import React, { useContext, useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { AuthContext } from '../../context/authContext';

const SuggestedTrucks = ({ suggestedTrucks, pickupId, zipCode, setSelectedSuggestedTruck }) => {
    const { token } = useContext(AuthContext);
    // const [suggestedTruck, setSuggestedTruck] = useState(null);
    const [suggestedTruckName, setSuggestedTruckName] = useState(null);

    const GET_TRUCKS_BY_ZIP_CODE = gql`
        query GetTrucksByZipCode($zipCode: String, $sortBy: TrucksByZipCodeSortInput) {
            trucksByZipCode(zipCode: $zipCode, sortBy: $sortBy) {
                id
                model
                numberPlate
                zone {
                    id
                    title
                }
            }
        }
    `;

    const [fetchTrucksByZipCode, { data: trucksByZipCodeData }] = useLazyQuery(GET_TRUCKS_BY_ZIP_CODE, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            if (zipCode) {
                try {
                    let result = await fetchTrucksByZipCode({
                        variables: {
                            zipCode: zipCode,
                            sortBy: {
                                field: 'numberPlate',
                                direction: 'asc',
                            },
                        },
                    });

                    console.log('=============');
                    // if (result.data) {
                    //     console.log(result?.data?.trucksByZipCode[0]);
                    //     setSuggestedTruck(result?.data?.trucksByZipCode[0]);
                    //     setSelectedSuggestedTruck(result?.data?.trucksByZipCode[0]);
                    // }
                    if (result?.data?.trucksByZipCode[0] != null) {
                        console.log('truck suge result in', result?.data?.trucksByZipCode[0]);
                        console.log('pickupId in ', pickupId);
                        let title = result?.data?.trucksByZipCode[0] ? result?.data?.trucksByZipCode[0]?.model + " (" + result?.data?.trucksByZipCode[0]?.numberPlate + ")" : '';
                        setSuggestedTruckName({
                            pickupId: pickupId,
                            title: title
                        });
                        let truck = result?.data?.trucksByZipCode[0];
                        setSelectedSuggestedTruck(truck, pickupId);
                    }
                } catch (error) {
                    // console.error('Error fetching locations:', error);
                }
            }
        };

        fetchData();
    }, [zipCode, fetchTrucksByZipCode]);

    useEffect(() => {
        console.log(trucksByZipCodeData);
    }, [trucksByZipCodeData]);

    return (
        <div className="my-0">
            <span className=" pb-1 text-[#374151] rounded-md">Suggested Truck: {suggestedTruckName != null && suggestedTruckName.pickupId == pickupId ? suggestedTruckName.title : ''}</span>
            {/* <span className="my-2 px-3 pb-1 bg-[#DBEAFE] text-[#1E40AF] rounded-md font-semibold">Suggested Truck: {suggestedTruck ? suggestedTruck?.model + " (" + suggestedTruck?.numberPlate + ")" : ''}</span> */}
        </div>
    );
};

export default SuggestedTrucks;
