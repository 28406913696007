// import React, { useState } from 'react';
// import { GoogleMap, LoadScript, Marker, DirectionsRenderer } from '@react-google-maps/api';

// const MyGoogleMap = () => {
//     const mapStyles = {
//         height: '400px',
//         width: '100%',
//         // Add any additional styling here
//         border: '1px solid #ccc',
//         borderRadius: '5px',
//     };

//     const [map, setmap] = useState(null)
//     const [directionsResponse, setDirectionsResponse] = useState(null)
//     const [distance, setDistance] = useState('')
//     const [duration, setDuration] = useState('')
//     const origin = { lat: 40.7128, lng: -74.0060 }; // New York City
//     const destination = { lat: 34.0522, lng: -118.2437 }; // Los Angeles
//     const defaultCenter = {
//         lat: 40.748817,
//         lng: -73.985428,
//     };

//     async function calculateRoute() {
//         if (origin == {} || destination == {}) {
//             return
//         }
//         const directionsService = new google.maps.DirectionsService()
//         const results = await directionsService.route({
//             origin: origin,
//             destination: destination,
//             travelMode: google.maps.TravelMode.DRIVING
//         });

//         setDirectionsResponse(results)
//         setDistance(results.routes[0].legs[0].distance.text)
//         setDuration(results.routes[0].legs[0].duration.text)
//     }



//     return (
//         <div>
//             <button className='' onClick={calculateRoute}>Get Distance</button>
//             <LoadScript googleMapsApiKey="AIzaSyCxOKo6IEBBmCJh7APFaQJffUx5mEIgEEE">
//                 <div style={mapStyles}>
//                     <GoogleMap
//                         mapContainerStyle={{ height: '100%', width: '100%' }}
//                         zoom={13}
//                         center={defaultCenter}
//                         options={{
//                             ZoomControl: false,
//                             streetViewControl: false,
//                             mapTypeControl: false,
//                             fullscreenControl: false
//                         }}
//                     >
//                         <Marker position={defaultCenter} />
//                         {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
//                     </GoogleMap>
//                 </div>
//             </LoadScript>
//         </div>
//     );
// };

// export default MyGoogleMap;


// // import React, { useState } from 'react';
// // import { GoogleMap, LoadScript, Marker, DirectionsRenderer, GeocodingService } from '@react-google-maps/api';

// // const MyGoogleMap = () => {
// //     const mapStyles = {
// //         height: '400px',
// //         width: '100%',
// //         border: '1px solid #ccc',
// //         borderRadius: '5px',
// //     };

// //     const [map, setMap] = useState(null);
// //     const [directionsResponse, setDirectionsResponse] = useState(null);
// //     const [originAddress, setOriginAddress] = useState("12500 Foothill Blvd Sylmar, CA 91342 USA");
// //     const [destinationAddress, setDestinationAddress] = useState("2720 Monterey Street Suite 401 Torrance, CA 90503 USA");

// //     const defaultCenter = {
// //         lat: 40.748817,
// //         lng: -73.985428,
// //     };

// //     async function calculateRoute() {
// //         const origin = await getCoordinates(originAddress);
// //         const destination = await getCoordinates(destinationAddress);

// //         if (!origin || !destination) {
// //             return;
// //         }

// //         const directionsService = new google.maps.DirectionsService();
// //         const results = await directionsService.route({
// //             origin,
// //             destination,
// //             travelMode: google.maps.TravelMode.DRIVING
// //         });

// //         setDirectionsResponse(results);
// //     }

// //     async function getCoordinates(address) {
// //         try {
// //             const response = await GeocodingService.geocode({ address });
// //             if (response && response.results.length > 0) {
// //                 return response.results[0].geometry.location;
// //             }
// //             return null;
// //         } catch (error) {
// //             console.error("Geocoding error:", error);
// //             return null;
// //         }
// //     }

// //     return (
// //         <div>
// //             <button className='' onClick={calculateRoute}>Get Directions</button>
// //             <LoadScript googleMapsApiKey="AIzaSyCxOKo6IEBBmCJh7APFaQJffUx5mEIgEEE">
// //                 <div style={mapStyles}>
// //                     <GoogleMap
// //                         mapContainerStyle={{ height: '100%', width: '100%' }}
// //                         zoom={13}
// //                         center={defaultCenter}
// //                         options={{
// //                             zoomControl: false,
// //                             streetViewControl: false,
// //                             mapTypeControl: false,
// //                             fullscreenControl: false
// //                         }}
// //                     >
// //                         <Marker position={defaultCenter} />
// //                         {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
// //                     </GoogleMap>
// //                 </div>
// //             </LoadScript>
// //         </div>
// //     );
// // };

// // export default MyGoogleMap;


// import React, { useState, useEffect } from 'react';
// import { GoogleMap, LoadScript, Marker, DirectionsRenderer, GeocodingService } from '@react-google-maps/api';

// const MyGoogleMap = () => {
//     const mapStyles = {
//         height: '400px',
//         width: '100%',
//         border: '1px solid #ccc',
//         borderRadius: '5px',
//     };

//     const [map, setMap] = useState(null);
//     const [directionsResponse, setDirectionsResponse] = useState(null);

//     const defaultCenter = {
//         lat: 40.748817,
//         lng: -73.985428,
//     };

//     async function calculateRoute() {

//         const origin = { lat: 40.7128, lng: -74.0060 }; // New York City
//         const destination = { lat: 34.0522, lng: -118.2437 }; // Los Angeles

//         if (!origin || !destination) {
//             return;
//         }

//         const directionsService = new window.google.maps.DirectionsService();
//         const results = await directionsService.route({
//             origin,
//             destination,
//             travelMode: window.google.maps.TravelMode.DRIVING
//         });

//         setDirectionsResponse(results);
//     }

//     return (
//         <div>
//             <button className='' onClick={calculateRoute}>Get Directions</button>
//             <LoadScript googleMapsApiKey="AIzaSyCxOKo6IEBBmCJh7APFaQJffUx5mEIgEEE">
//                 <div style={mapStyles}>
//                     <GoogleMap
//                         mapContainerStyle={{ height: '100%', width: '100%' }}
//                         zoom={13}
//                         center={defaultCenter}
//                         options={{
//                             zoomControl: false,
//                             streetViewControl: false,
//                             mapTypeControl: false,
//                             fullscreenControl: false
//                         }}
//                     >
//                         <Marker position={defaultCenter} />
//                         {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
//                     </GoogleMap>
//                 </div>
//             </LoadScript>
//         </div>
//     );
// };

// export default MyGoogleMap;









import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, DirectionsRenderer } from '@react-google-maps/api';

const MyGoogleMap = ({ trackingData }) => {
    const mapStyles = {
        height: '400px',
        width: '100%',
        border: '1px solid #ccc',
        borderRadius: '5px',
    };

    const [map, setMap] = useState(null);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [waypoints, setWaypoints] = useState([
        { lat: 40.7128, lng: -74.0060, title: 'Shipped' }, // New York City
        { lat: 34.0522, lng: -118.2437, title: 'picked up' }, // Los Angeles
        { lat: 41.8781, lng: -87.6298, title: 'delivered' }, // Chicago
    ]);

    useEffect(() => {
        var trackingDetail = trackingData.map((row, index) => {
            console.log('index', index, typeof (index))
            if (index === 0) {
                setMap({ lat: parseFloat(row.latitude), lng: parseFloat(row.longitude) });
            }
            return { lat: parseFloat(row.latitude), lng: parseFloat(row.longitude), title: row.status, trackingDateTime: row.trackingDateTime };
        });

        setWaypoints(trackingDetail);
    }, [trackingData]);

    // useEffect(() => {
    //     if (waypoints.length <= 2) {
    //         calculateRoute();
    //     }
    // }, [waypoints])

    async function calculateRoute() {
        console.log('waypoint', waypoints)
        const origin = waypoints[0];
        const destination = waypoints[waypoints.length - 1];
        const intermediateWaypoints = waypoints.slice(1, -1);

        const directionsService = new window.google.maps.DirectionsService();
        const results = await directionsService.route({
            origin,
            destination,
            waypoints: intermediateWaypoints.map(point => ({ location: point })),
            travelMode: window.google.maps.TravelMode.DRIVING
        });
        setDirectionsResponse(results);
    }

    return (
        <div className="mt-3">
            <div className='flex flex-row justify-center'>
                <button onClick={calculateRoute} className="mb-8 bg-gradient-to-l from-5% from-gray-300 to-gray-300 to-100% px-5 py-1 rounded-sm capitalize font-medium font-roboto hover:opacity-80 ease-in duration-200 text-sm text-color-dark">
                    Get Live Location
                </button>
            </div>
            <LoadScript googleMapsApiKey="AIzaSyCxOKo6IEBBmCJh7APFaQJffUx5mEIgEEE">
                <div className='mt-3' style={mapStyles}>
                    <GoogleMap
                        mapContainerStyle={{ height: '100%', width: '100%' }}
                        zoom={15}
                        center={map}
                        options={{
                            zoomControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false
                        }}
                    >
                        {waypoints.map((waypoint, index) => (
                            <Marker key={index} position={waypoint} title={waypoint.title} />
                        ))}
                        {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
                    </GoogleMap>
                </div>
            </LoadScript>
        </div>
    );
};

export default MyGoogleMap;