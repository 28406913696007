import React, { useState, useContext, useEffect } from 'react';
// import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import GraphQuery from '../graphql/queries/Query';
import { BACKEND_API_URL } from '../../constants';
// import PhoneInput from 'react-phone-input-2'

const ImageLightBoxModal = ({ pickupImage, showModal, closeModal, remarks = null }) => {
    if (showModal) {
        return (
            <div>
                <div id="image-light-box-modal" tabIndex="-1" aria-hidden="true" className="bg-black/40 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full d-flex">
                    <div className="relative p-4 w-full max-w-xl top-[3%] left-[35%] mb-20 d-flex justify-center">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 w-full">
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    Pickup Request Image
                                </h3>
                                <button onClick={() => closeModal()} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <form className="">
                                <div className='row'>
                                    <div className='md:w-full'>
                                        <div className="bg-white rounded px-8 pt-6 pb-8 flex flex-col my-2">
                                            {remarks !== null && <div>
                                                <h3 className='text-xl font-medium'>Note:</h3>
                                                <p>{remarks}</p>
                                            </div>}
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full px-3">
                                                    <img className='w-full' src={BACKEND_API_URL + '/images/' + pickupImage} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ div >
        );
    }
    else
        return false;

}

export default ImageLightBoxModal;