import React, { useState, useEffect, useContext } from 'react';
import { gql, useMutation, useQuery, } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import GraphQuery from '../graphql/queries/Query';
import OtherTruckModel from './OtherTruckModal';
import SuggestedTrucks from './SuggestedTrucks';
import ReportIssueModal from './ReportIssueModal';
import CompletedFreight from './CompletedFreight';
import FreightLocationModal from './FreightLocationModal';

const Loading = ({ filterValue, selectedTruckTab, allTrucks, selectedTruck }) => {
    const { token } = useContext(AuthContext);
    const [trucks, setTrucks] = useState([]);
    const [errors, setErrors] = useState(null);
    // const [loadingTruck, setLoadingTruck] = useState([]);
    const [showReportIssueModal, setShowReportIssueModal] = useState(false);
    const [showReportIssueModalId, setShowReportIssueModalId] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState([]);
    const [showOtherTruckModal, setShowOtherTruckModal] = useState(false);
    const [showFreightLocationModal, setShowFreightLocationModal] = useState(false);
    const [currentTruckId, setCurrentTruckId] = useState(null);
    const [suggestedTrucks, setSuggestedTrucks] = useState(null);
    const [otherTruckModalId, setOtherTruckModalId] = useState(null);
    const [upcomingTrucks, setUpcomingTrucks] = useState([]);
    const [currentPickup, setCurrentPickup] = useState(null);
    const [pickupRequests, setPickupRequests] = useState([]);

    const [driverOptions, setDriverOptions] = useState([]);
    const [truckOptions, setTruckOptions] = useState([]);
    const [formData, setFormData] = useState({ truckId: 0, driverId: 0 });





    // const { responseData: trucksData, loading, error, refetchData: fetchTrucks } = GraphQuery({ name: 'Trucks', single: 'Truck' }, {
    //     // first: 10,
    //     pickupStatus: "unloaded",
    //     skip: 0,
    //     search: '',
    //     sortBy: 'number_plate',
    //     order: 'asc',
    // }, `
    //     id,
    //     numberPlate,
    //     make,
    //     model,
    //     color
    //     assignedPickupRequests (pickupStatus: $pickupStatus){
    //         id,
    //         createdAt,
    //         apiMode,
    //         shipmentDate,
    //         pickupDate,
    //         pickupTime,
    //         pickupNumber,
    //         shipmentTerms,
    //         pickupStatus,
    //         customer {
    //             contactPersonFullName,
    //             businessName
    //         }
    //         account {
    //             id,
    //             companyName,
    //         },
    //         pickupRequestAddresses {
    //             id,
    //             companyName,
    //             addressType,
    //             address1,
    //             address2,
    //             city,
    //             state,
    //             zipCode,
    //         },
    //         pickupRequestItems {
    //             id,
    //             description,
    //             quantity,
    //             weight,
    //             pallets,
    //             shipmentClass,
    //             charges
    //         },
    //     }
    // `);

    const GET_DRIVERS = gql`
        query getDrivers {
            drivers {
                id,
                user {
                    firstName,
                    lastName,
                }
                defaultTruck {
                    id
                }
            }
        }
    `;

    const GET_TRUCKS = gql`
        query getTrucks {
            trucks {
                id,
                numberPlate,
                make,
                model,
            }
        }
    `;

    const BULK_INSERT_MUTATION = gql`
    mutation bulkUpdatePickupRequests($input: UpdatePickupRequestInput) {
        bulkUpdatePickupRequests(input: $input) {
            success
            message
            updatedPickupRequests {
                id,
            createdAt,
            apiMode,
            shipmentDate,
            pickupDate,
            pickupTime,
            pickupNumber,
            shipmentTerms,
            serviceType,
            pickupStatus,
            customer {
                contactPersonFullName,
                businessName
            }
            account {
                id,
                companyName,
            },
            pickupRequestAddresses {
                id,
                companyName,
                addressType,
                address1,
                address2,
                city,
                state,
                zipCode,
            },
            pickupRequestItems {
                id,
                description,
                quantity,
                weight,
                width,
                height,
                length,
                pallets,
                shipmentClass,
                charges
            },
            pickupRequestInvoices{
                id,
                customer {
                    id
                    contactPersonFullName
                }
                invoiceDate,
                invoicePath,
                status
            }

            }
        }
    }
    `;



    const [bulkInsert, { data: bulkInsertData, loading, error }] = useMutation(BULK_INSERT_MUTATION);

    const { driversLoding, driversError, data: driversData } = useQuery(GET_DRIVERS, {
        context: {
            headers: {
                'Authorization': `JWT ${token}`,
            },
        },
    });

    const { trucksLoading, trucksError, data: trucksData } = useQuery(GET_TRUCKS, {
        context: {
            headers: {
                'Authorization': `JWT ${token}`
            }
        }
    });

    const UPDATE_PICKUPREQUEST = gql`
        mutation ($id: ID!,$status: String,$truckId: ID) {
            updatePickupRequest (id: $id,status: $status, truckId: $truckId) {
                pickupRequest {
                    id,
                    createdAt,
                    apiMode,
                    shipmentDate,
                    pickupDate,
                    pickupTime,
                    pickupNumber,
                    shipmentTerms,
                    serviceType,
                    pickupStatus,
                    truck {
                        id,
                        numberPlate,
                        model,
                    }
                    customer {
                        contactPersonFullName,
                        businessName
                    }
                    account {
                        id,
                        companyName,
                    },
                    pickupRequestAddresses {
                        id,
                        companyName,
                        addressType,
                        address1,
                        address2,
                        city,
                        state,
                        zipCode,
                    },
                    pickupRequestItems {
                        id,
                        description,
                        quantity,
                        weight,
                        length,
                        width,
                        height,
                        pallets,
                        shipmentClass,
                        charges
                    },
                }
            }
        }
    `;

    const [updatePickupRequest, { pickupRequestLoading, pickupRequestError, data: pickupRequestUpdatedData }] = useMutation(UPDATE_PICKUPREQUEST);

    // useEffect(() => {
    //     console.log('shipmentClased', trucksData)
    //     if (trucksData) {
    //         // let filteredTrucks = trucksData.filter((truck) => {
    //         //     return truck.assignedPickupRequests.length > 0 ? truck : false;
    //         // })
    //         setTrucks(trucksData)
    //     }
    // }, [trucksData, setTrucks]);

    useEffect(() => {
        console.log('check in YTruck', selectedTruck)
        if (selectedTruck && selectedTruck.assignedPickupRequests.length > 0) {
            // let truckStorageLocation = selectedTruck.zone && selectedTruck?.zone?.zoneLocations ? selectedTruck.zone.zoneLocations[0] : {};
            // setStorageLocation(truckStorageLocation);
            setTrucks([selectedTruck])
            // setFormData({
            //     driverId: pickupData.pickupRequest?.driver?.id,
            //     truckId: pickupData.pickupRequest?.truck?.id,
            // });
            // setTrucks((trucks) => [...trucks, checkinTruck])
        }
    }, [selectedTruck, setTrucks]);

    useEffect(() => {
        console.log('trucks', allTrucks);
        let filteredUpcomingTrucks = allTrucks.filter((truck, index) => {
            return truck.id != selectedTruck.id;
        });
        setUpcomingTrucks(filteredUpcomingTrucks);
    }, [setUpcomingTrucks, allTrucks, selectedTruckTab]);

    useEffect(() => {
        if (driversData && driversData.drivers) {
            let driverOptions = driversData?.drivers.map((driver) => {
                return ({
                    label: driver?.user?.firstName + " " + driver.user.lastName[0].toUpperCase(),
                    value: driver.id
                });
            });

            setDriverOptions(driverOptions);
        }
    }, [driversData, setDriverOptions])

    useEffect(() => {
        if (trucksData && trucksData.trucks) {
            let truckOptions = trucksData?.trucks.map((truck) => {
                return ({
                    label: truck?.make + "-" + truck.model + " ( " + truck.numberPlate + " )",
                    value: truck.id
                });
            });

            setTruckOptions(truckOptions);
        }
    }, [trucksData, setTruckOptions])



    useEffect(() => {
        console.log(pickupRequestUpdatedData);
        if (pickupRequestUpdatedData && pickupRequestUpdatedData?.updatePickupRequest?.pickupRequest) {
            let updatedPickupRequest = pickupRequestUpdatedData?.updatePickupRequest?.pickupRequest;
            console.log(trucks, 'truks');

            // pickupRequests.

            // let updatedTrucks = trucks.map(truck => {

            //     let updatedAssignedPickupRequests = truck.assignedPickupRequests.map(pickup =>
            //         pickup.id === updatedPickupRequest.id ? updatedPickupRequest : pickup
            //     );

            //     return {
            //         ...truck,
            //         assignedPickupRequests: updatedAssignedPickupRequests
            //     };
            // });
            // setTrucks(updatedTrucks)


            // Update the pickupRequests state
            let updatedPickupRequests = pickupRequests.map(request => {
                if (request.truckId === updatedPickupRequest?.truck?.id) {
                    let updatedAssignedPickupRequests = request.assignedPickupRequests.map(pickup =>
                        pickup.id === updatedPickupRequest.id ? updatedPickupRequest : pickup
                    );

                    return {
                        ...request,
                        assignedPickupRequests: updatedAssignedPickupRequests
                    };
                }
                return request;
            });

            setPickupRequests(updatedPickupRequests);

        }
        console.log('===================')
        console.log('===================')
        console.log("PR after update", pickupRequests)
        console.log('===================')
    }, [pickupRequestUpdatedData]);

    useEffect(() => {
        console.log('bulk reponse=> ', bulkInsertData);
        if (bulkInsertData && bulkInsertData?.bulkUpdatePickupRequests?.updatedPickupRequests) {
            // let updatedPickupRequests = bulkInsertData?.bulkUpdatePickupRequests?.updatedPickupRequests;
            // console.log(trucks, 'truks');
            // let updatedPickupRequests = pickupRequests.map(request => {
            //     if (request.truckId === updatedPickupRequest?.truck?.id) {
            //         let updatedAssignedPickupRequests = request.assignedPickupRequests.map(pickup =>
            //             pickup.id === updatedPickupRequest.id ? updatedPickupRequest : pickup
            //         );

            //         return {
            //             ...request,
            //             assignedPickupRequests: updatedAssignedPickupRequests
            //         };
            //     }
            //     return request;
            // });

            // setPickupRequests(updatedPickupRequests);


            let pickupLoadedStatus = bulkInsertData?.bulkUpdatePickupRequests?.updatedPickupRequests.some((pickup) => {
                return pickup.pickupStatus == 'unloaded' ? true : false;
            })

            if (pickupLoadedStatus == true) {
                setPickupRequests([]);
            }

        }
        console.log('===================')
        console.log('===================')
        console.log("PR after update", bulkInsertData)
        console.log('===================')
    }, [bulkInsertData]);

    // useEffect(() => {
    //     console.log('truckData', truckData);
    //     if (truckData && truckData?.truck) {
    //         setTrucks([truckData.truck])
    //     }
    // }, [truckData]);

    const renderPickupRequestItems = (pickupItems) => {
        if (pickupItems.length > 0) {
            return pickupItems.map((pickupItem, index) => {
                return (
                    <div key={pickupItem.id} className="w-full justify-start">
                        <div className='w-full my-[8px]'>
                            <span className="font-inter font-medium text-[16px] text-[#374151]">Delivery Item# {index + 1}</span>
                        </div>
                        <div className='flex flex-wrap w-full'>
                            <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Weight:</span>
                            <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-right lg:text-left'>{pickupItem.weight} lbs</span>
                            <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Dimensions:</span>
                            <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-right lg:text-left'>{pickupItem.length + ' X ' + pickupItem.width + ' X ' + pickupItem.height}</span>
                        </div>
                    </div>
                    // <div key={pickupItem.id} className="w-full">
                    //     <div className="w-full flex  items-center">
                    //         <div className="w-[10%]">
                    //             <span className="pr-5 flex items-center">
                    //                 <svg fill="#1D397A" width={12} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" /></svg>
                    //             </span>
                    //         </div>
                    //         <div className="w-[90%]">
                    //             <p className="text-md font-semibold">Pickup Item# {index + 1}</p>
                    //         </div>
                    //     </div>
                    //     <div className="w-full flex">
                    //         <div className="w-[10%]">
                    //             <span className="pr-5 flex items-center">

                    //             </span>
                    //         </div>
                    //         <div className="w-[90%]">
                    //             <div className="w-full flex  items-center">
                    //                 <div className="w-[10%]">
                    //                     <span>
                    //                         <svg fill="#1D397A" width={12} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    //                             <path d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z" /></svg>
                    //                     </span>
                    //                 </div>
                    //                 <div className="w-[70%]">
                    //                     <div className="flex flex-row items-center">
                    //                         <div className='md:w-1/2 flex items-center justify-start'>
                    //                             <p>Weight : {pickupItem.weight}</p>
                    //                         </div>
                    //                     </div>
                    //                 </div>
                    //             </div>
                    //             <div className="w-full flex  items-center">
                    //                 <div className="w-[10%]">
                    //                     <span>
                    //                         <svg fill="#1D397A" width={12} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    //                             <path d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z" /></svg>
                    //                     </span>
                    //                 </div>
                    //                 <div className="w-[70%]">
                    //                     <div className="flex flex-row items-center ">
                    //                         <div className='md:w-1/2 flex items-center justify-start'>
                    //                             <p>Dimensions : {pickupItem.weight}</p>
                    //                         </div>
                    //                     </div>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </div>

                    // </div>
                )
            })
        }

    }

    const closeOtherTruckModal = () => {
        setShowOtherTruckModal(false)
        setOtherTruckModalId(null)
    }

    const showReportIssueModalHandle = (id) => {
        // e.preventDefault();
        setShowReportIssueModal(true);
        console.log('modal and truck id', id);
        setShowReportIssueModalId(id);
    }

    const showOtherTruckModalHandle = (pickupId) => {
        setShowOtherTruckModal(true)
        setOtherTruckModalId(pickupId)
        console.log('shoeothertou', pickupId);
        setCurrentPickup(pickupId);
        // setLoadingTruck(truckId)
    }

    const confirmTruckHandle = (truckId, pickupId, status) => {

        // if (truckId === 0 && suggestedTrucks == null) {
        //     let error = [];
        //     error[pickupId] = { suggestedLocation: 'Please select a location to proceed' };
        //     console.log('please select a truck to proceed');
        //     return setErrors(error);
        // }

        // if (suggestedTrucks != null) {
        if (truckId == 0 && suggestedTrucks != null) {
            truckId = suggestedTrucks[pickupId];
        }
        // let confirmedTruck = truckId === 0 ? suggestedTrucks[pickupId] : truckId;
        updatePickupRequest({
            context: {
                headers: {
                    'Authorization': `JWT ${token}`,
                },
            },
            variables: {
                id: pickupId,
                truckId: truckId,
                status: status,
            }
        });
        // }
        if (showOtherTruckModal == true) {
            setShowOtherTruckModal(false);
            setOtherTruckModalId(null)
        }
    }

    const setSuggestedTruckHandle = (truck, pickupId) => {
        if (truck != null) {
            setSuggestedTrucks(prevSuggestedTrucks => ({
                ...prevSuggestedTrucks,
                [pickupId]: truck.id
            }));
        }

        // setSuggestedTrucks((prevLoadingTruck) => {
        //     // Ensure prevLoadingTruck is an array
        //     if (!Array.isArray(prevLoadingTruck)) {
        //         prevLoadingTruck = [];
        //     }

        //     return prevLoadingTruck.map((item) => {
        //         // Fix the logic: if item.pickupId matches, return updated object
        //         if (item.pickupId === pickupId) {
        //             return { ...item, truckId: truck.id }; // update the truckId if pickupId matches
        //         }
        //         return item; // if no match, return the item unchanged
        //     }).concat(
        //         prevLoadingTruck.some(item => item.pickupId === pickupId) ? [] : [{ pickupId, truckId: truck.id }]
        //     ); // Add new object if pickupId not found
        // });
        // }
    }

    // const renderTruckPickupRequests = (truck, truckIndex) => {
    //     console.log('checking truck id', pickupRequests.truckId == truck?.id?.toString())
    //     if (pickupRequests.length > 0) {
    //         return pickupRequests[truck.id]['assignedPickupRequests'].map((pickupRequest, index) => {
    //             // let selectedLoadingTruck = [...loadingTruck]
    //             // selectedLoadingTruck[pickupRequest.id] = truck.id;
    //             // setLoadingTruck((loadingTruck) => { return [...loadingTruck, [pickupRequest.id, truck.id],] });
    //             let sourceAddressData = {};
    //             let destinationAddressData = {};
    //             let addresses = pickupRequest?.pickupRequestAddresses;
    //             addresses.map((address) => {
    //                 if (address.addressType === 'SHIPPER') {
    //                     sourceAddressData = address;
    //                 }
    //                 else if (address.addressType === 'CONSIGNEE') {
    //                     destinationAddressData = address;
    //                 }
    //                 return true;
    //             });

    //             return (
    //                 <div key={pickupRequest.id} className="md:w-full px-0 lg:px-0">
    //                     <div className="w-full px-3">
    //                         <div className='relative w-full flex flex-wrap rounded-lg mb-[20px]'>
    //                             <div className="w-full sm:w-full md:w-full lg:w-[30%] xl:w-1/6 2xl:w-[25%]">
    //                                 {index == 0 && <div className="flex items-center justify-start">
    //                                     <h5 className="text-[18px] text-[#374151] font-semibold font-inter capitalize mb-[8px]">{truck.make} {truck.model} {truck.numberPlate ? '( ' + truck.numberPlate + ')' : ''}</h5>
    //                                 </div>}
    //                                 {/* <h5 className='w-[90%] text-md font-semibold'>Pickup Request# {pickupRequest.id} - {pickupRequest.pickupNumber}</h5> */}
    //                                 {pickupRequest.pickupStatus && pickupRequest.pickupStatus === 'loaded' ?
    //                                     <div>
    //                                         <p className="font-inter font-normal text-[16px] mb-[0px] capitalize">Confirmed Truck: <span className="font-bold text-green-500">{pickupRequest.truck ? pickupRequest.truck.model + '( ' + pickupRequest.truck.numberPlate + ')' : ''}</span></p>
    //                                         <p className="capitalize">Pickup Status: <span className="font-bold text-green-500">{pickupRequest.pickupStatus}</span></p>
    //                                     </div>
    //                                     : <SuggestedTrucks suggestedTrucks={suggestedTrucks} pickupId={pickupRequest.id} zipCode={destinationAddressData.zipCode} setSelectedSuggestedTruck={(truck, pickupId) => setSuggestedTruckHandle(truck, pickupId)} />}

    //                                 <div className='w-full mb-3'>

    //                                     {pickupRequestUpdatedData && pickupRequestUpdatedData?.updatePickupRequest?.pickupRequest?.id == pickupRequest.id ?
    //                                         <div>
    //                                             {pickupRequestUpdatedData && pickupRequestUpdatedData?.updatePickupRequest?.pickupRequest?.id && <p className="text-green-500">Pickup Request #{pickupRequestUpdatedData?.updatePickupRequest?.pickupRequest?.id} - {pickupRequestUpdatedData?.updatePickupRequest?.pickupRequest?.pickupNumber} loaded successfully</p>}
    //                                         </div>
    //                                         :
    //                                         <div className="w-full items-center mt-3">

    //                                             {/* {renderSuggestedTruck(truck.id, pickupRequest)} */}

    //                                             {pickupRequest.pickupStatus === 'unloaded' && truck.id == selectedTruck.id && <div className="flex flex-wrap justify-start">
    //                                                 <button onClick={() => loadingStatusHandle(index, pickupRequest.id)} className="mb-5 px-[1.5rem] py-[0.75rem]  mr-4 leading-5 text-[16px] text-white transition-colors duration-200 transform bg-[#EA580C] rounded-md hover:bg-[#ff7c38] focus:outline-none focus:bg-[#e96f30]">Load</button>
    //                                                 {loadingStatus.includes(index + '-' + pickupRequest.id) &&
    //                                                     <>
    //                                                         {suggestedTrucks && suggestedTrucks[pickupRequest.id] && <div className="w-2/4 flex align-items-center justify-start">
    //                                                             <button onClick={() => confirmTruckHandle(0, pickupRequest.id, 'loaded')} className="mb-5 px-[1.5rem] py-[0.75rem]  mr-4 leading-5 text-[16px] text-[#EA580C] font-medium transition-colors duration-200 transform bg-[#EA580C1A] rounded-md hover:bg-[#EA580C] hover:text-[#fff] focus:outline-none focus:[#e96f30]">Confirm</button>
    //                                                         </div>}
    //                                                         <button onClick={() => showOtherTruckModalHandle(pickupRequest.id)} className="mb-5 px-[1.5rem] py-[0.75rem]  mr-4 leading-5 text-[16px] text-[#EA580C] font-medium transition-colors duration-200 transform  rounded-md hover:bg-[#EA580C] hover:text-[#fff] focus:outline-none focus:bg-gray-500">Choose Other Truck</button>
    //                                                     </>
    //                                                 }
    //                                             </div>}
    //                                         </div>}

    //                                     {/* {errors && errors[pickupRequest.id] && <p className="text-red-500">{errors[pickupRequest.id].suggestedTruck}</p>} */}

    //                                 </div>

    //                             </div>
    //                             <div className="relative w-full sm:w-full md:w-full lg:w-[45%] xl:w-3/6 2xl:md:w-3/6">
    //                                 <div className="w-full">
    //                                     <div className="flex flex-wrap w-full">
    //                                         <div className='flex flex-wrap w-full'>
    //                                             <span className="w-full font-inter font-semibold text-[16px] text-[#374151] mb-[20px]">Pickup #{index + 1}</span>
    //                                             <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Pickup Number:</span>
    //                                             <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupRequest.pickupNumber}</span>
    //                                             <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Source Zip:</span>
    //                                             <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{sourceAddressData.zipCode}</span>
    //                                             <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Destination Zip:</span>
    //                                             <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{destinationAddressData.zipCode}</span>
    //                                         </div>
    //                                         {renderPickupRequestItems(pickupRequest?.pickupRequestItems)}
    //                                         <div className='flex flex-wrap w-full'>
    //                                             <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Source Company Name:</span>
    //                                             <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{sourceAddressData.companyName}</span>
    //                                             <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Destination Company Name:</span>
    //                                             <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{destinationAddressData.companyName}</span>
    //                                         </div>
    //                                     </div>
    //                                     {/* <div className="w-full flex items-center justify-between md:justify-start mb-1">
    //                                             <div className="md:w-[80%] lg:w-3/6 xl:w-2/6 mr-2 md:mr-0">
    //                                                 <div className="flex items-center justify-start">
    //                                                     <p className="text-[#6B7280]">Source Company Name:</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className="md:w-[20%] x:w-4/6 text-end md:text-start">
    //                                                 <p className="text-[#374151] font-medium">{sourceAddressData.companyName}</p>
    //                                             </div>
    //                                         </div>
    //                                         <div className="w-full flex items-center justify-between md:justify-start mb-1">
    //                                             <div className="md:w-[80%] lg:w-3/6 xl:w-2/6 mr-2 md:mr-0">
    //                                                 <div className="flex items-center justify-start">
    //                                                     <p className="text-[#6B7280]">Destination Company Name:</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className="md:w-[20%] x:w-4/6 text-end md:text-start">
    //                                                 <p className="text-[#374151] font-medium">{destinationAddressData.companyName}</p>
    //                                             </div>
    //                                         </div> */}
    //                                 </div>
    //                             </div>
    //                             <div className="w-full md:w-2/6 lg:w-[25%] xl:w-2/6 2xl:w-[25%] relative md:absolute top-0 right-0 lg:relative">
    //                                 <div className="flex justify-start md:justify-end">
    //                                     <button onClick={() => showReportIssueModalHandle(pickupRequest.id)} className="flex items-center px-0 md:p-2 leading-5 text-[#EA580C] text-[17px] font-semibold transition-colors duration-200 transform rounded-md  hover:bg-[#EA580C1A] focus:outline-none focus:bg-[#EA580C1A]">
    //                                         <span className="mr-2">

    //                                             <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                                                 <path d="M13.1083 2.5H6.89167L2.5 6.89167V13.1083L6.89167 17.5H13.1083L17.5 13.1083V6.89167L13.1083 2.5ZM15.8333 12.4167L12.4167 15.8333H7.58333L4.16667 12.4167V7.58333L7.58333 4.16667H12.4167L15.8333 7.58333V12.4167Z" fill="#EA580C" />
    //                                                 <path d="M10.0001 14.1667C10.4603 14.1667 10.8334 13.7936 10.8334 13.3333C10.8334 12.8731 10.4603 12.5 10.0001 12.5C9.53984 12.5 9.16675 12.8731 9.16675 13.3333C9.16675 13.7936 9.53984 14.1667 10.0001 14.1667Z" fill="#EA580C" />
    //                                                 <path d="M9.16675 5.83333H10.8334V11.6667H9.16675V5.83333Z" fill="#EA580C" />
    //                                             </svg>

    //                                         </span>
    //                                         <span>
    //                                             Report an issue
    //                                         </span>
    //                                     </button>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div key={pickupRequest.id}>
    //                         {showReportIssueModalId === pickupRequest.id && <ReportIssueModal pickupId={showReportIssueModalId} showModal={showReportIssueModal} closeModal={() => closeReportIssueModal()} />}
    //                     </div>
    //                     {otherTruckModalId == pickupRequest.id && <OtherTruckModel updateSelectedTruck={(truckId) => confirmTruckHandle(truckId, pickupRequest.id, 'loaded')} currentPickup={pickupRequest.id} selectedTruck={suggestedTrucks} showModal={showOtherTruckModal} closeModal={() => closeOtherTruckModal()} />}
    //                     {/* {(index + 1) < truck?.assignedPickupRequests.length && <hr className="my-3 w-full" />} */}
    //                 </div >
    //             )

    //         });
    //     }
    // }

    const removePickupHandle = (truckId, pickupRequestId) => {
        setPickupRequests((prevRequests) => {
            return prevRequests.map(request => {
                if (request.truckId === truckId) {
                    return {
                        ...request,
                        assignedPickupRequests: request.assignedPickupRequests.filter(
                            pickup => pickup.id !== pickupRequestId
                        )
                    };
                }
                return request;
            });
        });
    };

    const renderTruckPickupRequests = (truck, truckIndex) => {
        const truckPickupRequests = pickupRequests.find(request => request.truckId === truck.id);

        if (truckPickupRequests && truckPickupRequests.assignedPickupRequests.length > 0) {
            return truckPickupRequests.assignedPickupRequests.map((pickupRequest, index) => {
                let sourceAddressData = {};
                let destinationAddressData = {};
                let addresses = pickupRequest?.pickupRequestAddresses;
                addresses.map((address) => {
                    if (address.addressType === 'SHIPPER' || address.addressType === 'SOURCE') {
                        sourceAddressData = address;
                    }
                    else if (address.addressType === 'CONSIGNEE' || address.addressType === 'DESTINATION') {
                        destinationAddressData = address;
                    }
                    return true;
                });

                return (
                    <div key={pickupRequest.id} className="md:w-full px-0 lg:px-0">
                        <div className="w-full px-3">
                            <div className='relative w-full flex flex-wrap rounded-lg mb-[20px]'>
                                {/* <div className="w-full sm:w-full md:w-full lg:w-[30%] xl:w-1/6 2xl:w-[25%]">

                                </div> */}
                                <div className="relative w-full sm:w-full md:w-full lg:w-[45%] xl:w-3/6 2xl:md:w-3/6">
                                    <div className="w-full">
                                        <div className="flex flex-wrap w-full">
                                            <div className='flex flex-wrap w-full'>
                                                <span className="w-full font-inter font-semibold text-[16px] text-[#374151] mb-[20px]">Delivery # {pickupRequest.id}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Delivery Number:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupRequest.pickupNumber}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Source Zip:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{sourceAddressData.zipCode}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Destination Zip:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{destinationAddressData.zipCode}</span>
                                            </div>
                                            {renderPickupRequestItems(pickupRequest?.pickupRequestItems)}
                                            <div className='flex flex-wrap w-full'>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Source Company Name:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{sourceAddressData.companyName}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Destination Company Name:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{destinationAddressData.companyName}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Service Type:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupRequest.serviceType}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-2/6 lg:w-[25%] xl:w-2/6 2xl:w-[25%] relative md:absolute top-0 right-0 lg:relative">
                                    <div className="flex justify-start md:justify-end">
                                        <button onClick={() => showReportIssueModalHandle(pickupRequest.id)} className="flex items-center px-0 md:p-2 leading-5 text-[#EA580C] text-[17px] font-semibold transition-colors duration-200 transform rounded-md  hover:bg-[#EA580C1A] focus:outline-none focus:bg-[#EA580C1A]">
                                            <span className="mr-2">
                                                <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.1083 2.5H6.89167L2.5 6.89167V13.1083L6.89167 17.5H13.1083L17.5 13.1083V6.89167L13.1083 2.5ZM15.8333 12.4167L12.4167 15.8333H7.58333L4.16667 12.4167V7.58333L7.58333 4.16667H12.4167L15.8333 7.583V12.4167Z" fill="#EA580C" />
                                                    <path d="M10.0001 14.1667C10.4603 14.1667 10.8334 13.7936 10.8334 13.3333C10.8334 12.8731 10.4603 12.5 10.0001 12.5C9.53984 12.5 9.16675 12.8731 9.16675 13.3333C9.16675 13.7936 9.53984 14.1667 10.0001 14.1667Z" fill="#EA580C" />
                                                    <path d="M9.16675 5.83333H10.8334V11.6667H9.16675V5.83333Z" fill="#EA580C" />
                                                </svg>
                                            </span>
                                            <span>
                                                Report an issue
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div key={pickupRequest.id}>
                            {showReportIssueModalId === pickupRequest.id && <ReportIssueModal pickupId={showReportIssueModalId} showModal={showReportIssueModal} closeModal={() => closeReportIssueModal()} />}
                        </div>
                        {otherTruckModalId === pickupRequest.id && <OtherTruckModel updateSelectedTruck={(truckId) => confirmTruckHandle(truckId, pickupRequest.id, 'loaded')} currentPickup={pickupRequest.id} selectedTruck={suggestedTrucks} showModal={showOtherTruckModal} closeModal={() => closeOtherTruckModal()} />}
                    </div>
                );
            });
        }
    };


    const closeReportIssueModal = () => {
        setShowReportIssueModal(false);
    }

    const checkinHandle = () => {
        console.log('checkin')
    }

    const reportHandle = () => {
        console.log('report')
    }

    const loadingStatusHandle = (truckId, pickupId) => {
        // console.log(loadingStatus);
        // let updatedloadingStatus = [...loadingStatus, index + '-' + pickupId]
        // setLoadingStatus(updatedloadingStatus);
        // setCurrentPickup(pickupId);
        // setLoadingTruck(truckId)

        updatePickupRequest({
            context: {
                headers: {
                    'Authorization': `JWT ${token}`,
                },
            },
            variables: {
                id: pickupId,
                truckId: truckId,
                status: 'loaded',
            }
        });

    }

    const selectFreightHandle = (truck) => {
        setShowFreightLocationModal(true);
        setCurrentTruckId(truck.id);
    }

    const closeFreightLocationModal = () => {
        setShowFreightLocationModal(false);
    }

    const removeLoadedInventory = () => {

        let pickupData = [];
        console.log(pickupRequests, 'PR all', selectedTruck.id)
        pickupRequests.forEach(async (truck) => {
            if (truck.truckId == selectedTruck.id) {
                return await truck?.assignedPickupRequests?.forEach((pickup) => {
                    return pickupData.push({ id: parseInt(pickup.id) })
                });
            }
        })


        console.log('PR bf update', pickupData);
        const inputData = {
            pickups: pickupData,
            truckId: selectedTruck?.id ? parseInt(selectedTruck.id) : null,
            status: 'unloaded'
        };

        bulkInsert({
            context: {
                headers: {
                    'Authorization': `JWT ${token}`,
                },
            },
            variables: { input: inputData }
        });
    }

    const getUpdatedPickups = (pickups) => {
        // setPickupRequests({
        //     truckId: currentTruckId,
        //     assignedPickupRequests: pickups,
        // });

        setPickupRequests((prevRequests) => {
            const existingIndex = prevRequests.findIndex(request => request.truckId === currentTruckId);
            if (existingIndex !== -1) {
                // Update existing truck's pickup requests
                return prevRequests.map((request, index) => {
                    if (index === existingIndex) {
                        const updatedAssignedPickupRequests = [
                            ...request.assignedPickupRequests,
                            ...pickups.filter(
                                newPickup => !request.assignedPickupRequests.some(
                                    existingPickup => existingPickup.id === newPickup.id
                                )
                            )
                        ];
                        return {
                            ...request,
                            assignedPickupRequests: updatedAssignedPickupRequests,
                        };
                    }
                    return request;
                });
            } else {
                // Add new truck's pickup requests
                return [
                    ...prevRequests,
                    {
                        truckId: currentTruckId,
                        assignedPickupRequests: pickups,
                    },
                ];
            }
        });

        let pickupData = [];
        pickups.forEach((pickup) => {

            return pickupData.push({ id: parseInt(pickup.id) });
        })


        console.log('PR bf update', pickupData);
        let truckId = selectedTruck?.id ? parseInt(selectedTruck.id) : null;
        let driverId = null;
        if (formData.driverId !== null) {
            truckId = parseInt(formData?.truckId);
            driverId = parseInt(formData?.driverId);
        }

        const inputData = {
            pickups: pickupData,
            truckId: truckId,
            driverId: driverId,
            status: 'loaded'
        };
        console.log('inputData', inputData)
        // return true;

        bulkInsert({
            context: {
                headers: {
                    'Authorization': `JWT ${token}`,
                },
            },
            variables: { input: inputData }
        });

        // bulkInsert({ variables: { input: inputData } });



        setShowFreightLocationModal(false);
        console.log('PPPPPPPPPPPPPPPP');
        console.log(pickupRequests);
    }

    const driverHandle = (e) => {
        setFormData({
            ...formData,
            driverId: e.target.value
        });

        driversData.drivers.forEach((driver, index) => {
            if (driver.id === e.target.value && driver?.defaultTruck?.id != null) {
                return setFormData({
                    driverId: e.target.value,
                    truckId: driver?.defaultTruck?.id
                });
            }
            return false;
        });

    }

    const truckHandle = (e) => {
        return setFormData({
            ...formData,
            truckId: e.target.value
        });
    }



    const renderTrucksDetails = () => {
        if (trucks.length > 0) {
            return trucks.map((truck, index) => {
                return (
                    <div key={truck.id} className="md:w-full mb-[20px]">
                        <div className="relative md:w-full flex flex-wrap p-5 border bg-white border-[#D1D5DB] shadow-md rounded-lg h-[100%]">
                            <h5 className="text-xl font-bold mb-5 px-3">Truck {index + 1} : {truck.numberPlate ? truck.numberPlate : ''}</h5>
                            <div className="md:w-full flex flex-wrap px-3">
                                <div className="w-2/6 px-4">
                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-default-truck">
                                        Select Driver
                                    </label>
                                    <div className="relative mb-3">
                                        <select value={formData.driverId} onChange={driverHandle} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="grid-default-truck">
                                            <option value={0}>Select Drivers</option>
                                            {
                                                driverOptions.map((driver) => {
                                                    return (
                                                        <option value={driver.value} key={driver.value}>{driver.label}</option>
                                                    );
                                                })
                                            }
                                        </select>
                                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-2/6 px-4">
                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-default-truck">
                                        Select Truck
                                    </label>
                                    <div className="relative mb-3">
                                        <select value={formData.truckId} onChange={truckHandle} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="grid-default-truck">
                                            <option value={0}>Select Trucks</option>
                                            {
                                                truckOptions.map((truck) => {
                                                    return (
                                                        <option value={truck.value} key={truck.value}>{truck.label}</option>
                                                    );
                                                })
                                            }
                                        </select>
                                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="md:w-full flex flex-wrap px-3">
                                {renderActionButtons(truck, index)}
                            </div>



                            <div className="md:w-full flex flex-wrap">
                                {renderTruckPickupRequests(truck, index)}
                            </div>
                            {currentTruckId == truck.id && <FreightLocationModal showModal={showFreightLocationModal} closeModal={closeFreightLocationModal} updateSelectedPickups={(pickupRequests) => getUpdatedPickups(pickupRequests)} />}
                        </div>
                    </div>
                )
            })
        }
        return "No truck Data Available..."
    }

    const renderActionButtons = (truck, index) => {
        if (bulkInsertData && bulkInsertData?.bulkUpdatePickupRequests?.updatedPickupRequests) {
            let pickupLoadedStatus = bulkInsertData?.bulkUpdatePickupRequests?.updatedPickupRequests.some((pickup) => {
                return pickup.pickupStatus == 'loaded' ? true : false;
            })
            return (
                pickupLoadedStatus ?
                    <div>
                        <button disabled={true} className="px-6 py-3 mr-4 mb-4 leading-5 text-white transition-colors duration-200 transform bg-green-600 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600">Inventory Loaded</button>
                        <button onClick={() => removeLoadedInventory()} className="px-6 py-3 mr-4 mb-4 leading-5 text-white transition-colors duration-200 transform bg-red-500 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-900">Remove Loaded Inventory</button>

                    </div>
                    :
                    <button onClick={() => selectFreightHandle(truck)} className="px-6 py-3 mr-4 mb-4 leading-5 text-white transition-colors duration-200 transform bg-[#EA580C] rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Select Inventory to load</button>
            )
        }
        else {
            return (<button onClick={() => selectFreightHandle(truck)} className="px-6 py-3 mr-4 mb-4 leading-5 text-white transition-colors duration-200 transform bg-[#EA580C] rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Select Inventory to load</button>);
        }
    }

    const renderUpComingTrucksDetails = () => {
        if (upcomingTrucks.length > 0) {
            return upcomingTrucks.map((truck, index) => {
                return (
                    <div key={index} className="md:w-full mb-5">
                        <div className="md:w-full p-5 border bg-white border-[#D1D5DB] shadow-md rounded-lg h-[100%]">
                            <div className="w-full">
                                <h5 className="text-[18px] font-bold capitalize mb-4">Truck test {index + 1}: {truck.numberPlate ? truck.numberPlate : ''}</h5>
                                {/* <button onClick={() => checkinHandle(truck)} className="px-6 py-3 mr-4 mt-4 leading-5 text-white transition-colors duration-200 transform bg-[#EA580C] rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Check-in</button> */}
                            </div>
                            <div className="w-full">
                                {renderTruckPickupRequests(truck, index)}
                            </div>
                            {/* <div className="w-full md:w-2/6">
                                <div className="flex justify-end">
                                    <button onClick={() => showReportIssueModalHandle(truck.id)} className="flex items-center px-3 xl:px-6 py-4 leading-5 text-[#EA580C] text-md font-semibold transition-colors duration-200 transform  hover:bg-[#F9FAFB] focus:outline-none focus:bg-[#F9FAFB]">
                                        <span className="mr-3">

                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.1083 2.5H6.89167L2.5 6.89167V13.1083L6.89167 17.5H13.1083L17.5 13.1083V6.89167L13.1083 2.5ZM15.8333 12.4167L12.4167 15.8333H7.58333L4.16667 12.4167V7.58333L7.58333 4.16667H12.4167L15.8333 7.58333V12.4167Z" fill="#EA580C" />
                                                <path d="M10.0001 14.1667C10.4603 14.1667 10.8334 13.7936 10.8334 13.3333C10.8334 12.8731 10.4603 12.5 10.0001 12.5C9.53984 12.5 9.16675 12.8731 9.16675 13.3333C9.16675 13.7936 9.53984 14.1667 10.0001 14.1667Z" fill="#EA580C" />
                                                <path d="M9.16675 5.83333H10.8334V11.6667H9.16675V5.83333Z" fill="#EA580C" />
                                            </svg>

                                        </span>
                                        Report an issue</button>
                                </div>
                            </div> */}
                        </div>
                        {/* <div key={truck.id + '_' + index}>
                            {showReportIssueModalId === truck.id && <ReportIssueModal showModal={showReportIssueModal} closeModal={() => closeReportIssueModal()} />}
                        </div> */}
                    </div>
                )
            })
        }
        return "No truck Data Available..."
    }

    return (
        <div>
            {/* <h4 className="text-2xl my-4 font-bold">Loading <span className="text-[#6B7280] ml-3 font-medium">(Click load starts confirmation flow)</span></h4> */}
            <div className="tab-container">
                {filterValue == 'unloaded' ? <div>
                    {trucks.length > 0 ? <div className="relative z-1">
                        <div className="row pt-3 mb-4 my-2">
                            <h3 className="uppercase text-[#6B7280] mb-3 font-medium font-inter text-[12px]">In Progress</h3>
                            {renderTrucksDetails()}
                        </div>
                    </div>
                        :
                        <p className=' mt-7'>No Delivery Request Found...</p>
                    }
                    {upcomingTrucks.length > 0 && <div className="row pt-3 mb-4 my-2">
                        <h3 className="uppercase text-[#6B7280] mb-3 font-medium font-inter text-[12px]">Up Coming</h3>
                        {renderUpComingTrucksDetails()}
                    </div>}
                </div> :
                    <CompletedFreight selectedTruckTab={selectedTruckTab} newCompletedPickupRequest={pickupRequestUpdatedData?.updatePickupRequest?.pickupRequest} pickupStatus='loaded' />
                }

            </div>
        </div>
    )
}
export default Loading;