import React, { useState, useEffect, useContext } from 'react';

import SortableTable from "../components/SortableTable";
import PageHeading from "../components/PageHeading";

import { Link } from 'react-router-dom'
import Pagination from "../components/Pagination";

import Switch from "react-switch";
import searchIcon from '../assets/search-icon.svg';
import { gql, useMutation, useQuery } from '@apollo/client';

import { AuthContext } from '../context/authContext';
import Loader from '../components/Loader';

import pdfFileIcon from '../assets/images/icons/pdf-official-icon.jpg';

function BolsPage() {
    const urlParams = new URLSearchParams(window.location.search);

    const { token } = useContext(AuthContext);

    // const searchValue = urlParams.get('search');
    const [mode, setMode] = useState('test');

    const pageValue = urlParams.get('page');
    const [page, setPage] = useState(pageValue ? parseInt(pageValue) : 1);

    const recordsPerPage = 10;
    const [offset, setOffset] = useState((page - 1) * recordsPerPage);
    const [testMode, setTestMode] = useState({ checked: false });
    const [search, setSearch] = useState("");

    const [pdfUrl, setPdfUrl] = useState(null);

    const GET_BOLS = gql`
        query GetBols($first: Int, $skip: Int, $search: String, $sortBy: BolSortInput, $mode: String) {
            bols(first: $first, skip: $skip, search: $search, sortBy: $sortBy, mode: $mode) {
                id,
                shipmentTerms,
                createdAt,
                apiMode
                account {
                    id,
                    companyName,
                }
                customer {
                    id,
                    contactPersonFullName,
                    businessName
                }
                bolAddresses{
                    id,
                    companyName,
                    addressType,
                    address1,
                    address2,
                    city,
                    state,
                    zipCode,
                },
                bolItems {
                    id,
                    description,
                    quantity,
                    weight,
                    pallets,
                    shipmentClass,
                    charges,
                    serviceType,
                    packageType
                },
            },
            bolCount(search: $search, mode: $mode)
        }
    `;

    const GENERATE_BOL_PDF = gql`
        mutation GenerateBolPdf($bolId: ID!) {
            generateBolPdf(bolId: $bolId) {
                success
                message
                bolPath
            }
        }
    `;

    const [generateBolPdf, { data: generateBolPdfData, loading: generateBolLoading, error: generateBolPdfError }] = useMutation(GENERATE_BOL_PDF);
    const { loading, error, data: bolData, refetch } = useQuery(GET_BOLS, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: {
            first: recordsPerPage,
            skip: offset,
            search: search.length > 3 ? search : '',
            sortBy: {
                field: 'id',
                direction: 'desc',
            },
        },
    });

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (bolData && bolData.bols) {
            const bols = bolData.bols;
            setData(bols);
            setCount(bolData.bolCount);
        }
    }, [bolData, setData]);

    useEffect(() => {
        setPage(pageValue ? parseInt(pageValue) : 1);
    }, [pageValue]);

    if (error) {
        return <p>Error :(</p>;
    }

    const handlePageChange = (page) => {
        setPage(page);
        setOffset((page - 1) * recordsPerPage);
    }

    const handleApiModeChange = (checked) => {
        setPage(1);
        setOffset(0);
        setTestMode({ checked });
        testMode.checked === true ? setMode('test') : setMode('live');
        refetch({
            first: recordsPerPage,
            skip: 0,
            search: search.length > 3 ? search : '',
            sortBy: {
                field: 'id',
                direction: 'desc',
            },
            mode: testMode.checked === true ? 'test' : 'live',
        });
    }

    const downloadPDF = async (bolId) => {
        // alert('downloading pdf...');
        // try {
        //     const response = await generateBolPdf({
        //         variables: { bolId: bolId },
        //     });

        //     if (response.data.generateBolPdf.success) {
        //         alert(`PDF generated successfully! Message: ${response.data.generateBolPdf.message}`);
        //         setPdfUrl(`/assets/pdf/${response.data.generateBolPdf.message}`); // Set the PDF URL to allow download
        //     } else {
        //         alert(`Failed to generate PDF: ${response.data.generateBolPdf.message}`);
        //     }
        // } catch (err) {
        //     console.error('Error generating PDF:', err);
        // }

        try {
            const response = await generateBolPdf({ variables: { bolId } });
            const fileUrl = response.data.generateBolPdf.bolPath;
            console.log('hello', response.data);
            if (fileUrl) {
                const link = document.createElement('a');
                link.href = fileUrl;
                link.setAttribute('target', '_blank'); // Set default file name
                link.setAttribute('download', 'bol_document.pdf'); // Set default file name
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error("Failed to download PDF");
            }
        } catch (error) {
            console.error("Error generating PDF:", error);
        }

    }

    const config = [
        {
            label: 'ID',
            render: (bol) => <Link
                to={"/admin/bols/" + bol.id}
                className='text-blue-600 text-sm leading-5 font-normal'
            >{bol.id}</Link>,
            headerClassName: 'pr-8 w-12',
            rowCellClassName: '',
        },
        {
            label: 'Consignee Name',
            render: (bol) => {
                let consigneeAddress = bol.bolAddresses.filter((address) => address.addressType === 'CONSIGNEE');
                return consigneeAddress[0]['companyName'];
            },
            headerClassName: 'pl-0 pr-0',
            rowCellClassName: 'pl-0 pr-0',
        },
        {
            label: 'City',
            render: (bol) => {
                let consigneeAddress = bol.bolAddresses.filter((address) => address.addressType === 'CONSIGNEE');
                return consigneeAddress[0]['city'];
            },
            headerClassName: 'pl-0 pr-0',
            rowCellClassName: 'pl-0 pr-0',
        },
        {
            label: 'State',
            render: (bol) => {
                let consigneeAddress = bol.bolAddresses.filter((address) => address.addressType === 'CONSIGNEE');
                return consigneeAddress[0]['state'];
            },
            headerClassName: 'pl-0 pr-0',
            rowCellClassName: 'pl-0 pr-0',
        },
        {
            label: 'Zip',
            render: (bol) => {
                let consigneeAddress = bol.bolAddresses.filter((address) => address.addressType === 'CONSIGNEE');
                return consigneeAddress[0]['zipCode'];
            },
            headerClassName: 'pl-0 pr-14',
            rowCellClassName: 'pl-0 pr-14',
        },
        {
            label: 'Billing Name',
            render: (bol) => {
                let consigneeAddress = bol.bolAddresses.filter((address) => address.addressType === 'BILLTO');
                return consigneeAddress[0]['companyName'];
            },
            headerClassName: 'pl-14 pr-0',
            rowCellClassName: 'pl-14 pr-0',
        },
        {
            label: 'Billing City',
            render: (bol) => {
                let consigneeAddress = bol.bolAddresses.filter((address) => address.addressType === 'BILLTO');
                return consigneeAddress[0]['city'];
            },
            headerClassName: 'pl-0 pr-0',
            rowCellClassName: 'pl-0 pr-0',
        },
        {
            label: 'Billing State',
            render: (bol) => {
                let consigneeAddress = bol.bolAddresses.filter((address) => address.addressType === 'BILLTO');
                return consigneeAddress[0]['state'];
            },
            headerClassName: 'pl-0 pr-0',
            rowCellClassName: 'pl-0 pr-0',
        },
        {
            label: 'Billing Zip',
            render: (bol) => {
                let consigneeAddress = bol.bolAddresses.filter((address) => address.addressType === 'BILLTO');
                return consigneeAddress[0]['zipCode'];
            },
            headerClassName: 'pl-0 pr-14',
            rowCellClassName: 'pl-0 pr-14',
        },
        {
            label: 'Shipment Terms',
            render: (bol) => bol.shipmentTerms,
            headerClassName: 'pl-14',
            rowCellClassName: 'pl-14',
        },
        {
            label: 'Weight',
            render: (bol) => {
                let weight = 0;
                bol.bolItems.forEach(item => {
                    weight += parseInt(item.weight);
                });
                return weight;
            },
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Quantity',
            render: (bol) => {
                let quantity = 0;
                bol.bolItems.forEach(item => {
                    quantity += parseInt(item.quantity);
                });
                return quantity;
            },
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Status',
            render: (bol) => bol.status,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Created At',
            render: (bol) => bol.createdAt,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Api Mode',
            render: (bol) => bol.apiMode,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'PDF',
            render: (bol) => {
                return (
                    <div>
                        {generateBolLoading ? <span>Please wait...</span> : <img onClick={() => downloadPDF(bol.id)} className='w-8 cursor-pointer' alt='' src={pdfFileIcon} />}
                    </div>
                );
            },
            headerClassName: 'pl-4',
            rowCellClassName: '',
        }
    ];

    const keyFun = (bol) => {
        return bol.id
    }

    const paginationLastLimitHandler = () => {
        let leftRecords = count - (offset + 1);
        if (leftRecords > recordsPerPage) {
            return (page) * recordsPerPage;
        }
        else {
            return count;
        }
    }

    const searchHandler = (e) => {
        e.preventDefault();
        // if (e.target.value.length > 2) {
        setSearch(e.target.value);
        // }
    }

    return (
        <div className="content px-3 lg:px-8">
            <div className="content-bar flex justify-between mt-6">
                <PageHeading>INCOMING BOLS</PageHeading>
                <div className="search-bar">
                    <div className="search-field-container flex p-2 pl-4 bg-white border border-gray-300 shadow-sm rounded-md">
                        <img className="pr-2" src={searchIcon} alt="Search Icon" />
                        <input value={search} onInput={(e) => searchHandler(e)} type="text" className="pl-1" placeholder="Customer Business Name" />
                    </div>
                </div>
            </div>
            <div className="mt-8">
                <div className='flex items-center justify-end mb-3'>
                    <label className='flex items-center gap-3'>
                        <span className='text-sm capitalize'>Mode: {mode}</span>
                        <Switch checkedIcon={false} uncheckedIcon={false} offColor="#eb0303" onChange={(value) => handleApiModeChange(value)} checked={testMode.checked} />
                    </label>
                </div>
                {loading ?
                    <Loader />
                    :
                    <div>
                        <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                            <div className="absolute inset-0"></div>
                            <div className="relative rounded-xl overflow-auto">
                                <div className="shadow-sm overflow-hidden mt-7">
                                    <SortableTable tableLayout="row" data={data} config={config} keyFun={keyFun} />
                                </div>
                            </div>
                            <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                        </div>
                        <div className="flex justify-between items-center self-stretch py-6">
                            {/* {count > 0 ? <div>Showing {offset + 1} to {(page) * recordsPerPage} of {count} results</div> : null} */}
                            {count > 0 ? <div>Showing {offset + 1} to {paginationLastLimitHandler()} of {count} results</div> : null}
                            {count > recordsPerPage ?
                                <div className="flex">
                                    <Pagination
                                        to="/bols"
                                        currentPage={page}
                                        totalPages={Math.ceil(count / recordsPerPage)}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                                : null}
                        </div>
                    </div>}
            </div>
        </div>
    );
}

export default BolsPage;