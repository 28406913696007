import React, { useState, useEffect, useContext } from 'react';

import SortableTable from "../components/SortableTable";
import PageHeading from "../components/PageHeading";

import { useNavigate, useParams } from 'react-router-dom'

import { gql, useMutation, useQuery } from '@apollo/client';

import { AuthContext } from '../context/authContext';
import moment from 'moment';
import Loader from '../components/Loader';
import ImageGallery from '../components/ImageGallery';

function PickupRequestDetailPage() {
    const [errors, setErrors] = useState([]);
    const [driverAssignmentText, setDriverAssignmentText] = useState('');
    const { id } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    const { token } = useContext(AuthContext);
    const [driverName, setDriverName] = useState('');
    const [pickupShipperAddress, setPickupShipperAddress] = useState([]);
    const [pickupBillingAddress, setPickupBillingAddress] = useState([]);
    const [pickupConsigneeAddress, setPickupConsigneeAddress] = useState([]);
    const [pickupItems, setPickupItems] = useState([]);
    const [pickupNumber, setPickupNumber] = useState('');
    const [driverOptions, setDriverOptions] = useState([]);
    const [truckOptions, setTruckOptions] = useState([]);
    const [formData, setFormData] = useState({ truckId: 0, driverId: 0 });
    const pageValue = urlParams.get('page');
    const [pickupLogs, setPickupLogs] = useState([]);
    const [pickupImages, setPickupImages] = useState([]);
    const navigate = useNavigate();

    // get the data from the server
    const GET_PICKUP_REQUEST = gql`
        query GetPickupRequest($id: Int!) {
            pickupRequest(id: $id) {
                id,
                createdAt,
                apiMode,
                shipmentDate,
                pickupDate,
                pickupTime,
                pickupNumber,
                shipmentTerms,
                serviceType,
                pickupStatus,
                customer {
                    contactPersonFullName,
                    businessName
                }
                account {
                    id,
                    companyName,
                },
                pickupRequestAddresses {
                    id,
                    companyName,
                    addressType,
                    address1,
                    address2,
                    city,
                    state,
                    zipCode,
                },
                truck {
                    id
                },
                driver {
                    id,
                    user {
                        firstName,
                        lastName
                    }
                },
                pickupRequestItems {
                    id,
                    description,
                    quantity,
                    length,
                    width,
                    height,
                    weight,
                    pallets,
                    shipmentClass,
                    charges
                },
                pickupRequestLogs {
                    id,
                    action,
                    description,
                    createdAt,
                }
                pickupRequestImages {
                    id,
                    image,
                    event,
                    createdAt,
                }
            }
        }
    `;

    const GET_DRIVERS = gql`
        query getDrivers {
            drivers {
                id,
                user {
                    firstName,
                    lastName,
                }
                defaultTruck {
                    id
                }
            }
        }
    `;

    const GET_TRUCKS = gql`
        query getTrucks {
            trucks {
                id,
                numberPlate,
                make,
                model,
            }
        }
    `;

    const UPDATE_PICKUPREQUEST = gql`
        mutation ($id: ID!, $driverId: ID!, $truckId: ID!, $status: String, $remarks: String) {
            updatePickupRequest (id: $id, driverId: $driverId, truckId: $truckId, status: $status, remarks: $remarks) {
                pickupRequest {
                    pickupTime,
                    pickupDate,
                    shipmentId,
                    pickupNumber,
                    pickupStatus,
                    truck {
                        id
                    },
                    driver {
                        id,
                        user {
                            firstName,
                            lastName
                        }
                    },
                    pickupRequestLogs {
                        id,
                        action,
                        description,
                        createdAt,
                    }
                }
            }
        }
    `;

    const [updatePickupRequest, { pickupRequestLoding, pickupRequestError, data: pickupRequestUpdatedData }] = useMutation(UPDATE_PICKUPREQUEST);
    const { driversLoding, driversError, data: driversData } = useQuery(GET_DRIVERS, {
        context: {
            headers: {
                'Authorization': `JWT ${token}`,
            },
        },
    });

    const { trucksLoading, trucksError, data: trucksData } = useQuery(GET_TRUCKS, {
        context: {
            headers: {
                'Authorization': `JWT ${token}`
            }
        }
    });

    const { loading, error, data: pickupData } = useQuery(GET_PICKUP_REQUEST, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: {
            id: parseInt(id),
        },
    });

    const [data, setPickup] = useState([]);

    useEffect(() => {
        if (pickupData) {
            if (pickupData.pickupRequest) {
                setPickup([pickupData.pickupRequest]);
                let previousDriverName = pickupData.pickupRequest?.driver ? pickupData.pickupRequest?.driver?.user?.firstName + " " + pickupData.pickupRequest?.driver?.user?.lastName : null;
                let pickupRequestLogs = pickupData.pickupRequest.pickupRequestLogs;
                let pickupRequestImages = pickupData.pickupRequest.pickupRequestImages;
                setPickupLogs(pickupRequestLogs);
                setPickupImages(pickupRequestImages);
                setPickupNumber(pickupData?.pickupRequest?.pickupNumber ? pickupData?.pickupRequest?.pickupNumber : '');
                previousDriverName != null ? setDriverName(previousDriverName) : setDriverName('');
                setDriverAssignmentText(`This pickup Request is assigned to " <strong className='capitalize'>${previousDriverName}</strong>"`);
                setFormData({
                    driverId: pickupData.pickupRequest?.driver?.id,
                    truckId: pickupData.pickupRequest?.truck?.id,
                });
            }

            if (pickupData.pickupRequest.pickupRequestAddresses) {
                const pickupRequestAddresses = pickupData.pickupRequest.pickupRequestAddresses;
                const pickupRequestShipperAddress = pickupRequestAddresses.filter((pickupRequestAddress) => pickupRequestAddress.addressType === 'SHIPPER' || pickupRequestAddress.addressType === 'SOURCE');
                const pickupRequestBillingAddress = pickupRequestAddresses.filter((pickupRequestAddress) => pickupRequestAddress.addressType === 'BILLTO');
                const pickupRequestConsigneeAddress = pickupRequestAddresses.filter((pickupRequestAddress) => pickupRequestAddress.addressType === 'CONSIGNEE' || pickupRequestAddress.addressType === 'DESTINATION');

                setPickupShipperAddress(pickupRequestShipperAddress);
                setPickupBillingAddress(pickupRequestBillingAddress);
                setPickupConsigneeAddress(pickupRequestConsigneeAddress);
            }

            if (pickupData.pickupRequest.pickupRequestItems) {
                const pickupRequestItems = pickupData.pickupRequest.pickupRequestItems;
                setPickupItems(pickupRequestItems);
            }

        }
    }, [pickupData, setPickup]);

    useEffect(() => {
        if (driversData && driversData.drivers) {
            let driverOptions = driversData?.drivers.map((driver) => {
                return ({
                    label: driver?.user?.firstName + " " + driver.user.lastName[0].toUpperCase(),
                    value: driver.id
                });
            });

            setDriverOptions(driverOptions);
        }
    }, [driversData, setDriverOptions])

    useEffect(() => {
        if (trucksData && trucksData.trucks) {
            let truckOptions = trucksData?.trucks.map((truck) => {
                return ({
                    label: truck?.make + "-" + truck.model + " ( " + truck.numberPlate + " )",
                    value: truck.id
                });
            });

            setTruckOptions(truckOptions);
        }
    }, [trucksData, setTruckOptions])

    useEffect(() => {
        if (pickupRequestUpdatedData && pickupRequestUpdatedData.updatePickupRequest?.pickupRequest) {
            let pickupRequest = pickupRequestUpdatedData.updatePickupRequest?.pickupRequest;
            let previousDriverName = pickupRequest.driver ? pickupRequest.driver?.user?.firstName + " " + pickupRequest.driver?.user?.lastName : null;
            let pickupRequestLogs = pickupRequest.pickupRequestLogs;
            setPickupLogs(pickupRequestLogs);
            previousDriverName != null ? setDriverName(previousDriverName) : setDriverName('');
            setDriverAssignmentText(`This pickup Request is now assigned to " <strong className='capitalize'>${previousDriverName}</strong>"`);
            setFormData({
                driverId: pickupRequest.driver?.id,
                truckId: pickupRequest.truck?.id,
            });


            // window.location.href = '/admin/pickup-requests';
        }
    }, [pickupRequestUpdatedData, setPickup]);

    useEffect(() => {
    }, [pageValue]);

    useEffect(() => {

    }, [formData.driverId])

    if (error || pickupRequestError || driversError || trucksError) {
        return <p>Error :(</p>;
    }

    const driverHandle = (e) => {
        setFormData({
            ...formData,
            driverId: e.target.value
        });

        driversData.drivers.forEach((driver, index) => {
            if (driver.id === e.target.value && driver?.defaultTruck?.id != null) {
                return setFormData({
                    driverId: e.target.value,
                    truckId: driver?.defaultTruck?.id
                });
            }
            return false;
        });

    }

    const truckHandle = (e) => {
        return setFormData({
            ...formData,
            truckId: e.target.value
        });
    }
    const cancelDriverHandle = (e) => {
        setDriverName('');
        setErrors([]);
        if (pickupData.pickupRequest?.driver != null && pickupData.pickupRequest?.truck != null) {
            setDriverName(pickupData.pickupRequest.driver?.user.firstName + " " + pickupData.pickupRequest.driver?.user.lastName)
            setFormData({
                driverId: pickupData.pickupRequest.driver.id,
                truckId: pickupData.pickupRequest.truck.id
            });
        }
        return false;
    }

    const assignDriverHandle = (e) => {
        if ((formData.driverId && parseInt(formData.driverId) !== 0) || (formData.truckId && parseInt(formData.truckId) !== 0)) {
            e.preventDefault();
            updatePickupRequest({
                context: {
                    headers: {
                        'Authorization': `JWT ${token}`,
                    },
                },
                variables: {
                    id: parseInt(id),
                    driverId: formData.driverId,
                    truckId: formData.truckId,
                    status: 'driver-assigned',
                    remarks: '',
                }
            });
        }
        else {
            setErrors({ 'assign_driver_error': 'Please Select Driver and Truck to proceed' })
            return false;
        }


    }

    const reAssignDriverHandle = () => {
        setDriverName('');
    }

    const pickupConfig = [
        {
            label: 'ID',
            render: (pickup) => pickup.id,
            headerClassName: 'pr-8 w-12',
            rowCellClassName: '',
        },
        {
            label: 'Customer',
            render: (pickup) => pickup.customer[0]['businessName'],
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Pickup Date',
            render: (pickup) => pickup.pickupDate,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Pickup Time',
            render: (pickup) => pickup.pickupTime,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Created At',
            render: (pickup) => pickup.createdAt,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Api Mode',
            render: (pickup) => pickup.apiMode,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'Shipment Terms',
            render: (pickup) => pickup.shipmentTerms,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'Service Type',
            render: (pickup) => pickup.serviceType,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        }
    ];

    const addressConfig = [
        {
            label: 'Company Name',
            render: (pickupAddress) => pickupAddress.companyName,
            headerClassName: 'pr-8',
            rowCellClassName: '',
            width: '30%',
        },
        {
            label: 'Address',
            render: (pickupAddress) => pickupAddress.address1 + ' ' + pickupAddress.address2,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'City',
            render: (pickupAddress) => pickupAddress.city,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'State',
            render: (pickupAddress) => pickupAddress.state,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'Zip Code',
            render: (pickupAddress) => pickupAddress.zipCode,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        }
    ];

    const pickupItemConfig = [
        {
            label: 'Description',
            render: (pickupItem) => pickupItem.description,
            headerClassName: 'pr-8',
            rowCellClassName: '',
            width: '30%',
        },
        {
            label: 'Shipping Class',
            render: (pickupItem) => pickupItem.shipmentClass,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'Length',
            render: (pickupItem) => pickupItem.length,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'Width',
            render: (pickupItem) => pickupItem.width,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'Height',
            render: (pickupItem) => pickupItem.height,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'Weight',
            render: (pickupItem) => pickupItem.weight,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'Quantity',
            render: (pickupItem) => pickupItem.quantity,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        // {
        //     label: 'Charges',
        //     render: (pickupItem) => pickupItem.charges,
        //     headerClassName: 'pl-4',
        //     rowCellClassName: '',
        // }
    ];

    const keyFun = (pickup) => {
        return pickup.id
    }

    const renderPickupLogs = () => {
        if (pickupLogs.length === 0) {
            return (<p>No Logs to show</p>)
        }



        return (
            <div className='h-[35rem] overflow-y-scroll'>

                {pickupRequestLoding ? <Loader /> :
                    pickupLogs.map((pickupLog) => {
                        const createdMoment = moment(pickupLog.createdAt);
                        const date = createdMoment.format('DD/MM/YYYY');
                        const time = createdMoment.format('hh:mmA');
                        return (
                            <div key={pickupLog.id} className='border-b px-2 cursor-pointer py-5'>
                                <div >
                                    <div className='flex items-center justify-between'>
                                        <span className='text-xs font-semibold mb-2'>{date}</span>
                                        <span className='text-xs font-semibold mb-2'>{time}</span>
                                    </div>
                                    <p><span className="font-semibold text-blue-600 capitalize mb-2">{pickupLog.action}</span></p>
                                </div>
                                <div>
                                    <p className='text-sm leading-loose'>
                                        {pickupLog.description}
                                    </p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const handleCancel = () => {
        navigate("/admin/pickup-requests");
    }

    return (
        <div className="content px-3 lg:px-8">

            {loading || trucksLoading || driversLoding ?
                <Loader />
                :
                <div>
                    <div className='flex gap-5'>
                        <div className="mt-8 w-4/5">
                            <div className="content-bar flex justify-between mt-6">
                                <PageHeading>PICKUP REQUEST DETAIL FOR "{id}"</PageHeading>
                            </div>
                            <div><p className='text-xl font-semibold'>Pickup Number: {pickupNumber}</p></div>
                            <div className='flex gap-5'>
                                <div className="mt-8 w-full">
                                    <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                                        <div className="absolute inset-0"></div>
                                        <div className="relative rounded-xl overflow-auto">
                                            <div className="shadow-sm overflow-hidden mt-7">
                                                <div className="w-full">
                                                    <SortableTable tableLayout="row" data={data} config={pickupConfig} keyFun={keyFun} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                                    </div>
                                </div>

                            </div>

                            <div className="mt-8 w-full mb-8">
                                <div className="mt-8 w-full mb-3">
                                    <PageHeading>Pickup Request Items</PageHeading>
                                </div>
                                <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                                    <div className="absolute inset-0"></div>
                                    <div className="relative rounded-xl overflow-auto">
                                        <div className="shadow-sm overflow-hidden mt-7">
                                            <div className="w-full">
                                                <SortableTable tableLayout="row" data={pickupItems} config={pickupItemConfig} keyFun={keyFun} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                                </div>
                            </div>

                            <div className='flex gap-5 columns-3'>
                                {pickupShipperAddress.length > 0 && <div className="w-full">
                                    <div className="mb-3">
                                        <PageHeading>Source Address</PageHeading>
                                    </div>
                                    <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                                        <div className="absolute inset-0"></div>
                                        <div className="relative rounded-xl overflow-auto">
                                            <div className="shadow-sm overflow-hidden mt-7">
                                                <div className="w-full">
                                                    <SortableTable tableLayout="column" data={pickupShipperAddress} config={addressConfig} keyFun={keyFun} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                                    </div>
                                </div>}

                                {pickupConsigneeAddress.length > 0 && <div className="w-full">
                                    <div className="mb-3">
                                        <PageHeading>Destination Address</PageHeading>
                                    </div>
                                    <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                                        <div className="absolute inset-0"></div>
                                        <div className="relative rounded-xl overflow-auto">
                                            <div className="shadow-sm overflow-hidden mt-7">
                                                <div className="w-full">
                                                    <SortableTable tableLayout="column" data={pickupConsigneeAddress} config={addressConfig} keyFun={keyFun} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                                    </div>
                                </div>}

                                {pickupBillingAddress.length > 0 && <div className="w-full">
                                    <div className="mb-3">
                                        <PageHeading>Billing Address</PageHeading>
                                    </div>
                                    <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                                        <div className="absolute inset-0"></div>
                                        <div className="relative rounded-xl overflow-auto">
                                            <div className="shadow-sm overflow-hidden mt-7">
                                                <div className="w-full">
                                                    <SortableTable tableLayout="column" data={pickupBillingAddress} config={addressConfig} keyFun={keyFun} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                                    </div>
                                </div>}
                            </div>
                            <div>
                                {pickupImages.length > 0 && <div className="mt-8 w-full">
                                    <ImageGallery images={pickupImages} />
                                </div>}
                            </div>
                        </div>
                        <div className="mt-8 w-1/5">
                            {data[0]?.pickupStatus !== 'canceled' && <div className="mt-8 w-full">
                                <PageHeading>Assign Driver</PageHeading>
                                {driverName !== '' ?
                                    <div className='mt-4'>
                                        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                                            <strong className="font-bold">Note! </strong><br />
                                            <span className="block sm:inline" dangerouslySetInnerHTML={{ __html: driverAssignmentText }} ></span>
                                        </div>
                                        <div className="flex justify-end my-3 ">
                                            <button onClick={reAssignDriverHandle} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transhtmlForm bg-red-700 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600">Change Driver</button>
                                        </div>
                                    </div>
                                    :
                                    <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden mt-4 border">
                                        <div className="absolute inset-0"></div>
                                        <div className="relative rounded-xl overflow-auto">
                                            <div className="shadow-sm overflow-hidden mt-7">
                                                <div className="w-full px-4">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-default-truck">
                                                        Select Drivers
                                                    </label>
                                                    <div className="relative mb-3">
                                                        <select value={formData.driverId} onChange={driverHandle} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="grid-default-truck">
                                                            <option value={0}>Select Drivers</option>
                                                            {
                                                                driverOptions.map((driver) => {
                                                                    return (
                                                                        <option value={driver.value} key={driver.value}>{driver.label}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative rounded-xl overflow-auto">
                                            <div className="shadow-sm overflow-hidden mt-7">
                                                <div className="w-full px-4">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-default-truck">
                                                        Select Trucks
                                                    </label>
                                                    <div className="relative mb-3">
                                                        <select value={formData.truckId} onChange={truckHandle} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="grid-default-truck">
                                                            <option value={0}>Select Trucks</option>
                                                            {
                                                                truckOptions.map((truck) => {
                                                                    return (
                                                                        <option value={truck.value} key={truck.value}>{truck.label}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div>

                                                    </div>
                                                </div>
                                                {errors && errors.assign_driver_error && <div className="flex justify-end my-3 px-4">
                                                    <p className="text-red-500 text-xs italic">{errors.assign_driver_error}</p>
                                                </div>}
                                                <div className="flex justify-end my-3 px-4">
                                                    <button onClick={cancelDriverHandle} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transhtmlForm bg-blue-700 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600 mr-2">Cancel</button>
                                                    <button onClick={assignDriverHandle} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transhtmlForm bg-blue-700 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">Assign Driver</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                }
                            </div>}
                            <div className="border px-2 rounded-xl mt-8">
                                <h3 className='text-lg font-semibold py-3'>Pickup Request Logs</h3>
                                <hr></hr>
                                <div className='h-[34rem] overflow-hidden'>
                                    {renderPickupLogs()}
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            }


            <div className='w-full mt-5 flex items-center justify-end'>
                <button onClick={handleCancel} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500">Cancel</button>
            </div>

        </div>
    );
}

export default PickupRequestDetailPage;