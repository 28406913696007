
import loadingImage from '../assets/images/bolerm-loading-without-bg.gif';
function Loader() {

    return (
        <div className='flex items-center justify-center my-5'>
            <img src={loadingImage} alt="loading" />
        </div>
    );
}

export default Loader;