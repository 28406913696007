import React, { useState, useEffect, useContext } from 'react';

import SortableTable from "../../components/SortableTable";
import PageHeading from "../../components/PageHeading";

import { Link } from 'react-router-dom'
import Pagination from "../../components/Pagination";

import searchIcon from '../../assets/search-icon.svg';
import { gql, useMutation, useQuery } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
// import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import Loader from '../../components/Loader';

function TruckFeaturesPage() {

    const urlParams = new URLSearchParams(window.location.search);

    const { token } = useContext(AuthContext);

    // const searchValue = urlParams.get('search');
    const [search, setSearch] = useState("");

    const pageValue = urlParams.get('page');
    const [page, setPage] = useState(pageValue ? parseInt(pageValue) : 1);

    const recordsPerPage = 10;
    const [offset, setOffset] = useState((page - 1) * recordsPerPage);
    const [count, setCount] = useState(0);
    // const [isMount, setMount] = useState(false)
    // useEffect(() => {
    //     setMount(true)
    // }, [])

    const GET_TRUCK_FEATURES = gql`
        query GetTruckFeatures($first: Int, $skip: Int, $search: String, $sortBy: TruckFeatureSortInput) {
            truckFeatures(first: $first, skip: $skip, search: $search, sortBy: $sortBy) {
                id,
                name
            },
            truckFeatureCount(search: $search)
        }
    `;
    const DELETE_TRUCK_FEATURE = gql`
        mutation($id: ID) {
            deleteTruckFeature(id: $id){
                truckFeature {
                    name
                }
            }
        }
    `;

    const [deleteTruckFeature, { data: updatedTruckFeatures }] = useMutation(DELETE_TRUCK_FEATURE);


    const { loading, error, data: truckFeaturesData, refetch } = useQuery(GET_TRUCK_FEATURES, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: {
            first: recordsPerPage,
            skip: offset,
            search: search.length > 3 ? search : '',
            sortBy: {
                field: 'id',
                direction: 'desc',
            },
        },
    });

    const [data, setData] = useState([]);

    useEffect(() => {
        if (truckFeaturesData && truckFeaturesData.truckFeatures) {
            const features = truckFeaturesData.truckFeatures;
            setData(features);
            setCount(truckFeaturesData.truckFeatureCount);
        }
    }, [truckFeaturesData, setData]);

    const fetchTruckFeatures = async () => {
        refetch({
            first: recordsPerPage,
            skip: offset,
            search: search,
            sortBy: {
                field: 'id',
                direction: 'desc',
            },
        });

    }

    useEffect(() => {
        if (updatedTruckFeatures && updatedTruckFeatures.deleteTruckFeature?.truckFeature) {
            fetchTruckFeatures();
        }
    }, [updatedTruckFeatures, setData]);

    useEffect(() => {
        if (updatedTruckFeatures && updatedTruckFeatures.truckFeatures) {
            const truckFeatures = updatedTruckFeatures.truckFeatures;
            setData(truckFeatures);
            setCount(updatedTruckFeatures.truckFeatureCount)
        }
    }, [updatedTruckFeatures, setData]);

    useEffect(() => {
        setPage(pageValue ? parseInt(pageValue) : 1);
    }, [pageValue]);

    if (error) {
        return <p>Error :(</p>;
    }

    const handlePageChange = (page) => {
        setPage(page);
        setOffset((page - 1) * recordsPerPage);
    }

    // const notify = () => toast("Wow so easy!");

    // const handleEdit = (id) => {
    //     window.location.href = '/admin/trucks/' + id;
    // }

    const config = [
        {
            label: 'ID',
            render: (truckFeature) => <Link
                to={"/admin/truck-features/" + truckFeature.id}
                className='text-blue-600 text-sm leading-5 font-normal'
            >{truckFeature.id}</Link>,
            headerClassName: 'pr-8 w-12',
            rowCellClassName: '',
        },
        {
            label: 'Name',
            render: (truckFeature) => truckFeature.name,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Actions',
            render: (truckFeature) => (
                <>
                    <div key={truckFeature.id} className='flex gap-3'>
                        <svg onClick={() => handleDelete(truckFeature.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="fill-red-500 cursor-pointer" height="25" width="17" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg>
                        <Link
                            to={"/admin/truck-features/" + truckFeature.id}
                            className=''
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5" stroke="currentColor" className="fill-green-500 cursor-pointer" height="25" width="20" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" /></svg>
                        </Link>

                    </div>
                </>
            ),
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
    ];

    const keyFun = (truckFeature) => {
        return truckFeature.id
    }

    const handleDelete = (id) => {
        console.log(id);
        Swal.fire({
            title: 'Are you Sure you want to delete this?',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            icon: 'warning'
        }
        ).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                deleteTruckFeature({
                    variables: { id },
                });
                Swal.fire('Data deleted successfully', '', 'success');
            }
        });
    }

    const searchHandler = (e) => {
        e.preventDefault();
        // if (e.target.value.length > 2) {
        setSearch(e.target.value);
        // }
    }


    const paginationLastLimitHandler = () => {
        let leftRecords = count - (offset + 1);
        if (leftRecords > recordsPerPage) {
            return (page) * recordsPerPage;
        }
        else {
            return count;
        }
    }

    return (
        <div className="content px-3 lg:px-8">
            <div className="content-bar flex justify-between mt-6">
                <PageHeading>Truck Features</PageHeading>
                <div className="search-bar">
                    <div className="search-field-container flex p-2 pl-4 bg-white border border-gray-300 shadow-sm rounded-md">
                        <img className="pr-2" src={searchIcon} alt="Search Icon" />
                        <input value={search} onInput={(e) => searchHandler(e)} type="text" className="pl-1" placeholder="Name" />
                    </div>
                </div>
            </div>
            <div className="mt-8 w-full">
                {loading ?
                    <Loader />
                    :
                    <div className="mt-8 w-1/2">
                        <div className='flex items-center justify-end mb-3'>
                            <Link
                                to={"/admin/truck-features/create"}
                                className=''
                            >
                                <button type="submit" className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span className="ml-2">New Truck Feature</span>
                                </button>
                            </Link>

                        </div>

                        <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                            <div className="absolute inset-0"></div>
                            <div className="relative rounded-xl overflow-auto">
                                <div className="shadow-sm overflow-hidden mt-7">
                                    <SortableTable tableLayout="row" data={data} config={config} keyFun={keyFun} />
                                </div>
                            </div>
                            <div className="flex justify-between items-center self-stretch py-6">
                                {/* {count > 0 ? <div>Showing {offset + 1} to {(page) * recordsPerPage} of {count} results</div> : null} */}
                                {count > 0 ? <div>Showing {offset + 1} to {paginationLastLimitHandler()} of {count} results</div> : null}
                                {count > recordsPerPage ?
                                    <div className="flex">
                                        <Pagination
                                            to="/bols"
                                            currentPage={page}
                                            totalPages={Math.ceil(count / recordsPerPage)}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                }
            </div>

        </div>
    );
}

export default TruckFeaturesPage;