import React, { useState, useEffect, useContext, useRef } from 'react';
import PageHeading from "../components/PageHeading";

import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';

import SortableTable from "../components/SortableTable";
import { AuthContext } from '../context/authContext';

import { Link } from 'react-router-dom'
import Pagination from "../components/Pagination";
import searchIcon from '../assets/search-icon.svg';
import Loader from '../components/Loader';
import ReactSimpleSuggest from '../components/ReactSimpleSuggest';
import CreateCustomerModal from '../components/CreateCustomerModal';
import ManualPickupRequest from '../components/Manual Pickup Request/ManualPickupRequest';
import EditTableColumnSettingModal from '../components/table/EditTableColumnSettingModal';


const GET_PICKUP_REQUESTS = gql`
    query GetPickupRequests($first: Int, $skip: Int, $search: String, $sortBy: PickupRequestSortInput) {
        pickupRequests(first: $first, skip: $skip, search: $search, sortBy: $sortBy) {
            id,
            createdAt,
            apiMode,
            pickupNumber,
            pickupStatus,
            driver {
                id,
                user {
                    firstName,
                    lastName
                }
            },
            truck {
                id,
                numberPlate,
                make,
                model,
                color
            }
            account {
                id,
                companyName,
            }
            customer {
                id,
                contactPersonFullName,
                businessName
            }
        },
        pickupCount(search: $search)
    }
`;


const GET_COLUMN_SETTINGS = gql`
  query GetUserTableColumnSetting($tableName: String!) {
    userTableColumnSetting(tableName: $tableName) {
      columnSettings
    }
  }
`;

// GraphQL mutation to update column settings



function PickupRequestPage() {

    const urlParams = new URLSearchParams(window.location.search);

    const { token } = useContext(AuthContext);
    const [search, setSearch] = useState("");
    const [modalVisibility, setModalVisibility] = useState(false);
    // const searchValue = urlParams.get('search');
    // const [search] = useState(searchValue);

    const pageValue = urlParams.get('page');
    const [page, setPage] = useState(pageValue ? parseInt(pageValue) : 1);

    const [errors, setErrors] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [disableCities, setDisableCities] = useState(false);
    const [cityOptions, setCityOptions] = useState([]);
    const [customerSearchValue, setCustomerSearchValue] = useState('');
    const [selectedTab, setSelectedTab] = useState('PickupRequest')
    const [tableName, setTableName] = useState('pickupRequest')


    // const { token } = useContext(AuthContext);
    // const navigate = useNavigate();


    const recordsPerPage = 10;
    const [offset, setOffset] = useState((page - 1) * recordsPerPage);



    const { loading, error, data: pickupData } = useQuery(GET_PICKUP_REQUESTS, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: {
            first: recordsPerPage,
            skip: offset,
            search: search.length > 3 ? search : '',
            sortBy: {
                field: 'id',
                direction: 'desc',
            },
        },
    });

    const { data: columnSettingData, loading: columnSettingLoading, error: columnSettingError } = useQuery(GET_COLUMN_SETTINGS, {
        fetchPolicy: 'network-only',
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: { tableName: tableName }
    });


    const [columns, setColumns] = useState([]);

    useEffect(() => {
        console.log('check column length > 0', columns.length, columnSettingData)
        if (columnSettingData?.userTableColumnSetting) {
            let columnSettings = JSON.parse(columnSettingData?.userTableColumnSetting?.columnSettings)
            setColumns(columnSettings?.columns);
        }
        else if (columns.length === 0) {
            setColumns([
                { "id": 1, "name": "ID", "slug": "id", "order": 1, "visible": true },
                { "id": 2, "name": "Customer", "slug": "customer", "order": 2, "visible": true },
                { "id": 3, "name": "Pickup Number", "slug": "pickup-number", "order": 3, "visible": true },
                { "id": 4, "name": "Pickup Status", "slug": "pickup-status", "order": 4, "visible": true },
                { "id": 5, "name": "Assigned Driver", "slug": "assigned-driver", "order": 5, "visible": true },
                { "id": 6, "name": "Assigned Truck", "slug": "assigned-truck", "order": 6, "visible": true },
                { "id": 7, "name": "Status", "slug": "status", "order": 7, "visible": true },
                { "id": 8, "name": "Created At", "slug": "created-at", "order": 8, "visible": true },
                { "id": 9, "name": "Api Mode", "slug": "api-mode", "order": 9, "visible": true }
            ]);
        }
    }, [columnSettingData]);


    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);

    const fetchPickupRequests = async () => {
        if (pickupData && pickupData.pickupRequests) {
            const pickupRequests = await pickupData.pickupRequests;
            setData(pickupRequests);
            setCount(pickupData.pickupCount);
        }
    }

    useEffect(() => {
        fetchPickupRequests();
    }, [pickupData, setData]);

    useEffect(() => {
        setPage(pageValue ? parseInt(pageValue) : 1);
    }, [pageValue]);





    if (error) {
        return <p>Error :(</p>;
    }

    const handlePageChange = (page) => {
        setPage(page);
        setOffset((page - 1) * recordsPerPage);
    }

    const originalConfig = [
        {
            label: 'ID',
            slug: 'id',
            render: (pickup) => <Link to={`/admin/pickup-requests/${pickup.id}`} className='text-blue-600 text-sm leading-5 font-normal'>{pickup.id}</Link>,
            headerClassName: 'pr-8 w-12',
            rowCellClassName: '',
        },
        {
            label: 'Pickup Number',
            slug: 'pickup-number',
            render: (pickup) => pickup.pickupNumber,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Customer',
            slug: 'customer',
            render: (pickup) => pickup.customer[0]['businessName'],
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Pickup Status',
            slug: 'pickup-status',
            render: (pickup) => pickup.pickupStatus,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Assigned Driver',
            slug: 'assigned-driver',
            render: (pickup) => pickup.driver?.user?.firstName ? pickup.driver?.user?.firstName + " " + pickup.driver?.user?.lastName : '',
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Assigned Truck',
            slug: 'assigned-truck',
            render: (pickup) => pickup.truck ? pickup.truck.make + " " + pickup.truck.model + ` (${pickup.truck.numberPlate})` : '',
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Status',
            slug: 'status',
            render: (pickup) => pickup.pickupStatus,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Created At',
            slug: 'created-at',
            render: (pickup) => pickup.createdAt,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Api Mode',
            slug: 'api-mode',
            render: (pickup) => pickup.apiMode,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        }
    ];

    const updatedConfig = columns
        .filter(setting => setting.visible)  // Only include visible columns
        .sort((a, b) => a.order - b.order)  // Sort by order
        .map(setting => originalConfig.find(col => col.slug === setting.slug));

    const keyFun = (pickup) => {
        return pickup.id
    }

    const searchHandler = (e) => {
        e.preventDefault();
        // if (e.target.value.length > 2) {
        setSearch(e.target.value);
        // }
    }

    const tabChangeHandle = () => {
        if (selectedTab === 'PickupRequest') {
            setSelectedTab('manualPickupRequest')
        }
        else {
            setSelectedTab('PickupRequest')
        }
    }



    const updateColumnsHandle = (data) => {
        console.log("data pr columnd", data);
        setColumns(data);
    }

    return (
        <div>
            <div className="content px-3 lg:px-8">
                <div className="content-bar flex justify-between mt-6">
                    <div className='flex flex-wrap justify-between'>
                        <div className="mr-3">
                            <PageHeading>PICK UP REQUESTS</PageHeading>
                        </div>
                        <button onClick={() => tabChangeHandle()} type="button" className={`mt-3 sm:mt-0 rounded-md ${selectedTab === 'manualPickupRequest' ? 'bg-indigo-600 text-white ' : 'bg-white-600 text-dark border border-black'}bg-indigo-600 px-3 py-2 text-sm font-semibold shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center`}>
                            <span className='w-[20px] h-[20px] rounded-[50px] bg-red-500 flex items-center justify-center'>
                                <svg fill='#fff' width="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path d="M22.7 33.4c13.5-4.1 28.1 1.1 35.9 12.9L224 294.3 389.4 46.2c7.8-11.7 22.4-17 35.9-12.9S448 49.9 448 64V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V169.7L250.6 369.8c-5.9 8.9-15.9 14.2-26.6 14.2s-20.7-5.3-26.6-14.2L64 169.7V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 49.9 9.2 37.5 22.7 33.4z" /></svg>
                            </span>
                            <span className="ml-2">Manual Pickup</span>
                        </button>
                    </div>
                </div>
            </div>
            {
                selectedTab === 'PickupRequest' &&
                <div className="content px-3 lg:px-8">
                    <div className="content-bar flex justify-between mt-6">
                        <div className="search-bar w-full sm:w-[255px]">
                            <div className="search-field-container flex p-2 pl-4 bg-white border border-gray-300 shadow-sm rounded-md">
                                <img className="pr-2" src={searchIcon} alt="Search Icon" />
                                <input value={search} onInput={(e) => searchHandler(e)} type="text" className="pl-1" placeholder="Customer Business Name" />
                            </div>
                        </div>
                        <div>

                            <EditTableColumnSettingModal columns={columns} tableName="pickupRequest" updateColumns={(data) => updateColumnsHandle(data)} />

                        </div>
                    </div>
                    {loading ? <div>
                        <Loader />
                    </div> :
                        <div className="mt-8">
                            <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                                <div className="absolute inset-0"></div>
                                <div className="relative rounded-xl overflow-auto">
                                    <div className="shadow-sm overflow-x-scroll mt-7">
                                        <SortableTable tableLayout="row" data={data} config={updatedConfig} keyFun={keyFun} />
                                    </div>
                                </div>
                                <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                            </div>
                            <div className="flex justify-between items-center self-stretch py-6">
                                {count > 0 ? <div>Showing {offset + 1} to {(page) * recordsPerPage} of {count} results</div> : null}
                                {count > recordsPerPage ?
                                    <div className="flex">
                                        <Pagination
                                            to="/bols"
                                            currentPage={page}
                                            totalPages={Math.ceil(count / recordsPerPage)}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                    : null}
                            </div>
                        </div>}
                </div>
            }
            {selectedTab === 'manualPickupRequest' && <ManualPickupRequest changeTab={tabChangeHandle} />}


        </div >
    );
}

export default PickupRequestPage;