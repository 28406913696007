import TopNavigation from '../../components/TopNavigation';
import TopBar from '../../components/TopBar';
import { Outlet } from 'react-router-dom';

function AdminLayout(props) {
    return (
        <div className="mx-auto py-2 px-0">
            <div className="px-3 lg:px-8 border-b">
                <TopBar />
                <TopNavigation />
            </div>
            <div className="">
                <Outlet />
            </div>
        </div>
    );
}

export default AdminLayout;