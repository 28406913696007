// import React, { useEffect, useRef, useState } from 'react';
// import { useMutation, gql } from '@apollo/client';

// const UPLOAD_IMAGE = gql`
//   mutation UploadImage($file: Upload!) {
//     uploadImage(file: $file) {
//       url
//     }
//   }
// `;

// const CameraButton = ({ images = [], alltakenImages }) => {
//     const [uploadImage] = useMutation(UPLOAD_IMAGE);
//     const videoRef = useRef(null);
//     // const [imageSrcList, setImageSrcList] = useState([]);
//     const canvasRef = useRef(null);
//     const [isCameraOn, setIsCameraOn] = useState(false);

//     // useEffect(() => {
//     //     setImageSrcList(images);
//     // }, [images])

//     const startCamera = async (e) => {
//         e.preventDefault();
//         setIsCameraOn(true);
//         const stream = await navigator.mediaDevices.getUserMedia({ video: true });
//         videoRef.current.srcObject = stream;
//     };

//     const capturePhoto = (e) => {
//         e.preventDefault();
//         const context = canvasRef.current.getContext('2d');
//         context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
//         const dataUrl = canvasRef.current.toDataURL('image/jpeg');

//         // setImageSrcList(prevList => {
//         //     const newList = [...prevList, dataUrl];
//         //     alltakenImages(newList); // Update parent component
//         //     return newList;
//         // });
//         alltakenImages(dataUrl)
//         setIsCameraOn(false);
//         // canvasRef.current.toBlob(async (blob) => {
//         //     const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' });
//         //     try {
//         //         console.log('Picture taken:', file);
//         //         // Uncomment the lines below to upload the image
//         //         // const response = await uploadImage({ variables: { file } });
//         //         // console.log('Image uploaded successfully:', response.data.uploadImage.url);
//         //     } catch (error) {
//         //         console.error('Error uploading image:', error);
//         //     }
//         // }, 'image/jpeg');
//     };

//     return (
//         <div>
//             <button onClick={startCamera} type="button" className="flex items-center justify-center">
//                 <svg
//                     width={25}
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 512 512"
//                 >
//                     <path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
//                 </svg>
//             </button>
//             {isCameraOn && (
//                 <div>
//                     <video ref={videoRef} autoPlay style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, bottom: 0, zIndex: 20 }} />
//                     <div className="w-full flex items-center justify-center absolute bottom-[10%] z-30">
//                         <button className="bg-[#1D397A] text-white rounded-lg px-3 py-2 mx-auto" onClick={capturePhoto}>Capture Photo</button>
//                     </div>
//                 </div>
//             )}
//             {/* <div className="mt-4">
//                 {imageSrcList.map((src, index) => (
//                     <img key={index} src={src} alt={`Captured ${index}`} className="mx-auto mb-2" />
//                 ))}
//             </div> */}
//             <canvas ref={canvasRef} style={{ display: 'none' }} width="100%" height="100%" />
//         </div>
//     );
// };

// export default CameraButton;


import React, { useEffect, useRef, useState } from 'react';
import { useMutation, gql } from '@apollo/client';

const UPLOAD_IMAGE = gql`
  mutation UploadImage($file: Upload!) {
    uploadImage(file: $file) {
      url
    }
  }
`;

const CameraButton = ({ images = [], alltakenImages }) => {
    const [uploadImage] = useMutation(UPLOAD_IMAGE);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [isCameraOn, setIsCameraOn] = useState(false);

    const startCamera = async (e) => {
        e.preventDefault();
        setIsCameraOn(true);
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        videoRef.current.srcObject = stream;
    };

    const capturePhoto = (e) => {
        e.preventDefault();
        const context = canvasRef.current.getContext('2d');
        const width = 1280;  // Set desired width
        const height = 720;  // Set desired height
        canvasRef.current.width = width;
        canvasRef.current.height = height;
        context.drawImage(videoRef.current, 0, 0, width, height);

        // Specify the quality here (0.5 is an example; you can adjust as needed)
        const dataUrl = canvasRef.current.toDataURL('image/jpeg', 0.5);

        alltakenImages(dataUrl);
        setIsCameraOn(false);
    };

    return (
        <div>
            <button onClick={startCamera} type="button" className="flex items-center justify-center">
                <svg
                    width={25}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                >
                    <path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
                </svg>
            </button>
            {isCameraOn && (
                // <div className=''>
                <>
                    <video ref={videoRef} autoPlay style={{ width: '100%', position: 'absolute', top: 0, left: 0, bottom: 0, zIndex: 20 }} />
                    <div className="flex items-center justify-center absolute bottom-[10%] left-[45%] z-30">
                        <button className="bg-[#1D397A] text-white rounded-lg px-3 py-2 mx-auto" onClick={capturePhoto}>Capture Photo</button>
                    </div>
                </>
                // </div>
            )}
            <canvas ref={canvasRef} style={{ display: 'none' }} />
        </div>
    );
};

export default CameraButton;

