import React, { useState, useEffect, useContext } from 'react';
import { gql, useMutation, useQuery, } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import GraphQuery from '../graphql/queries/Query';
import OtherTruckModel from './OtherTruckModal';
import SuggestedTrucks from './SuggestedTrucks';
import ReportIssueModal from './ReportIssueModal';
import CompletedFreight from './CompletedFreight';
import GenerateInvoice from '../batch_invoice/GenerateInvoice';

const Delivery = ({ filterValue, selectedTruckTab, allTrucks, selectedTruck }) => {
    const { token } = useContext(AuthContext);
    const [trucks, setTrucks] = useState([]);
    const [loadingTruck, setLoadingTruck] = useState([]);
    const [showReportIssueModal, setShowReportIssueModal] = useState(false);
    const [showReportIssueModalId, setShowReportIssueModalId] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState([]);
    const [showOtherTruckModal, setShowOtherTruckModal] = useState(false);
    const [upcomingTrucks, setUpcomingTrucks] = useState([]);
    const [currentPickup, setCurrentPickup] = useState(null);
    // const { responseData: trucksData, loading, error, refetchData: fetchTrucks } = GraphQuery({ name: 'Trucks', single: 'Truck' }, {
    //     // first: 10,
    //     pickupStatus: "delivered",
    //     skip: 0,
    //     search: '',
    //     sortBy: 'number_plate',
    //     order: 'asc',
    // }, `
    //     id,
    //     numberPlate,
    //     make,
    //     model,
    //     color
    //     assignedPickupRequests (pickupStatus: $pickupStatus){
    //         id,
    //         createdAt,
    //         apiMode,
    //         shipmentDate,
    //         pickupDate,
    //         pickupTime,
    //         pickupNumber,
    //         shipmentTerms,
    //         pickupStatus,
    //         customer {
    //             contactPersonFullName,
    //             businessName
    //         }
    //         account {
    //             id,
    //             companyName,
    //         },
    //         pickupRequestAddresses {
    //             id,
    //             companyName,
    //             addressType,
    //             address1,
    //             address2,
    //             city,
    //             state,
    //             zipCode,
    //         },
    //         pickupRequestItems {
    //             id,
    //             description,
    //             quantity,
    //             weight,
    //             pallets,
    //             shipmentClass,
    //             charges
    //         },
    //     }
    // `);

    const UPDATE_PICKUPREQUEST = gql`
        mutation ($id: ID!,$status: String,$truckId: ID) {
            updatePickupRequest (id: $id,status: $status, truckId: $truckId) {
                pickupRequest {
                    id,
                    createdAt,
                    apiMode,
                    shipmentDate,
                    pickupDate,
                    pickupTime,
                    pickupNumber,
                    shipmentTerms,
                    serviceType,
                    pickupStatus,
                    truck {
                        id,
                        numberPlate,
                        model,
                    }
                    customer {
                        contactPersonFullName,
                        businessName
                    }
                    account {
                        id,
                        companyName,
                    },
                    pickupRequestAddresses {
                        id,
                        companyName,
                        addressType,
                        address1,
                        address2,
                        city,
                        state,
                        zipCode,
                    },
                    pickupRequestItems {
                        id,
                        description,
                        quantity,
                        weight,
                        length,
                        width,
                        height,
                        pallets,
                        shipmentClass,
                        charges
                    },
                    pickupRequestInvoices{
                        id,
                        customer {
                            id
                            contactPersonFullName
                        }
                        invoiceDate,
                        invoicePath,
                        status
                    }
                }
            }
        }
    `;

    const [updatePickupRequest, { pickupRequestLoading, pickupRequestError, data: pickupRequestUpdatedData }] = useMutation(UPDATE_PICKUPREQUEST);

    // useEffect(() => {
    //     console.log('shipmentClased', trucksData)
    //     if (trucksData) {
    //         // let filteredTrucks = trucksData.filter((truck) => {
    //         //     return truck.assignedPickupRequests.length > 0 ? truck : false;
    //         // })
    //         setTrucks(trucksData)
    //     }
    // }, [trucksData, setTrucks]);

    useEffect(() => {
        console.log('check in YTruck', selectedTruck)
        if (selectedTruck && selectedTruck.assignedPickupRequests.length > 0) {
            let truckStorageLocation = selectedTruck.zone && selectedTruck?.zone?.zoneLocations ? selectedTruck.zone.zoneLocations[0] : {};
            // setStorageLocation(truckStorageLocation);
            setTrucks([selectedTruck])
            // setTrucks((trucks) => [...trucks, checkinTruck])
        }
    }, [selectedTruck, setTrucks, allTrucks]);

    useEffect(() => {
        console.log('delivery trucks', allTrucks);
        let filteredUpcomingTrucks = allTrucks.filter((truck, index) => {
            return truck.id != selectedTruck.id;
        });
        setUpcomingTrucks(filteredUpcomingTrucks);
    }, [setUpcomingTrucks, allTrucks, selectedTruckTab]);


    useEffect(() => {
        console.log(pickupRequestUpdatedData);
        if (pickupRequestUpdatedData && pickupRequestUpdatedData.updatePickupRequest?.pickupRequest) {
            let updatedPickupRequest = pickupRequestUpdatedData.updatePickupRequest?.pickupRequest;
            console.log(trucks, 'truks');
            let updatedTrucks = trucks.map(truck => {
                // Check if the truck contains the pickup request to be updated
                let updatedAssignedPickupRequests = truck.assignedPickupRequests.map(pickup =>
                    pickup.id === updatedPickupRequest.id ? updatedPickupRequest : pickup
                );

                // Return the updated truck with the modified assignedPickupRequests array
                return {
                    ...truck,
                    assignedPickupRequests: updatedAssignedPickupRequests
                };
            });
            setTrucks(updatedTrucks)
        }
        console.log(trucks)
    }, [pickupRequestUpdatedData]);


    const renderPickupRequestItems = (pickupItems) => {
        if (pickupItems.length > 0) {
            return pickupItems.map((pickupItem, index) => {
                return (
                    <div key={pickupItem.id} className="w-full justify-start">
                        <div className='w-full  my-[8px]'>
                            <span className="font-inter font-medium text-[16px] text-[#374151]">Pickup Item# {index + 1}</span>
                        </div>
                        <div className='flex flex-wrap w-full'>
                            <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Weight:</span>
                            <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-right lg:text-left'>{pickupItem.weight} lbs</span>
                            <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Dimensions:</span>
                            <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-right lg:text-left'>{pickupItem.length + ' X ' + pickupItem.width + ' X ' + pickupItem.height}</span>
                        </div>
                    </div>
                    // <div key={pickupItem.id} className="w-full">
                    //     <div className="w-full flex  items-center">
                    //         <div className="w-[10%]">
                    //             <span className="pr-5 flex items-center">
                    //                 <svg fill="#1D397A" width={12} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" /></svg>
                    //             </span>
                    //         </div>
                    //         <div className="w-[90%]">
                    //             <p className="text-md font-semibold">Pickup Item# {index + 1}</p>
                    //         </div>
                    //     </div>
                    //     <div className="w-full flex">
                    //         <div className="w-[10%]">
                    //             <span className="pr-5 flex items-center">

                    //             </span>
                    //         </div>
                    //         <div className="w-[90%]">
                    //             <div className="w-full flex  items-center">
                    //                 <div className="w-[10%]">
                    //                     <span>
                    //                         <svg fill="#1D397A" width={12} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    //                             <path d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z" /></svg>
                    //                     </span>
                    //                 </div>
                    //                 <div className="w-[70%]">
                    //                     <div className="flex flex-row items-center">
                    //                         <div className='md:w-1/2 flex items-center justify-start'>
                    //                             <p>Weight : {pickupItem.weight}</p>
                    //                         </div>
                    //                     </div>
                    //                 </div>
                    //             </div>
                    //             <div className="w-full flex  items-center">
                    //                 <div className="w-[10%]">
                    //                     <span>
                    //                         <svg fill="#1D397A" width={12} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    //                             <path d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z" /></svg>
                    //                     </span>
                    //                 </div>
                    //                 <div className="w-[70%]">
                    //                     <div className="flex flex-row items-center ">
                    //                         <div className='md:w-1/2 flex items-center justify-start'>
                    //                             <p>Dimensions : {pickupItem.weight}</p>
                    //                         </div>
                    //                     </div>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </div>

                    // </div>
                )
            })
        }

    }

    const renderSuggestedTruck = (truckId, pickupRequest) => {
        console.log('===>Truckls', trucks, truckId);
        let suggestedTruck = trucks.filter((truck) => truck.id === truckId);

        if (pickupRequest.pickupStatus !== 'loaded') {
            return (
                <p className="font-bold">Suggested Truck: {suggestedTruck[0].numberPlate ? suggestedTruck[0].numberPlate : ''}</p>
            )
        }
        return (
            <div>
                <p className="">Confirmed Truck: <span className="font-bold text-green-500">{suggestedTruck[0].numberPlate ? suggestedTruck[0].numberPlate : ''}</span></p>
                <p className="">Pickup Status:  <span className="font-bold text-green-500">loaded</span></p>

            </div>
        );

    }

    const closeOtherTruckModal = () => {
        setShowOtherTruckModal(false)
    }

    const showReportIssueModalHandle = (id) => {
        // e.preventDefault();
        setShowReportIssueModal(true);
        console.log('modal and truck id', id);
        setShowReportIssueModalId(id);
    }

    const showOtherTruckModalHandle = (pickupId) => {
        setShowOtherTruckModal(true)
        console.log('shoeothertou', pickupId);
        setCurrentPickup(pickupId);
        // setLoadingTruck(truckId)
    }

    const confirmTruckHandle = (pickupId, status) => {
        console.log('=============================', pickupId)
        let confirmedTruck = null;
        if (pickupId) {
            let confirmedTruckArray = loadingTruck.filter((truck) => truck.pickupId === pickupId);
            console.log('confirmedTruckArray', confirmedTruckArray, loadingTruck)
            confirmedTruck = confirmedTruckArray[0]['truckId'];
        }
        console.log(confirmedTruck, pickupId, status)

        updatePickupRequest({
            context: {
                headers: {
                    'Authorization': `JWT ${token}`,
                },
            },
            variables: {
                id: pickupId,
                truckId: confirmedTruck,
                status: status,
            }
        });
        if (showOtherTruckModal == true) {
            setShowOtherTruckModal(false);
        }
    }

    const setSuggestedTruckHandle = (truck, pickupId) => {
        if (truck && truck.id) {
            setLoadingTruck((prevLoadingTruck) => [
                ...prevLoadingTruck,
                { pickupId: pickupId, truckId: truck.id }
            ]);
        }
    }

    const renderTruckPickupRequests = (truck) => {
        if (truck.assignedPickupRequests.length > 0) {
            return truck.assignedPickupRequests.map((pickupRequest, index) => {
                // let selectedLoadingTruck = [...loadingTruck]
                // selectedLoadingTruck[pickupRequest.id] = truck.id;
                // setLoadingTruck((loadingTruck) => { return [...loadingTruck, [pickupRequest.id, truck.id],] });
                let sourceAddressData = {};
                let destinationAddressData = {};
                let addresses = pickupRequest?.pickupRequestAddresses;
                addresses.map((address) => {
                    if (address.addressType === 'SHIPPER') {
                        sourceAddressData = address;
                    }
                    else if (address.addressType === 'CONSIGNEE') {
                        destinationAddressData = address;
                    }
                    return true;
                });

                // if (pickupRequest.pickupRequestInvoices.length > 0) {
                //     // pickupHasInvoice = true;
                // }

                return (
                    <div key={pickupRequest.id} className="w-full flex flex-wrap rounded-lg mb-[20px]">
                        <div className="w-full ">
                            <div className='w-full flex flex-wrap rounded-lg'>
                                <div className="w-full sm:w-full md:w-full lg:w-[30%] xl:w-1/6 2xl:w-[25%]">
                                    <div className="w-full">
                                        <h5 className="text-[18px] text-[#374151] font-semibold font-inter capitalize mb-[8px]">BOL #{index + 1}</h5>
                                        {pickupRequest.pickupStatus == 'delivered' ?
                                            <span className="px-3 bg-[#28A7451A] text-[#28A745] rounded-md text-[16px] font-medium inline-flex items-center"><span className="text-[18px] font-medium mr-2">•</span> Delivered</span>
                                            :
                                            <span className="px-3 bg-[#007BFF1A] text-[#007BFF] rounded-md text-[16px] font-medium inline-flex items-center"><span className="text-[18px] font-medium mr-2">•</span> Delivery on route</span>
                                        }
                                    </div>
                                    {pickupRequest.pickupStatus == 'delivered' && <GenerateInvoice pickupRequest={pickupRequest} />}
                                </div>
                                <div className="relative w-full sm:w-full md:w-full lg:w-[45%] xl:w-3/6 2xl:md:w-[50%]">
                                    <div className="w-full">
                                        <div className="flex flex-wrap w-full">
                                            <div className='flex flex-wrap w-full'>
                                                <span className="w-full font-inter font-semibold text-[16px] text-[#374151] mb-[20px]">Delivery #{index + 1}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Pickup Number:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupRequest.pickupNumber}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Source Zip:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{sourceAddressData.zipCode}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Destination Zip:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{destinationAddressData.zipCode}</span>
                                            </div>
                                            {renderPickupRequestItems(pickupRequest?.pickupRequestItems)}
                                            <div className='flex flex-wrap w-full'>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Source Company Name:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{sourceAddressData.companyName}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Destination Company Name:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{destinationAddressData.companyName}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Service Type:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupRequest.serviceType}</span>
                                            </div>
                                        </div>
                                        {/* <div className="w-full flex items-center justify-between md:justify-start mb-1">
                                                <div className="md:w-[80%] lg:w-3/6 xl:w-2/6 mr-2 md:mr-0">
                                                    <div className="flex items-center justify-start">
                                                        <p className="text-[#6B7280]">Source Company Name:</p>
                                                    </div>
                                                </div>
                                                <div className="md:w-[20%] x:w-4/6 text-end md:text-start">
                                                    <p className="text-[#374151] font-medium">{sourceAddressData.companyName}</p>
                                                </div>
                                            </div>
                                            <div className="w-full flex items-center justify-between md:justify-start mb-1">
                                                <div className="md:w-[80%] lg:w-3/6 xl:w-2/6 mr-2 md:mr-0">
                                                    <div className="flex items-center justify-start">
                                                        <p className="text-[#6B7280]">Destination Company Name:</p>
                                                    </div>
                                                </div>
                                                <div className="md:w-[20%] x:w-4/6 text-end md:text-start">
                                                    <p className="text-[#374151] font-medium">{destinationAddressData.companyName}</p>
                                                </div>
                                            </div> */}
                                    </div>
                                </div>
                                <div className="w-full md:w-2/6 lg:w-[25%] xl:w-2/6 2xl:w-[25%] relative md:absolute top-0 right-0 lg:relative">
                                    <div className="flex justify-start md:justify-end">
                                        <button onClick={() => showReportIssueModalHandle(pickupRequest.id)} className="flex items-center px-0 md:p-2 leading-5 text-[#EA580C] text-md font-semibold transition-colors duration-200 transform   hover:bg-[#EA580C1A] focus:outline-none focus:bg-[#EA580C1A] rounded-md">
                                            <span className="mr-2">

                                                <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.1083 2.5H6.89167L2.5 6.89167V13.1083L6.89167 17.5H13.1083L17.5 13.1083V6.89167L13.1083 2.5ZM15.8333 12.4167L12.4167 15.8333H7.58333L4.16667 12.4167V7.58333L7.58333 4.16667H12.4167L15.8333 7.58333V12.4167Z" fill="#EA580C" />
                                                    <path d="M10.0001 14.1667C10.4603 14.1667 10.8334 13.7936 10.8334 13.3333C10.8334 12.8731 10.4603 12.5 10.0001 12.5C9.53984 12.5 9.16675 12.8731 9.16675 13.3333C9.16675 13.7936 9.53984 14.1667 10.0001 14.1667Z" fill="#EA580C" />
                                                    <path d="M9.16675 5.83333H10.8334V11.6667H9.16675V5.83333Z" fill="#EA580C" />
                                                </svg>

                                            </span>
                                            Report an issue
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div key={pickupRequest.id}>
                            {showReportIssueModalId === pickupRequest.id && <ReportIssueModal pickupId={showReportIssueModalId} showModal={showReportIssueModal} closeModal={() => closeReportIssueModal()} />}
                        </div>
                    </div >
                )

            });
        }
    }

    const closeReportIssueModal = () => {
        setShowReportIssueModal(false);
    }

    const checkinHandle = () => {
        console.log('checkin')
    }

    const reportHandle = () => {
        console.log('report')
    }

    const loadingStatusHandle = (index, pickupId) => {
        console.log(loadingStatus);
        let updatedloadingStatus = [...loadingStatus, index + '-' + pickupId]
        setLoadingStatus(updatedloadingStatus);
        // setCurrentPickup(pickupId);
        // setLoadingTruck(truckId)
    }

    const renderTrucksDetails = () => {
        if (trucks.length > 0) {

            // let truck = selectedTruck;
            return trucks.map((truck, index) => {
                return (
                    <div key={truck.id} className="md:w-full">
                        <div className="relative md:w-full flex flex-wrap p-5 border bg-white border-[#D1D5DB] shadow-md rounded-lg h-[100%]">
                            {/* <h5 className="text-xl font-bold mb-5 px-3">Truck {index + 1} : {truck.numberPlate ? truck.numberPlate : ''}</h5> */}
                            <div className="md:w-full flex flex-wrap">
                                {renderTruckPickupRequests(truck)}
                            </div>
                            <div key={truck.id}>
                                {showReportIssueModalId === truck.id && <ReportIssueModal showModal={showReportIssueModal} closeModal={() => closeReportIssueModal()} />}
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return (<p>No truck Data Available...</p>)
    }

    const renderUpComingTrucksDetails = () => {
        if (upcomingTrucks.length > 0) {
            return upcomingTrucks.map((truck, index) => {
                return (
                    <div key={index} className="md:w-full">
                        <div className="md:w-full flex p-5 border bg-white shadow-md rounded-lg h-[100%]">
                            {/* <div className="w-full md:w-1/6">
                                <h5 className="text-[18px] font-bold capitalize mb-4">Truck test {index + 1}: {truck.numberPlate ? truck.numberPlate : ''}</h5>
                                <button onClick={() => checkinHandle(truck)} className="px-6 py-3 mr-4 mt-4 leading-5 text-white transition-colors duration-200 transform bg-[#EA580C] rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Check-in</button>
                            </div> */}
                            <div className="w-full">
                                {renderTruckPickupRequests(truck)}
                            </div>
                            {/* <div className="w-full md:w-2/6">
                                <div className="flex justify-end">
                                    <button onClick={() => showReportIssueModalHandle(truck.id)} className="flex items-center px-3 xl:px-6 py-4 leading-5 text-[#EA580C] text-md font-semibold transition-colors duration-200 transform  hover:bg-[#F9FAFB] focus:outline-none focus:bg-[#F9FAFB]">
                                        <span className="mr-3">

                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.1083 2.5H6.89167L2.5 6.89167V13.1083L6.89167 17.5H13.1083L17.5 13.1083V6.89167L13.1083 2.5ZM15.8333 12.4167L12.4167 15.8333H7.58333L4.16667 12.4167V7.58333L7.58333 4.16667H12.4167L15.8333 7.58333V12.4167Z" fill="#EA580C" />
                                                <path d="M10.0001 14.1667C10.4603 14.1667 10.8334 13.7936 10.8334 13.3333C10.8334 12.8731 10.4603 12.5 10.0001 12.5C9.53984 12.5 9.16675 12.8731 9.16675 13.3333C9.16675 13.7936 9.53984 14.1667 10.0001 14.1667Z" fill="#EA580C" />
                                                <path d="M9.16675 5.83333H10.8334V11.6667H9.16675V5.83333Z" fill="#EA580C" />
                                            </svg>

                                        </span>
                                        Report an issue</button>
                                </div>
                            </div> */}
                        </div>

                    </div>
                )
            })
        }
        return (<p>No truck Data Available...</p>)
    }

    return (
        <div>
            {/* <h4 className="text-2xl my-4 font-bold">Loading <span className="text-[#6B7280] text-[14px] ml-3 font-medium">(Click load starts confirmation flow)</span></h4> */}
            <div className="tab-container">
                <div className="relative z-1">

                    {trucks.length > 0 && trucks[0]?.assignedPickupRequests?.length > 0 && <div className="row pt-3 mb-4 my-2">
                        <h3 className="uppercase text-[#6B7280] mb-3 font-medium font-inter text-[12px]">In Progress</h3>
                        {renderTrucksDetails()}
                        <OtherTruckModel updateSelectedTruck={(truckId) => confirmTruckHandle(currentPickup, 'loaded')} currentPickup={currentPickup} selectedTruck={loadingTruck} showModal={showOtherTruckModal} closeModal={() => closeOtherTruckModal()} />
                    </div>}
                    {/* {upcomingTrucks.length > 0 && <div className="row pt-3 mb-4 my-2">
                        <h3 className="uppercase text-[#6B7280] mb-3 font-medium font-inter text-[12px]">Completed</h3>
                        {renderUpComingTrucksDetails()}
                    </div>} */}
                </div>
            </div>
        </div >
    )
}
export default Delivery;