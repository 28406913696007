// // import { useContext, useState } from 'react';
// // import { AuthContext } from '../../../context/authContext';
// // import { gql, useMutation } from '@apollo/client';

// // const GraphMutation = (modelName = { method: 'create' }, inputType, output = 'id') => {
// //     const { token } = useContext(AuthContext);
// //     const [responseData, setResponseData] = useState(null);
// //     const [errors, setErrors] = useState(null);
// //     const [loading, setLoading] = useState(false);

// //     const DYNAMIC_MUTATION = gql`
// //         mutation ${modelName.method == 'update' ? 'Update' : 'Create' + modelName.name}(${id ? '$id: ID,' : ''}$${inputType.name}New: ${inputType.type}!) {
// //             ${modelName.method + modelName.name}(${inputType.name}Data: $${inputType.name}New) {
// //                 ${modelName.expectedResponseField} {
// //                     ${output}
// //                 }
// //             }
// //         }
// //     `;

// //     const [mutateFunction] = useMutation(DYNAMIC_MUTATION, {
// //         context: {
// //             headers: {
// //                 Authorization: `JWT ${token}`,
// //             },
// //         },
// //         onError: (error) => {
// //             console.log('error.message', error.message);
// //             const parsedError = JSON.parse(error.message);
// //             console.log('parsedError', parsedError);
// //             setErrors(parsedError);
// //         },
// //         onCompleted: (data) => {
// //             console.log(data)
// //             const responseKey = `create${modelName.name}`;
// //             if (data && data[responseKey]) {
// //                 setResponseData(data[responseKey][modelName.expectedResponseField]);
// //             }
// //         },
// //         onMutate: () => {
// //             setLoading(true);
// //         },
// //         onFinally: () => {
// //             setLoading(false);
// //         }
// //     });

// //     const executeMutation = async (variables, id = null) => {
// //         try {
// //             await mutateFunction({
// //                 id: id,
// //                 variables: {
// //                     [`${inputType.name}New`]: variables,
// //                 },
// //             });
// //         } catch (e) {
// //             console.error('Mutation error', e);
// //             setErrors(e);
// //         }
// //     };

// //     // const preprocessErrorMessage = (errorMessage) => {
// //     //     // Split the error message by commas and trim each part
// //     //     const parts = errorMessage.split(',').map(part => part.trim());

// //     //     // Create an object to store transformed errors
// //     //     const transformedErrors = {};

// //     //     // Iterate over each part of the error message
// //     //     parts.forEach(part => {
// //     //         // Extract the key-value pair
// //     //         const [key, value] = part.split(':').map(item => item.trim());

// //     //         // If the key contains 'items', transform it into a nested JSON structure
// //     //         if (key.includes('items')) {
// //     //             const nestedKeys = key.split('[').map(item => item.replace(']', '').trim());
// //     //             let currentObject = transformedErrors;
// //     //             nestedKeys.forEach((nestedKey, index) => {
// //     //                 // If it's the last key, set the error message
// //     //                 if (index === nestedKeys.length - 1) {
// //     //                     currentObject[nestedKey] = value;
// //     //                 } else {
// //     //                     // Otherwise, create nested objects
// //     //                     if (!currentObject[nestedKey]) {
// //     //                         currentObject[nestedKey] = {};
// //     //                     }
// //     //                     currentObject = currentObject[nestedKey];
// //     //                 }
// //     //             });
// //     //         } else {
// //     //             // For other keys, directly assign the value
// //     //             transformedErrors[key] = value;
// //     //         }
// //     //     });

// //     //     return transformedErrors;
// //     // };

// //     return { executeMutation, responseData, loading, errors };
// // };

// // export default GraphMutation;



// import { useContext, useState } from 'react';
// import { AuthContext } from '../../../context/authContext';
// import { gql, useMutation } from '@apollo/client';

// const GraphMutation = (modelName = { method: 'create' }, inputType, output = 'id') => {
//     const { token } = useContext(AuthContext);
//     const [responseData, setResponseData] = useState(null);
//     const [errors, setErrors] = useState(null);
//     const [loading, setLoading] = useState(false);

//     const DYNAMIC_MUTATION = gql`
//         mutation ${modelName.method === 'update' ? 'Update' + modelName.name : 'Create' + modelName.name}(
//             ${modelName.method === 'update' ? '$id: ID, ' : ''}$${inputType.name}New: ${inputType.type}!
//         ) {
//             ${modelName.method === 'update' ? 'update' : 'create'}${modelName.name}(
//                 ${modelName.method === 'update' ? 'id: $id, ' : ''}${inputType.name}Data: $${inputType.name}New
//             ) {
//                 ${modelName.expectedResponseField} {
//                     ${output}
//                 }
//             }
//         }
//     `;

//     const [mutateFunction] = useMutation(DYNAMIC_MUTATION, {
//         context: {
//             headers: {
//                 Authorization: `JWT ${token}`,
//             },
//         },
//         onError: (error) => {
//             try {
//                 const parsedError = JSON.parse(error.message);
//                 console.error('Parsed error:', parsedError);
//                 setErrors(parsedError);
//             } catch (parseError) {
//                 console.error('eRRR', error.message);
//                 setErrors(error.message);
//             }
//         },
//         onCompleted: (data) => {
//             const responseKey = `${modelName.method}${modelName.name}`;
//             console.log('data:', data, 'data[responseKey]', responseKey);
//             if (data && data[responseKey]) {
//                 console.log('setRes', modelName.expectedResponseField)
//                 setResponseData(data[responseKey][modelName.expectedResponseField]);
//             }
//         },
//         onMutate: () => {
//             setLoading(true);
//         },
//         onFinally: () => {
//             setLoading(false);
//         }
//     });

//     const executeMutation = async (variables, id = null) => {
//         try {
//             const mutationVariables = {
//                 [`${inputType.name}New`]: variables,
//             };
//             if (id) {
//                 mutationVariables.id = id;
//             }
//             await mutateFunction({
//                 variables: mutationVariables,
//             });
//         } catch (e) {
//             console.error('Mutation error 2:', e);
//             setErrors(e);
//         }
//     };

//     return { executeMutation, responseData, loading, errors };
// };

// export default GraphMutation;



import { useContext, useState } from 'react';
import { AuthContext } from '../../../context/authContext';
import { gql, useMutation } from '@apollo/client';

const GraphMutation = (modelName = { method: 'create' }, inputType, output = 'id') => {
    const { token } = useContext(AuthContext);
    const [responseData, setResponseData] = useState(null);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);

    const DYNAMIC_MUTATION = gql`
        mutation ${modelName.method === 'update' ? 'Update' + modelName.name : 'Create' + modelName.name}(
            ${modelName.method === 'update' ? '$id: ID, ' : ''}$${inputType.name}New: ${inputType.type}!
        ) {
            ${modelName.method === 'update' ? 'update' : 'create'}${modelName.name}(
                ${modelName.method === 'update' ? 'id: $id, ' : ''}${inputType.name}Data: $${inputType.name}New
            ) {
                ${modelName.expectedResponseField} {
                    ${output}
                }
            }
        }
    `;

    const [mutateFunction] = useMutation(DYNAMIC_MUTATION, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        onError: (error) => {
            let parsedError;
            try {
                parsedError = JSON.parse(error.message);
                console.error('Parsed error:', parsedError);
            } catch (parseError) {
                console.error('Error message:', error.message);
                parsedError = { message: error.message };
            }
            setErrors(parsedError);
        },
        onCompleted: (data) => {
            const responseKey = `${modelName.method}${modelName.name}`;
            if (data && data[responseKey]) {
                setResponseData(data[responseKey][modelName.expectedResponseField]);
            }
            setErrors(null); // Reset errors on successful completion
        },
        onMutate: () => {
            setLoading(true);
        },
        onFinally: () => {
            setLoading(false);
        }
    });

    const executeMutation = async (variables, id = null) => {
        setErrors(null); // Clear errors before sending the request
        try {
            const mutationVariables = {
                [`${inputType.name}New`]: variables,
            };
            if (id) {
                mutationVariables.id = id;
            }
            await mutateFunction({
                variables: mutationVariables,
            });
        } catch (e) {
            console.error('Mutation error:', e);
            setErrors(e);
        }
    };

    return { executeMutation, responseData, loading, errors };
};

export default GraphMutation;
