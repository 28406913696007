import Header from './layouts/PublicSite/Header';
import Footer from './layouts/PublicSite/Footer';

import fleetImage from '../assets/site/fleet_image.png';
import truckImage from '../assets/site/truck_image.png';
import complianceImage from '../assets/site/compliance-image.png';
import automateImage from '../assets/site/automate_image.png';
import flexibleSecureImage from '../assets/site/flexible_secure_image.png';
import numberBullet1 from '../assets/site/1.png';
import numberBullet2 from '../assets/site/2.png';
import numberBullet3 from '../assets/site/3.png';
import numberBullet4 from '../assets/site/4.png';
import numberBullet5 from '../assets/site/5.png';
import numberBullet6 from '../assets/site/6.png';
import numberBullet7 from '../assets/site/7.png';

function HomePage() {

    const heroImage = '/hero_image.png';
    const gridImage = '/grid_img.png';

    return (
        <>
            <div className="hero bg-cover bg-center bg-no-repeat bg-color-primary text-white" style={{ backgroundImage: `url(${heroImage})` }}>
                <Header />
                
                <div className="sm:p-8 md:p-16 xl:p-32 2xl:p-52">
                    <div className="sm:p-8 md:px-16 xl:p-32 px-6 py-14 hero-container flex h-[70vh] text-black ">
                        <div className="w-full">
                            <h3 className="md:text-xl 2xl:text-3xl text-xl text-black font-semibold">Welcome to <span
                                className="uppercase">bolerm</span></h3>
                            <h1
                                className="md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl text-3xl py-4 capitalize text-black font-bold">
                                Smarter Transport
                                Management
                            </h1>
                            <p className="2xl:text-2xl">
                                Step into the future of transport management with BOLERM, your SaaS <br /> partner for streamlined
                                logistics operations.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center bg-gradient-to-b from-30% from-transparent to-black to-100% h-[20vh]">
                    <div>
                        <a href="/">
                            <div><span>Learn More</span></div>
                            <div className="flex items-center justify-center pt-3">
                                <i className="animate-bounce fa-solid fa-chevron-down text-white"></i>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div className=" wrapper  xl:max-w-[2000px] 2xl:max-w-[2500px] mx-auto">
                <section className="lg bg-left-top bg-no-repeat sm:px-8 md:px-16 xl:px-32 2xl:px-52" style={{ backgroundImage: `url(${gridImage})` }} >
                    <div className="">
                        <div className="sm:px-8 md:px-16 xl:px-32 px-6 py-14 hero-container text-black ">
                            <h3 className="md:text-3xl xl:text-5xl text-2xl text-white font-bold">Streamlined Simplicity</h3>
                            <p className="text-white text-justify pt-4 pb-6">
                                From fleet management to route optimization, BOLERM revolutionizes logistics with its sleek,
                                intuitive,
                                and efficient solution.
                            </p>
                            <div className="flex items-center justify-center md:justify-start">
                                <div
                                    className="p-[2px] rounded bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block">
                                    <button
                                        className="px-14 xl:px-24 rounded py-2 text-white bg-black text-sm font-[600] hover:bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block duration-500">Get
                                        updates</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className=" sm:px-8 md:px-16 xl:px-32 2xl:px-52 relative">
                    <div>
                        <svg className="hidden lg:block absolute right-2  bottom-[-20rem] z-20" xmlns="http://www.w3.org/2000/svg"
                            width="314" height="602" viewBox="0 0 314 602" fill="none">
                            <path opacity="0.2"
                                d="M72.797 602L72.7969 0M148.759 602L148.759 0M224.721 602L224.721 0M300.683 602L300.683 0M376.646 602L376.646 0M452.608 602L452.608 0M528.57 602L528.57 0M1.99219e-05 72.7971H602M1.66016e-05 148.759H602M1.32812e-05 224.721H602M9.96093e-06 300.684H602M6.64062e-06 376.646H602M3.32031e-06 452.608H602M0 528.57H602"
                                stroke="url(#paint0_radial_23_74)" strokeWidth="2" />
                            <defs>
                                <radialGradient id="paint0_radial_23_74" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(301 301) rotate(90) scale(301)">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className="my-14 sm:px-0 px-6">
                        <div className=" flex-row items-center justify-center py-9 relative ">
                            <div
                                className="sm:px-8 md:px-16 xl:px-32 flex flex-col-reverse md:flex md:flex-row items-center justify-center w-full relative z-20">
                                <div
                                    className=" md:rounded-[36px] md:bg-[#181717c2] md:border-[#56545484] md:border-2  hero-container md:w-full md:h-[233px] lg:h-[16rem] xl:h-[293px] 2xl:h-[453px] z-10 absolute">
                                </div>
                                <div className="py-5 md:w-[50%] relative z-30">
                                    <h3 className="md:text-3xl xl:text-5xl text-2xl text-white font-bold">Fleet at Your Fingertips
                                    </h3>
                                    <p className="text-white text-justify pt-4 pb-6 md:pr-12">
                                        Scale continents or zip around the corner, control your entire fleet in real-time with
                                        our
                                        sophisticated tracking and
                                        reporting.
                                    </p>
                                    <div className="flex items-center justify-center md:justify-start">
                                        <div
                                            className="p-[2px] rounded bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block">
                                            <button
                                                className="px-14 xl:px-24 rounded py-2 text-white bg-black text-sm font-[600] hover:bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block duration-500">Get
                                                updates</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="md:w-[50%] w-full relative z-30">
                                    <img
                                        className="sm:w-full inline hover:scale-105 duration-500 hover:rotate-12"
                                        src={fleetImage} alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="sm:px-8 md:px-16 xl:px-32 2xl:px-52 relative">
                    <div>
                        <svg className="hidden lg:block absolute left-3  bottom-[-30rem] z-20" xmlns="http://www.w3.org/2000/svg"
                            width="281" height="602" viewBox="0 0 281 602" fill="none">
                            <path opacity="0.2"
                                d="M208.203 -2.69873e-05L208.203 602M132.241 -3.36281e-05L132.241 602M56.2788 -4.0269e-05L56.2787 602M-19.6834 -4.69098e-05L-19.6834 602M-95.6456 -5.35506e-05L-95.6456 602M-171.608 -6.01914e-05L-171.608 602M-247.57 -6.68323e-05L-247.57 602M281 529.203L-321 529.203M281 453.241L-321 453.241M281 377.279L-321 377.279M281 301.316L-321 301.316M281 225.354L-321 225.354M281 149.392L-321 149.392M281 73.4299L-321 73.4298"
                                stroke="url(#paint0_radial_23_28)" strokeWidth="2" />
                            <defs>
                                <radialGradient id="paint0_radial_23_28" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(-20 301) rotate(-90) scale(301)">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className="my-14 sm:px-0 px-6">
                        <div className="flex-column items-center justify-center py-9 relative">
                            <div
                                className="md:rounded-[36px] md:bg-[#181717c2] md:border-[#56545484] md:border-2  hero-container md:h-[436px] md:w-full xl:h-[30rem] 2xl:h-[623px] z-10 absolute top-1/3">
                            </div>
                            <div className="sm:px-8 md:px-16 xl:px-32 w-full relative z-20">
                                <div className="w-full relative z-30">
                                    <img className="w-full hover:scale-105 duration-500"
                                        src={automateImage} alt="" />
                                </div>
                                <div className="block py-5 md:pt-6">
                                    <h3 className="md:text-3xl xl:text-5xl text-2xl text-white font-bold">Automate. Dominate.</h3>
                                    <p className="text-white text-justify pt-4 pb-6 md:pr-12">
                                        Scale continents or zip around the corner, control your entire fleet in real-time with
                                        our
                                        sophisticated tracking and
                                        reporting.
                                    </p>
                                    <div className="flex items-center justify-center md:justify-start">
                                        <div
                                            className="p-[2px] rounded bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block">
                                            <button
                                                className="px-14 xl:px-24 rounded py-2 text-white bg-black text-sm font-[600] hover:bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block duration-500">Get
                                                updates</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <section className="sm:px-8 md:px-16 xl:px-32 2xl:px-52">
                    <div className="my-14 sm:px-0 px-6">
                        <div className="flex-row items-center justify-center py-9 relative">
                            <div
                                className="sm:px-8 md:px-16 xl:px-32 flex flex-col-reverse md:flex md:flex-row-reverse items-center justify-center w-full relative z-20">
                                <div
                                    className="sm:px-8 md:rounded-[36px] md:bg-[#181717c2] md:border-[#56545484] md:border-2  hero-container md:w-full md:h-[293px] h-[373px] xl:h-[453px] z-10 absolute">
                                </div>

                                <div className="py-5 md:w-[50%] relative z-30 md:pl-12 lg:pl-32">
                                    <h3 className="md:text-3xl xl:text-5xl text-2xl text-white font-bold">Compliance? Consider It
                                        Done.
                                    </h3>
                                    <p className="text-white text-justify pt-4 pb-6">
                                        Navigate the labyrinth of regulations with BOLERM's automatic updates and documentation
                                        management, ensuring complete
                                        regulatory compliance.
                                    </p>
                                    <div className="flex items-center justify-center md:justify-start">
                                        <div
                                            className="p-[2px] rounded bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block">
                                            <button
                                                className="px-14 xl:px-24 rounded py-2 text-white bg-black text-sm font-[600] hover:bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block duration-500">Get
                                                updates</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="md:w-[50%] w-full relative z-30">
                                    <img className="sm:w-full inline hover:scale-105 duration-500 hover:rotate-[-12deg]"
                                        src={complianceImage} alt="" />
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <section className="sm:px-8 md:px-16 xl:px-32 2xl:px-52 relative">
                    <div>
                        <svg className="hidden lg:block absolute left-3  top-[-25rem] z-20" xmlns="http://www.w3.org/2000/svg"
                            width="281" height="602" viewBox="0 0 281 602" fill="none">
                            <path opacity="0.2"
                                d="M208.203 -2.69873e-05L208.203 602M132.241 -3.36281e-05L132.241 602M56.2788 -4.0269e-05L56.2787 602M-19.6834 -4.69098e-05L-19.6834 602M-95.6456 -5.35506e-05L-95.6456 602M-171.608 -6.01914e-05L-171.608 602M-247.57 -6.68323e-05L-247.57 602M281 529.203L-321 529.203M281 453.241L-321 453.241M281 377.279L-321 377.279M281 301.316L-321 301.316M281 225.354L-321 225.354M281 149.392L-321 149.392M281 73.4299L-321 73.4298"
                                stroke="url(#paint0_radial_23_28)" strokeWidth="2" />
                            <defs>
                                <radialGradient id="paint0_radial_23_28" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(-20 301) rotate(-90) scale(301)">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                </radialGradient>
                            </defs>
                        </svg>

                        <svg className="hidden lg:block absolute right-3  top-[-25rem] z-20" xmlns="http://www.w3.org/2000/svg"
                            width="359" height="602" viewBox="0 0 359 602" fill="none">
                            <path opacity="0.2"
                                d="M529.203 -2.69873e-05L529.203 602M453.241 -3.36281e-05L453.241 602M377.279 -4.0269e-05L377.279 602M301.317 -4.69098e-05L301.317 602M225.354 -5.35506e-05L225.354 602M149.392 -6.01914e-05L149.392 602M73.43 -6.68323e-05L73.43 602M602 529.203L3.78859e-06 529.203M602 453.241L1.04294e-05 453.241M602 377.279L1.70702e-05 377.279M602 301.316L2.37111e-05 301.316M602 225.354L3.03519e-05 225.354M602 149.392L7.56291e-05 149.392M602 73.4299L8.22699e-05 73.4298"
                                stroke="url(#paint0_radial_42_22269)" strokeWidth="2" />
                            <defs>
                                <radialGradient id="paint0_radial_42_22269" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(301 301) rotate(-90) scale(301)">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className="my-14 sm:px-0 px-6">
                        <div className="flex-column items-center justify-center py-9 relative">
                            <div
                                className="md:rounded-[36px] md:bg-[#181717c2] md:border-[#56545484] md:border-2  hero-container md:h-[436px] md:w-full xl:h-[636px] 2xl:h-[613px] z-10 absolute bottom-1/3">
                            </div>
                            <div className="sm:px-8 md:px-16 xl:px-32 flex flex-col-reverse md:flex-col relative z-20">
                                <div className="block py-5 md:pb-6">
                                    <h3 className="md:text-3xl xl:text-5xl text-2xl text-white font-bold">Flexible, Secure, Reliable
                                    </h3>
                                    <p className="text-white text-justify pt-4 pb-6 ">
                                        Our platform scales with your needs, protects your data with ironclad security, and
                                        offers
                                        reliability that never quits..
                                    </p>
                                    <div className="flex items-center justify-center md:justify-start">
                                        <div
                                            className="p-[2px] rounded bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block">
                                            <button
                                                className="px-14 xl:px-24 rounded py-2 text-white bg-black text-sm font-[600] hover:bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block duration-500">Get
                                                updates</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full relative z-30">
                                    <img className="sm:w-full inline hover:scale-105 duration-500"
                                        src={flexibleSecureImage} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="sm:px-8 md:px-16 xl:px-32 2xl:px-52">
                    <div className="my-14 sm:px-0 px-6">
                        <div className="flex-column items-center justify-center py-9 relative">
                            <div
                                className="sm:px-8 md:p-16 xl:p-32 md:rounded-[36px] md:bg-[#181717c2] md:border-[#56545484] md:border-2 md:flex md:flex-row-reverse justify-between  hero-container  md:w-full z-10">
                                <div className="xl:h-full flex justify-end">
                                    <img className="sm:w-full inline hover:scale-105 duration-500"
                                        src={truckImage} alt="" />
                                </div>
                                <div className="md:w-[60%]">
                                    <h3 className="capitalize md:text-3xl py-5 xl:text-5xl text-2xl text-white font-bold">Semi Specs
                                    </h3>
                                    <p className=" ">Fully Loaded at 82k lbs Gross Combination Weight</p>
                                    <div className="pt-5 row flex sm:flex-row sm:gap-5 flex-col items-start justify-between">
                                        <div className="md:w-[50%]">
                                            <div className="row flex items-center justify-start">
                                                <div className=" w-10 flex items-center justify-center mr-6 md:mr-3 xl:mr-6">
                                                    <img src={numberBullet1} alt="" />
                                                </div>
                                                <div className="">
                                                    <h5 className="md:text-sm xl:text-2xl font-bold">AI-Optimized Route Planning
                                                    </h5>
                                                    <p className="md:text-xs xl:text-base">Efficient paths considering traffic
                                                        distance,
                                                        and weather.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="mt-7 md:mt-4 xl:mt-14 row flex items-center justify-start">
                                                <div className=" w-10 flex items-center justify-center mr-6 md:mr-3 xl:mr-6">
                                                    <img src={numberBullet2} alt="" />
                                                </div>
                                                <div className="">
                                                    <h5 className="md:text-sm xl:text-2xl font-bold">Automated Dispatch & Scheduling
                                                    </h5>
                                                    <p className="md:text-xs xl:text-base">Reduces human error boosting efficiency.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="mt-7 md:mt-4 xl:mt-14 row flex items-center justify-start">
                                                <div className=" w-10 flex items-center justify-center mr-6 md:mr-3 xl:mr-6">
                                                    <img src={numberBullet3} alt="" />
                                                </div>
                                                <div className="">
                                                    <h5 className="md:text-sm xl:text-2xl font-bold">Real-Time Tracking & Reporting
                                                    </h5>
                                                    <p className="md:text-xs xl:text-base">Live fleet updates and detailed
                                                        performance
                                                        analytics.</p>
                                                </div>
                                            </div>
                                            <div className="mt-7 md:mt-4 xl:mt-14 mb-7 row flex items-center justify-start">
                                                <div className=" w-10 flex items-center justify-center mr-6 md:mr-3 xl:mr-6">
                                                    <img src={numberBullet4} alt="" />
                                                </div>
                                                <div className="">
                                                    <h5 className="md:text-sm xl:text-2xl font-bold">Regulatory Compliance
                                                        Management
                                                    </h5>
                                                    <p className="md:text-xs xl:text-base">Keeps operations compliant and paperwork
                                                        updated.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="md:w-[50%]">
                                            <div className="row flex items-center justify-start">
                                                <div className=" w-10 flex items-center justify-center mr-6 md:mr-3 xl:mr-6">
                                                    <img src={numberBullet5} alt="" />
                                                </div>
                                                <div className="">
                                                    <h5 className="md:text-sm xl:text-2xl font-bold">Fleet Management</h5>
                                                    <p className="md:text-xs xl:text-base">Maximizes fleet utilization and reduces
                                                        idle
                                                        time.</p>
                                                </div>
                                            </div>
                                            <div className="mt-7 md:mt-4 xl:mt-14 row flex items-center justify-start">
                                                <div className=" w-10 flex items-center justify-center mr-6 md:mr-3 xl:mr-6">
                                                    <img src={numberBullet6} alt="" />
                                                </div>
                                                <div className="">
                                                    <h5 className="md:text-sm xl:text-2xl font-bold">Scalability & Flexibility</h5>
                                                    <p className="md:text-xs xl:text-base">Adapts to business needs and scales as
                                                        you
                                                        grow.</p>
                                                </div>
                                            </div>
                                            <div className="mt-7 md:mt-4 xl:mt-14 row flex items-center justify-start">
                                                <div className=" w-10 flex items-center justify-center mr-6 md:mr-3 xl:mr-6">
                                                    <img src={numberBullet7} alt="" />
                                                </div>
                                                <div className="">
                                                    <h5 className="md:text-sm xl:text-2xl font-bold">High Security</h5>
                                                    <p className="md:text-xs xl:text-base">Safeguards sensitive data with robust
                                                        protection measures.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-[100%] mt-16">
                                        <div className="flex items-center justify-center md:justify-start">
                                            <div
                                                className="p-[2px] rounded bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block">
                                                <button
                                                    className="px-14 xl:px-24 rounded py-2 text-white bg-black text-sm font-[600]  hover:bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block duration-500">Get
                                                    updates</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="sm:px-8 md:px-16 xl:px-32 2xl:px-52">
                    <div className="my-14 sm:px-0 px-6">
                        <div className="flex-column items-center justify-center py-9 relative">
                            <div className="flex flex-col-reverse relative z-20">
                                <div
                                    className="sm:border-2 sm:border-[#56545484] sm:bg-[#56545484] sm:rounded-3xl sm:p-8 xl:p-32 block md:pb-[74px] overflow-hidden relative">
                                    <div className="absolute top-0 bottom-0 right-0 w-full">
                                        <svg className="hidden lg:block absolute left-0  top-50 z-20"
                                            xmlns="http://www.w3.org/2000/svg" width="281" height="602" viewBox="0 0 281 602"
                                            fill="none">
                                            <path opacity="0.2"
                                                d="M208.203 -2.69873e-05L208.203 602M132.241 -3.36281e-05L132.241 602M56.2788 -4.0269e-05L56.2787 602M-19.6834 -4.69098e-05L-19.6834 602M-95.6456 -5.35506e-05L-95.6456 602M-171.608 -6.01914e-05L-171.608 602M-247.57 -6.68323e-05L-247.57 602M281 529.203L-321 529.203M281 453.241L-321 453.241M281 377.279L-321 377.279M281 301.316L-321 301.316M281 225.354L-321 225.354M281 149.392L-321 149.392M281 73.4299L-321 73.4298"
                                                stroke="url(#paint0_radial_23_28)" strokeWidth="2" />
                                            <defs>
                                                <radialGradient id="paint0_radial_23_28" cx="0" cy="0" r="1"
                                                    gradientUnits="userSpaceOnUse"
                                                    gradientTransform="translate(-20 301) rotate(-90) scale(301)">
                                                    <stop stopColor="white" />
                                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                                </radialGradient>
                                            </defs>
                                        </svg>

                                        <svg className="hidden lg:block absolute right-0  top-50 z-20"
                                            xmlns="http://www.w3.org/2000/svg" width="359" height="602" viewBox="0 0 359 602"
                                            fill="none">
                                            <path opacity="0.2"
                                                d="M529.203 -2.69873e-05L529.203 602M453.241 -3.36281e-05L453.241 602M377.279 -4.0269e-05L377.279 602M301.317 -4.69098e-05L301.317 602M225.354 -5.35506e-05L225.354 602M149.392 -6.01914e-05L149.392 602M73.43 -6.68323e-05L73.43 602M602 529.203L3.78859e-06 529.203M602 453.241L1.04294e-05 453.241M602 377.279L1.70702e-05 377.279M602 301.316L2.37111e-05 301.316M602 225.354L3.03519e-05 225.354M602 149.392L7.56291e-05 149.392M602 73.4299L8.22699e-05 73.4298"
                                                stroke="url(#paint0_radial_42_22269)" strokeWidth="2" />
                                            <defs>
                                                <radialGradient id="paint0_radial_42_22269" cx="0" cy="0" r="1"
                                                    gradientUnits="userSpaceOnUse"
                                                    gradientTransform="translate(301 301) rotate(-90) scale(301)">
                                                    <stop stopColor="white" />
                                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                                </radialGradient>
                                            </defs>
                                        </svg>
                                    </div>
                                    <div>
                                        <h3 className="sm:text-center md:text-4xl text-2xl text-white font-bold">Get Started</h3>
                                        <p className="sm:text-center text-white text-justify pt-4 pb-6 md:pr-12">
                                            Enter the field below to continue
                                        </p>
                                    </div>
                                    <div className="flex flex-col items-start lg:items-center justify-center">
                                        <form className="w-full lg:w-1/2 z-40" action="">
                                            <div>
                                                <label htmlFor="">Email</label>
                                                <input className="bg-[#56545484] p-1 rounded-md my-2 block w-full" type="text"
                                                    placeholder="example@email.com" />
                                            </div>
                                            <div className="mt-8 mb-6">
                                                <label htmlFor="">Phone</label>
                                                <input className="bg-[#56545484] p-1 rounded-md my-2 block w-full" type="text"
                                                    placeholder="(000) 123-1234" />
                                            </div>
                                        </form>
                                        <div className="flex flex-row w-full items-center justify-center md:justify-center z-40">
                                            <div
                                                className="p-[2px] rounded-full bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block">
                                                <button
                                                    className="px-14 xl:px-24 rounded-full py-2 text-white bg-black text-sm font-[600]  hover:bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block duration-500">Continue</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
        </>
    );
}

export default HomePage;