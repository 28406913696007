import React, { useState, useEffect, useContext } from 'react';

import SortableTable from "../../components/SortableTable";
import PageHeading from "../../components/PageHeading";

import { Link } from 'react-router-dom'
import Pagination from "../../components/Pagination";

import searchIcon from '../../assets/search-icon.svg';
import { gql, useMutation, useQuery } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
// import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import Loader from '../../components/Loader';
import FreightCheckIn from '../../components/fleet_control_components/FreightCheckIn';
import Unload from '../../components/fleet_control_components/Unload';
import Loading from '../../components/fleet_control_components/Loading';
import ReportIsssueModal from '../../components/fleet_control_components/ReportIssueModal';
import GraphQuery from '../../components/graphql/queries/Query';
import CameraButton from '../../components/fleet_control_components/CameraButton';
import Delivery from '../../components/fleet_control_components/Delivery';
import { useLocation } from 'react-router-dom';

function FleetControlPage() {

    const urlParams = new URLSearchParams(window.location.search);

    const { token } = useContext(AuthContext);

    const location = useLocation();

    const [search, setSearch] = useState("");
    const [showReportIssueModal, setShowReportIssueModal] = useState(false);
    const [checkinTruck, setCheckinTruck] = useState(null);
    const [loadTruck, setLoadTruck] = useState(null);
    const [unloadedTrucks, setUnloadedTrucks] = useState(null);
    const [deliveredTrucks, setDeliveredTrucks] = useState([]);
    const [selectedDeliveryTruck, setSelectedDeliveryTruck] = useState(null);
    const [selectedTruckTab, setSelectedTruckTab] = useState(0);
    const [selectedLoadTruckTab, setSelectedLoadTruckTab] = useState(0);
    const [selectedDeliveryTruckTab, setSelectedDeliveryTruckTab] = useState(0);
    const [statusFilterOption, setStatusFilterOptions] = useState([
        { id: 1, slug: 'show-all', title: 'Show All' },
        { id: 2, slug: 'driver-assigned', title: 'Driver Assigned' },
        { id: 3, slug: 'unloaded', title: 'Unloaded' },
        { id: 4, slug: 'loaded', title: 'Loaded' },
        { id: 5, slug: 'delivered', title: 'Delivered' },
    ]);
    const [statusFilterValue, setStatusFilterValue] = useState('show-all');
    const pageValue = urlParams.get('page');
    const [page, setPage] = useState(pageValue ? parseInt(pageValue) : 1);

    const recordsPerPage = 10;
    const [offset, setOffset] = useState((page - 1) * recordsPerPage);
    const [count, setCount] = useState(0);
    const [selectFreightTab, setSelectedFreightTab] = useState('freight-checkin');
    const [checkinTrucks, setCheckinTrucks] = useState([]);

    const { responseData: trucksData, loading, error, refetchData } = GraphQuery({ name: 'Trucks', single: 'Truck' }, {
        pickupStatus: ['driver-assigned', 'driver-accepted', 'picked'],
        latest: 1,
        skip: 0,
        search: '',
        sortBy: 'number_plate',
        order: 'asc',
    }, `
        id,
        numberPlate,
        make,
        model,
        color,
        zone{
            id,
            title,
            zoneLocations {
                id,
                title
            }
        }
        assignedPickupRequests(pickupStatus: $pickupStatus){
            id,
            createdAt,
            apiMode,
            shipmentDate,
            pickupDate,
            pickupTime,
            pickupNumber,
            shipmentTerms,
            serviceType,
            pickupStatus,
            storageLocation{
                id
                title
            }
            customer {
                contactPersonFullName,
                businessName
            }
            account {
                id,
                companyName,
            },
            pickupRequestAddresses {
                id,
                companyName,
                addressType,
                address1,
                address2,
                city,
                state,
                zipCode,
            },
            pickupRequestItems {
                id,
                description,
                quantity,
                weight,
                width,
                height,
                length,
                pallets,
                shipmentClass,
                charges
            },
            pickupRequestImages(pickupStatus: $pickupStatus) {
                id,
                image,
                event,
                pickupRequestStatus
            }
            pickupRequestTracking(latest: $latest) {
                id,
                latitude,
                longitude,
            }
            pickupRequestIssues(pickupStatus: $pickupStatus) {
                id,
                pickupStatus,
                remarks,
                pickupRequestIssueImages {
                    id,
                    image,
                }
            }
        }
    `);

    const { responseData: unloadedTrucksData, unloadedTrucksloading, unloadedTrucksError, refetchData: refetchLoadingData } = GraphQuery({ name: 'Trucks', single: 'Truck' }, {
        pickupStatus: ["unloaded"],
        skip: 0,
        search: '',
        sortBy: 'number_plate',
        order: 'asc',
    }, `
        id,
        numberPlate,
        make,
        model,
        color,
        zone{
            id,
            title,
            zoneLocations {
                id,
                title
            }
        }
        assignedPickupRequests(pickupStatus: $pickupStatus){
            id,
            createdAt,
            apiMode,
            shipmentDate,
            pickupDate,
            pickupTime,
            pickupNumber,
            shipmentTerms,
            serviceType,
            pickupStatus,
            truck {
                id,
                numberPlate,
                make,
                model,
                color
            }
            customer {
                contactPersonFullName,
                businessName
            }
            account {
                id,
                companyName,
            },
            pickupRequestAddresses {
                id,
                companyName,
                addressType,
                address1,
                address2,
                city,
                state,
                zipCode,
            },
            pickupRequestItems {
                id,
                description,
                quantity,
                weight,
                pallets,
                width,
                height,
                length,
                shipmentClass,
                charges
            },
        }
    `);

    const { responseData: deliveredTrucksData, deliveredTrucksloading, deliveredTrucksError, refetchData: refetchDeliveryData } = GraphQuery({ name: 'Trucks', single: 'Truck' }, {
        pickupStatus: ["loaded"],
        skip: 0,
        search: '',
        sortBy: 'number_plate',
        order: 'asc',
    }, `
        id,
        numberPlate,
        make,
        model,
        color,
        zone{
            id,
            title,
            zoneLocations {
                id,
                title
            }
        }
        assignedPickupRequests(pickupStatus: $pickupStatus){
            id,
            createdAt,
            apiMode,
            shipmentDate,
            pickupDate,
            pickupTime,
            pickupNumber,
            shipmentTerms,
            serviceType,
            pickupStatus,
            customer {
                contactPersonFullName,
                businessName
            }
            account {
                id,
                companyName,
            },
            pickupRequestImages(pickupStatus: $pickupStatus) {
                id,
                image,
                event,
                pickupRequestStatus
            },
            pickupRequestAddresses {
                id,
                companyName,
                addressType,
                address1,
                address2,
                city,
                state,
                zipCode,
            },
            pickupRequestItems {
                id,
                description,
                quantity,
                weight,
                width,
                height,
                length,
                pallets,
                shipmentClass,
                charges
            },
            pickupRequestIssues(pickupStatus: $pickupStatus) {
                id,
                pickupStatus,
                remarks,
                pickupRequestIssueImages {
                    id,
                    image,
                }
            },
            pickupRequestInvoices{
                id,
                customer {
                    id
                    contactPersonFullName
                }
                invoiceDate,
                invoicePath,
                status
            },
            deliverySignature {
                id
                signatureBy
                signatureImage
                remarks
            }
            

        }
    `);

    useEffect(() => {
        if (location.pathname === '/admin/fleet-control') {
            refetchData();
        }
    }, [location, refetchData]);

    useEffect(() => {
        if (selectFreightTab == 'freight-checkin') {
            setStatusFilterOptions([
                { id: 1, slug: 'show-all', title: 'Show All' },
                { id: 2, slug: 'driver-assigned', title: 'Driver Assigned' },
                { id: 3, slug: 'driver-accepted', title: 'Driver Accepted' },
                { id: 4, slug: 'on-the-move', title: 'Driver on Move' },
                { id: 5, slug: 'picked', title: 'Picked' },
                { id: 6, slug: 'unloaded', title: 'Unloaded' },
            ])
        }
        if (selectFreightTab == 'unload') {
            setStatusFilterOptions([
                { id: 1, slug: 'picked', title: 'Picked' },
                { id: 2, slug: 'unloaded', title: 'Unloaded' },
            ])
        }
        if (selectFreightTab == 'load') {
            setStatusFilterOptions([
                { id: 1, slug: 'unloaded', title: 'Unloaded' },
                { id: 2, slug: 'loaded', title: 'Loaded' },
            ])
        }
        if (selectFreightTab == 'delivery') {
            setStatusFilterOptions([
                { id: 1, slug: 'loaded', title: 'Loaded' },
                { id: 2, slug: 'driver-accepted-delivery', title: 'Delivery Accepted' },
                { id: 3, slug: 'delivery-on-the-move', title: 'Delivery on Route' },
                { id: 4, slug: 'delivered', title: 'Delivered' },
            ])
        }
    }, [selectFreightTab]);

    useEffect(() => {
        if (selectFreightTab == 'freight-checkin' || selectFreightTab == 'unload') {
            setSelectedTruckTab(0);
            refetchData({
                pickupStatus: statusFilterValue === 'show-all' ? ['driver-assigned', 'driver-accepted', 'on-the-move', 'picked'] : [statusFilterValue],
            });
        }
        if (selectFreightTab == 'load') {
            setSelectedLoadTruckTab(0)
            refetchLoadingData({
                pickupStatus: [statusFilterValue],
            })
        }
        if (selectFreightTab == 'delivery') {
            refetchDeliveryData({
                pickupStatus: [statusFilterValue],
            })
        }

    }, [statusFilterValue]);

    useEffect(() => {
        setPage(pageValue ? parseInt(pageValue) : 1);
    }, [pageValue]);


    useEffect(() => {
        console.log('shipmentClased fleet page with trucksData', trucksData)
        if (trucksData) {
            setCheckinTrucks(trucksData);
            setCheckinTruck(trucksData[selectedTruckTab]);
        }
    }, [trucksData, setCheckinTrucks]);

    useEffect(() => {
        if (unloadedTrucksData) {
            setUnloadedTrucks(unloadedTrucksData);
            setLoadTruck(unloadedTrucksData[selectedLoadTruckTab]);
        }
    }, [unloadedTrucksData, setUnloadedTrucks]);

    useEffect(() => {
        console.log('calling delivered data again', deliveredTrucksData)
        if (deliveredTrucksData) {
            setDeliveredTrucks(deliveredTrucksData);
            setSelectedDeliveryTruck(() => deliveredTrucksData[0]);
            setSelectedDeliveryTruckTab(0);
            // setDeliveredTruck(deliveredTrucksData[0]);
        }
    }, [deliveredTrucksData, setDeliveredTrucks]);

    const handlePageChange = (page) => {
        setPage(page);
        setOffset((page - 1) * recordsPerPage);
    }
    const searchHandler = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
    }


    const paginationLastLimitHandler = () => {
        let leftRecords = count - (offset + 1);
        if (leftRecords > recordsPerPage) {
            return (page) * recordsPerPage;
        }
        else {
            return count;
        }
    }

    const changeFreightTab = (tab) => {
        setSelectedFreightTab(tab);
        if (tab == 'freight-checkin') {
            refetchData();
            setStatusFilterValue('driver-assigned');
            if (selectedTruckTab > 0) {
                // let newTruckTab = selectedTruckTab - 1;
                let newTruckTab = 0;
                setSelectedTruckTab(newTruckTab);
                console.log('selected Truck tab', selectedTruckTab, 'new Truck Tab', newTruckTab);
            }
        }
        if (tab == 'unload') {
            refetchData();
            setStatusFilterValue('picked');
            if (selectedTruckTab > 0) {
                // let newTruckTab = selectedTruckTab - 1;
                let newTruckTab = 0;
                setSelectedTruckTab(newTruckTab);
                console.log('selected Truck tab', selectedTruckTab, 'new Truck Tab', newTruckTab);
            }
        }
        if (tab == 'load') {
            setStatusFilterValue('unloaded');
        }
        if (tab == 'delivery') {
            setStatusFilterValue('loaded');
            console.log('DT,[0]', deliveredTrucks[0]);
            setSelectedDeliveryTruck(selectedDeliveryTruck == null ? deliveredTrucks[0] : selectedDeliveryTruck);
        }
        console.log("selected Tab after change tab", selectedTruckTab);
        // if (tab == 'unload') {
        //     setCheckinTruck(checkinTruck ? checkinTruck : trucksData[0]);
        // }
    }

    const getCheckinTruckHandle = (truck) => {
        setCheckinTruck(truck);
        changeFreightTab('unload');
    }

    const closeReportIssueModal = () => {
        setShowReportIssueModal(false);
    }

    const showReportIssueModalHandle = () => {
        setShowReportIssueModal(true);
    }

    const changeSelectedTruckTabHandle = (index) => {
        setSelectedTruckTab(index);
    }

    const changeCheckinTruck = (truck, index) => {
        changeSelectedTruckTabHandle(index);
        setCheckinTruck(truck);
    }

    const changeLoadTruck = (truck, index) => {
        setSelectedLoadTruckTab(index);
        setLoadTruck(truck);
    }

    const changeDeliveryTruck = (truck, index) => {
        setSelectedDeliveryTruckTab(index);
        // console.log('truck 0.', deliveredTrucks[0]);
        setSelectedDeliveryTruck(truck)
        // selectedDeliveryTruck === null ? setSelectedDeliveryTruck(deliveredTrucks.length > 0 ? deliveredTrucks[0] : null) : setSelectedDeliveryTruck(truck);
        // setLoadTruck(truck);
    }


    const renderCheckInFreightTruckTabs = () => {
        if (checkinTrucks.length > 0) {
            return checkinTrucks.map((truck, index) => {
                return <li key={index} onClick={() => changeSelectedTruckTabHandle(index)} className={`${selectedTruckTab === index ? 'bg-[#3B82F6] text-white' : 'text-black'} cursor-pointer font-inter text-[13px] py-[8px] font-medium text-start pl-[20px] rounded-md  mt-3`} >{truck.make} {truck.model} {truck.numberPlate ? '( ' + truck.numberPlate + ')' : ''}</li>
            })
        }
        return '';
    }

    const renderUnloadTruckTabs = () => {
        if (checkinTrucks.length > 0) {
            return checkinTrucks.map((truck, index) => {
                return <li key={index} onClick={() => changeCheckinTruck(truck, index)} className={`${selectedTruckTab === index ? 'bg-[#3B82F6] text-white' : 'text-black'} cursor-pointer font-inter text-[13px] py-[8px] font-medium text-start pl-[20px] rounded-md  mt-3`} >{truck.make} {truck.model} {truck.numberPlate ? '( ' + truck.numberPlate + ')' : ''}</li>
            })
        }
        return '';
    }

    const renderDeliveryTruckTabs = () => {
        if (deliveredTrucks.length > 0) {
            return deliveredTrucks.map((truck, index) => {
                return <li key={index} onClick={() => changeDeliveryTruck(truck, index)} className={`${selectedDeliveryTruckTab === index ? 'bg-[#3B82F6] text-white' : 'text-black'} cursor-pointer z-3 font-inter text-[13px] py-[8px] font-medium text-start pl-[20px] rounded-md  mt-3`} >{truck.make} {truck.model} {truck.numberPlate ? '( ' + truck.numberPlate + ')' : ''}</li>
            })
        }
        return '';
    }

    const renderLoadingTruckTabs = () => {
        if (unloadedTrucks.length > 0) {
            return unloadedTrucks.map((truck, index) => {
                return <li key={index} onClick={() => changeLoadTruck(truck, index)} className={`${selectedLoadTruckTab === index ? 'bg-[#3B82F6] text-white' : 'text-black'} cursor-pointer z-3 font-inter text-[13px] py-[8px] font-medium text-start pl-[20px] rounded-md  mt-3`} >{truck.make} {truck.model} {truck.numberPlate ? '( ' + truck.numberPlate + ')' : ''}</li>
            })
        }
        return '';
    }

    const statusFilterChangeHandle = (e) => {
        e.preventDefault();
        console.log(e.target.value);
        if (e.target.value) {
            setStatusFilterValue(e.target.value);
        }
    }

    return (
        <div className="content min-h-[86vh]">
            {/* <div className="content-bar flex justify-between mt-6">
                <PageHeading>Fleet Control</PageHeading>

                <div className="search-bar">
                    <div className="search-field-container flex p-2 pl-4 bg-white border border-gray-300 shadow-sm rounded-md">
                        <img className="pr-2" src={searchIcon} alt="Search Icon" />
                        <input value={search} onInput={(e) => searchHandler(e)} type="text" className="pl-1" placeholder="Name" />
                    </div>
                </div>

            </div> */}
            <div className="flex flex-wrap w-full min-h-[86vh]">
                <div className="w-full sm:w-[25%] lg:w-[20%] xl:w-[17%] 2xl:w-[20%] bg-white border-r px-3 xl:py-[50px] xl:px-[32px] h-100">
                    <ul className="relative text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                        <li className={`relative z-10 py-[10px] text-[#374151] hover:text-black hover:font-bold text-[14px] ${selectFreightTab === 'freight-checkin' ? 'font-bold' : ''}`} >
                            <a onClick={() => changeFreightTab('freight-checkin')} type="button" className={`cursor-pointer flex items-center justify-start font-inter text-[14px] text-[#374151] ${selectFreightTab === 'freight-checkin' ? 'font-semibold ' : 'font-normal'} `}>
                                <span className="mr-5">
                                    <svg width="24" height="24" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className="hover:stroke-[#1F2937]" d="M17 5L9 1L1 5M17 5L9 9M17 5V15L9 19M9 9L1 5M9 9V19M1 5V15L9 19" stroke={selectFreightTab === 'freight-checkin' ? "#1F2937" : '#9CA3AF'} strokeWidth="2" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                Freight Check In</a>
                            {selectFreightTab === 'freight-checkin' && <ul className="w-full">
                                <li className='cursor-pointer font-inter text-[15px] py-[8px] font-medium text-start pl-[20px] mt-3'>All Trucks</li>
                                {renderCheckInFreightTruckTabs()}
                            </ul>}
                        </li>
                        <li className={`relative z-10 py-[10px] text-[#374151] hover:text-black hover:font-bold text-[14px] ${selectFreightTab === 'unload' ? 'font-bold' : ''}`} >

                            <a onClick={() => changeFreightTab('unload')} type="button" className={`cursor-pointer flex items-center justify-start font-inter text-[14px] text-[#374151] ${selectFreightTab === 'unload' ? 'font-semibold ' : 'font-normal'} `}>
                                <span className="mr-5">
                                    <svg width="24" height="24" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className="hover:stroke-[#1F2937]" d="M17 5L9 1L1 5M17 5L9 9M17 5V15L9 19M9 9L1 5M9 9V19M1 5V15L9 19" stroke={selectFreightTab === 'unload' ? "#1F2937" : '#9CA3AF'} strokeWidth="2" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                Unload Freight</a>
                            {selectFreightTab === 'unload' && <ul className="w-full">
                                {renderUnloadTruckTabs()}
                            </ul>}
                        </li>
                        <li className={`relative z-10 items-center justify-start py-[10px] text-[#374151] hover:text-black hover:font-bold text-[14px] ${selectFreightTab === 'load' ? 'font-bold' : ''}  hover:text-black dark:hover:text-white `} >
                            <a onClick={() => changeFreightTab('load')} type="button" className={`cursor-pointer flex items-center justify-start font-inter text-[14px] text-[#374151] ${selectFreightTab === 'load' ? 'font-semibold ' : 'font-normal'} `}>
                                <span className="mr-5">
                                    <svg width="24" height="24" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className="hover:stroke-[#1F2937]" d="M17 5L9 1L1 5M17 5L9 9M17 5V15L9 19M9 9L1 5M9 9V19M1 5V15L9 19" stroke={selectFreightTab === 'load' ? "#1F2937" : '#9CA3AF'} strokeWidth="2" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                Load Freight</a>
                            {selectFreightTab === 'load' && <ul className="w-full">
                                {renderLoadingTruckTabs()}
                            </ul>}
                        </li>
                        <li className={`relative z-10 items-center justify-start py-[10px] text-[#374151] hover:text-black hover:font-bold text-[14px] ${selectFreightTab === 'delivery' ? 'font-bold' : ''}  hover:text-black dark:hover:text-white `} >
                            <a onClick={() => changeFreightTab('delivery')} type="button" className={`cursor-pointer flex items-center justify-start font-inter text-[14px] text-[#374151] ${selectFreightTab === 'delivery' ? 'font-semibold ' : 'font-normal'} `}>
                                <span className="mr-5">
                                    <svg width="24" height="24" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className="hover:stroke-[#1F2937]" d="M17 5L9 1L1 5M17 5L9 9M17 5V15L9 19M9 9L1 5M9 9V19M1 5V15L9 19" stroke={selectFreightTab === 'delivery' ? "#1F2937" : '#9CA3AF'} strokeWidth="2" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                Delivery</a>
                            {selectFreightTab === 'delivery' && <ul className="w-full">
                                {renderDeliveryTruckTabs()}
                            </ul>}
                        </li>
                    </ul>

                </div>
                <div className="w-full sm:w-[75%] lg:w-[80%] xl:w-[83%] 2xl:w-[80%] bg-[#F9FAFB] px-3 xl:py-[50px] xl:px-[2rem] h-100">
                    <div className="pt-4 sm:pt-0 flex flex-wrap items-center justify-between mt-4">
                        <div className="w-full sm:w-3/6 md:w-3/6 2xl:w-1/6 search-bar">
                            <div className="search-field-container flex p-2 pl-4 bg-white border border-[#D1D5DB] shadow-sm rounded-md">
                                <img className="pr-2" src={searchIcon} alt="Search Icon" />
                                <input value={search} onInput={(e) => searchHandler(e)} type="text" className="w-full pl-1 font-inter text-[#6B7280]" placeholder="Code or Business Name" />
                            </div>
                        </div>
                        <div className="relative pr-0 block sm:inline-block mt-4 md:mt-0">
                            <select value={statusFilterValue} onChange={(e) => statusFilterChangeHandle(e)} className="block sm:inline-block appearance-none bg-white border border-[#D1D5DB] text-grey-darker py-2 px-4 pr-8 rounded-lg" id="location_zone">
                                {
                                    statusFilterOption.map((option) => {
                                        return (
                                            <option value={option.slug} key={option.id}>{option.title}</option>
                                        );
                                    })
                                }
                            </select>
                            <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-[0rem] top-[0.85rem] border-[#D1D5DB]">
                                <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>
                    {loading === true || unloadedTrucksloading === true || deliveredTrucksloading === true ?
                        <div>
                            <Loader />
                        </div>
                        :
                        <div>
                            {selectFreightTab === "freight-checkin" && <div>
                                <div className="w-full">
                                    <FreightCheckIn filterValue={statusFilterValue} selectedTruckTab={selectedTruckTab} trucksData={checkinTrucks} getCheckinTruck={(truck) => getCheckinTruckHandle(truck)} reportFreightCheckinIssue={() => showReportIssueModalHandle()} />
                                </div>
                            </div>}
                            {selectFreightTab === "unload" && <div>
                                <div className="w-full">
                                    <Unload filterValue={statusFilterValue} selectedTruckTab={selectedTruckTab} allTrucks={checkinTrucks} checkinTruck={checkinTruck} goBack={(tab) => changeFreightTab(tab)} reportUnloadIssue={() => showReportIssueModalHandle()} />
                                </div>
                            </div>}
                            {selectFreightTab === "load" && <div>
                                <div className="w-full">
                                    <Loading filterValue={statusFilterValue} selectedTruckTab={selectedLoadTruckTab} allTrucks={unloadedTrucks} selectedTruck={loadTruck} />
                                </div>
                            </div>}
                            {selectFreightTab === "delivery" && <div>
                                <div className="w-full">
                                    <Delivery filterValue={statusFilterValue} selectedTruckTab={selectedLoadTruckTab} allTrucks={deliveredTrucks} selectedTruck={selectedDeliveryTruck} />
                                </div>
                            </div>}
                        </div>}
                    {/* <ReportIsssueModal showModal={showReportIssueModal} closeModal={() => closeReportIssueModal()} /> */}
                </div>
            </div>

        </div>
    );
}

export default FleetControlPage;