import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/authContext';


function VerifyLogin({ children }) {
	const { isLoggedIn } = useContext(AuthContext);
	if (isLoggedIn) {
		return <Navigate to="/admin/dashboard" replace />
	}
	return children;
}
export default VerifyLogin;