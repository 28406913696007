import React, { useState } from 'react';
import { BACKEND_API_URL } from "../constants";

function ImageGallery({ images }) {
    const [modalImage, setModalImage] = useState({});
    const [showModal, setShowModal] = useState(false);

    const openImageLightBox = (image) => {
        console.log('imme', image);
        setModalImage(image);
        setShowModal(true);
        console.log('modalImge', modalImage);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const renderImage = () => {
        if (showModal) {
            return (
                <div className='absolute'>
                    <div className="fixed inset-0 bg-neutral-600/70 z-10">
                        <div className="flex flex-col justify-center items-center w-full h-full">
                            <div className="relative flex justify-center items-center w-[60%]">
                                <div onClick={closeModal} className="absolute z-30 top-0 rounded-md right-0 opacity-80 cursor-pointer w-[25px] h-[25px] bg-black text-white flex items-center justify-center p-2">
                                    <svg fill='#fff' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                                </div>
                                <img width="100%"
                                    alt="gallery"
                                    className="block w-[100%] rounded-lg object-cover object-cente opacity-100 z-20 rounded-xl"
                                    src={modalImage} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            <h2 className="text-3xl text-gray-900 leading-tight font-bold">Pickup Request Images</h2>
            <div className="container mx-auto px-5 py-2 lg:px-32 lg:pt-12">
                <div className="flex flex-wrap md:-m-2">
                    {images.map((image, index) => (
                        <div key={index} className="flex w-1/3 flex-wrap">
                            <div onClick={(e) => { console.log('img', e); openImageLightBox(e.target?.attributes?.src?.value) }} className="w-full p-1 md:p-2">
                                <img
                                    alt="gallery"
                                    className="block h-full w-full rounded-lg object-cover object-center"
                                    src={BACKEND_API_URL + 'images/' + image.image} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {renderImage()}
        </div>
    );
}

export default ImageGallery;