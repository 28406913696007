const validate = (data, validations) => {
    const errors = {};

    validations.forEach(({ field_name, title, rules }) => {
        rules.forEach(rule => {
            switch (rule) {
                case 'required':
                    if (!data[field_name]) {
                        errors[field_name] = `${title} is a required field.`;
                    }
                    break;
                case 'number':
                    if (isNaN(data[field_name])) {
                        errors[field_name] = `${title} must be a number.`;
                    }
                    break;
                default:
                    break;
            }
        });
    });

    return errors;
}

export default validate;