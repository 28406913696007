import { Outlet } from "react-router-dom";

function CommonLayout(props) {
    return (
        <div className="bg-color-primary text-white">
            <Outlet />
        </div>
    )
}

export default CommonLayout;