import React, { useState, useEffect, useContext } from 'react';
import PageHeading from "../../components/PageHeading";

import { useNavigate, useParams } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import Loader from '../../components/Loader';
import GraphQuery from '../../components/graphql/queries/Query';


function TruckCreatePage() {
    const { id } = useParams();
    const { token } = useContext(AuthContext);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const [zoneOptions, setZoneOptions] = useState([]);
    const [truckFeatureOptions, setTruckFeatureOptions] = useState([]);
    const [formData, setFormData] = useState({
        id: null,
        accountId: null,
        zoneId: '',
        numberPlate: '',
        make: '',
        model: '',
        color: '',
        weightCapacityValue: '',
        weightCapacityUnit: 'pounds',
        lengthUnit: 'meters',
        bedWidth: '',
        bedLength: '',
        maxHeightAllowed: '',
        cabHeight: '',
        overallWidth: '',
        overallLength: '',
        curbWeightValue: '',
        curbWeightUnit: 'pounds',
        selectedTruckFeatures: [],
    });

    const GET_TRUCK_FEATURES = gql`
        query GetTruckFeatures {
            truckFeatures {
                id,
                name
            }
        }
    `;

    const GET_TRUCK = gql`
        query GetTruck($id: Int!) {
            truck(id: $id) {
                id,
                numberPlate,
                make,
                model,
                color,
                weightCapacityValue,
                weightCapacityUnit,
                lengthUnit,
                bedWidth,
                bedLength,
                maxHeightAllowed,
                cabHeight,
                overallWidth,
                overallLength,
                curbWeightValue,
                curbWeightUnit,
                zone {
                    id,
                    title
                }
                selectedTruckFeatures {
                    id,
                    name  
                }
                account {
                    id
                    companyName
                }
                
            },
        }
    `;

    const UPDATE_TRUCK = gql`
        mutation($id: ID, $updateData: UpdateTruckInput!) {
            updateTruck(id: $id, truckData: $updateData) {
                truck {
                    numberPlate
                    make
                    model
                    color
                    weightCapacityValue,
                    weightCapacityUnit,
                    lengthUnit,
                    bedWidth,
                    bedLength,
                    maxHeightAllowed,
                    cabHeight,
                    overallWidth,
                    overallLength,
                    curbWeightValue,
                    curbWeightUnit,
                    selectedTruckFeatures {
                        id,
                        name  
                    }
                    account {
                        id 
                        companyName
                    }
                }
            }
        }
    `;

    const { data: truckFeaturesData } = useQuery(GET_TRUCK_FEATURES, {
        context: {
            headers: {
                Authorization: `JWT ${token}`
            }
        }
    });

    useEffect(() => {
        if (truckFeaturesData && truckFeaturesData.truckFeatures) {
            let truckFeatureOptions = truckFeaturesData.truckFeatures.map((truckFeature) => {
                return {
                    value: truckFeature.id,
                    label: truckFeature.name,
                }
            });

            setTruckFeatureOptions(truckFeatureOptions);
        }
    }, [truckFeaturesData]);

    const { loading: truckLoading, data: truckData } = useQuery(GET_TRUCK, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: {
            id: parseInt(id),
        },
    });

    const [updateTruck, { loading: updateLoading }] = useMutation(UPDATE_TRUCK, {
        onError: (error) => {
            try {
                const parsedError = JSON.parse(error.message);
                setErrors(parsedError);
            } catch (parseError) {
                setErrors('An error occurred while processing your request.');
            }
        },
        onCompleted: () => {
            window.location.href = "/admin/trucks";
        },
    });

    const { responseData: zoneData, dataCount: zoneCount, zoneloading, zoneError } = GraphQuery({ name: 'Zones', single: 'Zone' }, {
        skip: 0,
        search: '',
        sortBy: 'title',
        order: 'asc',
    }, 'id,title', 'zoneCount');

    useEffect(() => {
        if (zoneData) {
            setZoneOptions(zoneData);
        }
    }, [zoneData]);

    useEffect(() => {
        if (truckData) {
            let truckFeatures = [];
            if (truckData?.truck?.selectedTruckFeatures?.length > 0) {
                truckData?.truck?.selectedTruckFeatures.forEach(feature => {
                    truckFeatures.push(feature.id);
                });
            }

            setFormData({
                id: truckData?.truck?.id,
                zoneId: truckData?.truck?.zone ? truckData?.truck?.zone?.id : '',
                numberPlate: truckData.truck.numberPlate ? truckData?.truck?.numberPlate : '',
                make: truckData.truck.make ? truckData?.truck?.make : '',
                model: truckData.truck.model ? truckData?.truck?.model : '',
                color: truckData.truck.color ? truckData?.truck?.color : '',
                weightCapacityValue: truckData.truck.weightCapacityValue ? truckData?.truck?.weightCapacityValue : '',
                weightCapacityUnit: truckData.truck.weightCapacityUnit ? truckData?.truck?.weightCapacityUnit?.toLowerCase() : '',
                lengthUnit: truckData.truck.lengthUnit ? truckData?.truck?.lengthUnit?.toLowerCase() : '',
                bedWidth: truckData.truck.bedWidth ? truckData?.truck?.bedWidth : '',
                bedLength: truckData.truck.bedLength ? truckData?.truck?.bedLength : '',
                maxHeightAllowed: truckData.truck.maxHeightAllowed ? truckData?.truck?.maxHeightAllowed : '',
                cabHeight: truckData.truck.cabHeight ? truckData?.truck?.cabHeight : '',
                overallWidth: truckData.truck.overallWidth ? truckData?.truck?.overallWidth : '',
                overallLength: truckData.truck.overallLength ? truckData?.truck?.overallLength : '',
                curbWeightValue: truckData.truck.curbWeightValue ? truckData?.truck?.curbWeightValue : '',
                curbWeightUnit: truckData.truck.curbWeightUnit ? truckData?.truck?.curbWeightUnit?.toLowerCase() : '',
                selectedTruckFeatures: truckFeatures,
            });
        }
    }, [truckData]);

    const handleChange = (property, e) => {
        setFormData({
            ...formData,
            [property]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        updateTruck({
            context: {
                headers: {
                    Authorization: `JWT ${token}`
                }
            },
            variables: {
                id: id,
                updateData: {
                    numberPlate: formData.numberPlate,
                    zoneId: formData.zoneId,
                    make: formData.make,
                    model: formData.model,
                    color: formData.color,
                    weightCapacityValue: parseFloat(formData.weightCapacityValue),
                    weightCapacityUnit: formData.weightCapacityUnit,
                    lengthUnit: formData.lengthUnit,
                    bedWidth: parseFloat(formData.bedWidth),
                    bedLength: parseFloat(formData.bedLength),
                    maxHeightAllowed: parseFloat(formData.maxHeightAllowed),
                    cabHeight: parseFloat(formData.cabHeight),
                    overallWidth: parseFloat(formData.overallWidth),
                    overallLength: parseFloat(formData.overallLength),
                    curbWeightValue: parseFloat(formData.curbWeightValue),
                    curbWeightUnit: formData.curbWeightUnit,
                    selectedTruckFeatures: formData.selectedTruckFeatures,
                }
            }
        });
    }

    const handleCancel = () => {
        navigate("/admin/trucks");
    }

    const weightCapacityUnitHandle = (e) => {
        setFormData({
            ...formData,
            weightCapacityUnit: e.target.value,
        })
    };

    const truckFeaturesHandle = (event) => {
        const { value, checked } = event.target;
        var selectedFeatures = formData.selectedTruckFeatures;
        if (checked) {
            selectedFeatures.push(value);
        } else {
            selectedFeatures = selectedFeatures.filter((id) => id !== value);
        }
        setFormData({
            ...formData,
            selectedTruckFeatures: selectedFeatures,
        });
    };

    const curbWeightUnitHandle = (e) => {
        setFormData({
            ...formData,
            curbWeightUnit: e.target.value,
        })
    };

    const zoneHandle = (e, property) => {
        setFormData({
            ...formData,
            [property]: e.target.value
        })
    }

    const lengthUnitHandle = (e) => {
        setFormData({
            ...formData,
            lengthUnit: e.target.value,
        })
    };

    return (
        <div className="content px-3 lg:px-8">
            <div className="content-bar flex justify-between mt-6">
                <PageHeading>Update Truck</PageHeading>
            </div>
            <div className="mt-8">
                <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                    <div className="absolute inset-0"></div>
                    <div className="relative rounded-xl overflow-auto">
                        <div className="shadow-sm overflow-hidden mt-7 px-4">

                            {/* {
                                data && data.createTruck?.truck && < div className='text-green-500'>Truck Updated successfully.</div>
                            } */}


                            {/* Create Driver Form  */}
                            {truckLoading || updateLoading ?
                                <Loader />
                                : <div className='row'>
                                    <div className='md:w-3/6'>
                                        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                                            <div className="md:w-2/4">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="truck_zone">
                                                    Assign Zone
                                                </label>
                                                <div className="relative mb-2">
                                                    <select value={formData.zoneId} onChange={(e) => zoneHandle(e, 'zoneId')} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="truck_zone">
                                                        <option value="null">Select Zone</option>
                                                        {
                                                            zoneOptions.map((zone) => {
                                                                return (
                                                                    <option value={zone.id} key={zone.id}>{zone.title}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                    <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                        <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                    </div>

                                                </div>
                                                {errors && errors.zoneId && <p className="text-red-500 text-xs italic">{errors.zoneId}</p>}
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="truck-number-plate">
                                                        Truck Number Plate
                                                    </label>
                                                    <input value={formData.numberPlate} onChange={(value) => handleChange('numberPlate', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="truck-number-plate" type="text" autoComplete="on" placeholder="Truck Number Plate" required />
                                                    {errors && errors.numberPlate && <p className="text-red-500 text-xs italic">{errors.numberPlate}</p>}
                                                </div>
                                                <div className="md:w-1/2 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-last-name">
                                                        make
                                                    </label>
                                                    <input value={formData.make} onChange={(value) => handleChange('make', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4" id="grid-last-name" type="text" autoComplete="on" placeholder="Make" required />
                                                    {errors && errors.make && <p className="text-red-500 text-xs italic">{errors.make}</p>}
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                        Model
                                                    </label>
                                                    <input value={formData.model} onChange={(value) => handleChange('model', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="grid-first-name" type="text" placeholder="Model" required />
                                                </div>
                                                <div className="md:w-1/2 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-last-name">
                                                        Color
                                                    </label>
                                                    <input value={formData.color} onChange={(value) => handleChange('color', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4" id="grid-last-name" type="text" placeholder="Color" required />
                                                </div>

                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-weight-capacity-value">
                                                        Weight Capacity
                                                    </label>
                                                    <input value={formData.weightCapacityValue} onChange={(value) => handleChange('weightCapacityValue', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="grid-weight-capacity-value" type="number" placeholder="Weight Capacity" required />
                                                    {errors && errors.weightCapacityValue && <p className="text-red-500 text-xs italic">{errors.weightCapacityValue}</p>}
                                                </div>
                                                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-weight-capacity-unit">
                                                        Weight Capacity Unit
                                                    </label>
                                                    <div className="relative mb-3">
                                                        <select value={formData.weightCapacityUnit} name='weightCapacityUnit' onChange={weightCapacityUnitHandle} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="grid-weight-capacity-unit">
                                                            <option value="pounds">pounds (lbs)</option>
                                                            <option value="kilograms">kilograms (KGs)</option>
                                                        </select>
                                                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div>

                                                    </div>
                                                    {errors && errors.weightCapacityUnit && <p className="text-red-500 text-xs italic">{errors.weightCapacityUnit}</p>}
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-length-unit">
                                                        Length Unit
                                                    </label>
                                                    <div className="relative mb-3">
                                                        <select value={formData.lengthUnit} name='lengthUnit' onChange={lengthUnitHandle} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="grid-weight-capacity-unit">
                                                            <option value="feet">Feet (ft)</option>
                                                            <option value="inches">Inches (In)</option>
                                                            <option value="meters">Meters (m)</option>
                                                        </select>
                                                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div>

                                                    </div>
                                                    {errors && errors.lengthUnit && <p className="text-red-500 text-xs italic">{errors.lengthUnit}</p>}
                                                </div>
                                                <div className="md:w-1/4 px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="bed-width">
                                                        Bed Width
                                                    </label>
                                                    <input value={formData.bedWidth} onChange={(value) => handleChange('bedWidth', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="bed-width" type="number" placeholder="Bed Width" required />
                                                    {errors && errors.bedWidth && <p className="text-red-500 text-xs italic">{errors.bedWidth}</p>}
                                                </div>
                                                <div className="md:w-1/4 px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="bed-length">
                                                        Bed Length
                                                    </label>
                                                    <input value={formData.bedLength} onChange={(value) => handleChange('bedLength', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="bed-length" type="number" placeholder="Bed Length" required />
                                                    {errors && errors.bedLength && <p className="text-red-500 text-xs italic">{errors.bedLength}</p>}
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-1/4 px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="max-height-allowed">
                                                        Max Height Allowed
                                                    </label>
                                                    <input value={formData.maxHeightAllowed} onChange={(value) => handleChange('maxHeightAllowed', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="max-height-allowed" type="number" placeholder="Max Height Allowed" required />
                                                    {errors && errors.maxHeightAllowed && <p className="text-red-500 text-xs italic">{errors.maxHeightAllowed}</p>}
                                                </div>
                                                <div className="md:w-1/4 px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="cab-height">
                                                        Cab Height
                                                    </label>
                                                    <input value={formData.cabHeight} onChange={(value) => handleChange('cabHeight', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="cab-height" type="number" placeholder="Cab Height" required />
                                                    {errors && errors.cabHeight && <p className="text-red-500 text-xs italic">{errors.cabHeight}</p>}
                                                </div>
                                                <div className="md:w-1/4 px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="overall-length">
                                                        Overall Length
                                                    </label>
                                                    <input value={formData.overallLength} onChange={(value) => handleChange('overallLength', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="overall-length" type="number" placeholder="Overall Length" required />
                                                    {errors && errors.overallLength && <p className="text-red-500 text-xs italic">{errors.overallLength}</p>}
                                                </div>
                                                <div className="md:w-1/4 px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="overall-width">
                                                        Overall Width
                                                    </label>
                                                    <input value={formData.overallWidth} onChange={(value) => handleChange('overallWidth', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="overall-width" type="number" placeholder="Overall Width" required />
                                                    {errors && errors.overallWidth && <p className="text-red-500 text-xs italic">{errors.overallWidth}</p>}
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="curb-weight-value">
                                                        Curb Weight Value
                                                    </label>
                                                    <input value={formData.curbWeightValue} onChange={(value) => handleChange('curbWeightValue', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="curb-weight-value" type="number" placeholder="Curb Weight Value" required />
                                                    {errors && errors.curbWeightValue && <p className="text-red-500 text-xs italic">{errors.curbWeightValue}</p>}
                                                </div>
                                                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-curb-weight-unit">
                                                        Curb Weight Unit
                                                    </label>
                                                    <div className="relative mb-3">
                                                        <select value={formData.curbWeightUnit} onChange={curbWeightUnitHandle} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="grid-curb-weight-unit">
                                                            <option value="pounds">pounds (lbs)</option>
                                                            <option value="kilograms">kilograms (KGs)</option>
                                                        </select>
                                                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div>

                                                    </div>
                                                    {errors && errors.curbWeightUnit && <p className="text-red-500 text-xs italic">{errors.curbWeightUnit}</p>}
                                                </div>
                                            </div>
                                            <div className="mb-6">
                                                <h5 className="text-lg font-semibold">Truck Features</h5>
                                                {truckFeatureOptions.map((feature) => {
                                                    return (
                                                        <div key={feature.value} className="inline-flex items-center">
                                                            <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor={'check_' + feature.value}>
                                                                <input name={feature.label} onChange={truckFeaturesHandle} value={feature.value} checked={formData.selectedTruckFeatures.includes(feature.value)} type="checkbox"
                                                                    className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                                                                    id={'check_' + feature.value} />
                                                                <span
                                                                    className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                                                        stroke="currentColor" strokeWidth="1">
                                                                        <path fillRule="evenodd"
                                                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                            clipRule="evenodd"></path>
                                                                    </svg>
                                                                </span>
                                                            </label>
                                                            <label className="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor={'check_' + feature.value}>
                                                                {feature.label}
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className="flex justify-end mt-6">
                                                <button onClick={handleCancel} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500">Cancel</button>
                                                <button onClick={handleSubmit} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                            {/* Create Driver Form  */}
                        </div>
                    </div>
                    <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                </div>
            </div>
        </div >
    );
}

export default TruckCreatePage;