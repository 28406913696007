import React, { useState, useEffect, useContext } from 'react';
import PageHeading from "../../components/PageHeading";

import SortableTable from "../../components/SortableTable";
import { AuthContext } from '../../context/authContext';

import { Link } from 'react-router-dom'
import Pagination from "../../components/Pagination";
import searchIcon from '../../assets/search-icon.svg';
import Loader from '../../components/Loader';
import GraphQuery from '../../components/graphql/queries/Query';

function PickupInvoicePage() {

    const urlParams = new URLSearchParams(window.location.search);
    const [invoiceData, setInvoiceData] = useState([]);
    const { token } = useContext(AuthContext);
    const [search, setSearch] = useState("");

    const pageValue = urlParams.get('page');
    const [page, setPage] = useState(pageValue ? parseInt(pageValue) : 1);


    const recordsPerPage = 10;
    const [offset, setOffset] = useState((page - 1) * recordsPerPage);

    const { responseData: pickupInoicesData, loading } = GraphQuery({ name: 'PickupInvoices', single: 'PickupInvoice' }, {
        // first: 10,
        skip: 0,
        search: search.length > 3 ? search : '',
        sortBy: 'id',
        order: 'asc',
    }, `
        id
        invoiceDate,
        invoicePath,
        status,
        totalPrice,
        pickupRequest {
            pickupNumber
        }
        customer {
            contactPersonFullName
        }
    `);


    useEffect(() => {
        console.log('pickupInvoices', pickupInoicesData)
        if (pickupInoicesData) {
            setInvoiceData(pickupInoicesData)
        }
    }, [pickupInoicesData, setInvoiceData]);

    const [count, setCount] = useState(0);

    // const fetchPickupRequests = async () => {
    //     if (pickupData && pickupData.pickupRequests) {
    //         const pickupRequests = await pickupData.pickupRequests;
    //         setData(pickupRequests);
    //         setCount(pickupData.pickupCount);
    //     }
    // }

    // useEffect(() => {
    //     fetchPickupRequests();
    // }, [pickupData, setData]);

    useEffect(() => {
        setPage(pageValue ? parseInt(pageValue) : 1);
    }, [pageValue]);





    // if (error) {
    //     return <p>Error :(</p>;
    // }

    const handlePageChange = (page) => {
        setPage(page);
        setOffset((page - 1) * recordsPerPage);
    }

    const config = [
        {
            label: 'ID',
            render: (invoice) => <Link
                to={"/admin/invoices/" + invoice.id}
                className='text-blue-600 text-sm leading-5 font-normal'
            >{invoice.id}</Link>,
            headerClassName: 'pr-8 w-12',
            rowCellClassName: '',
        },
        {
            label: 'Invoice Date',
            render: (invoice) => invoice.invoiceDate,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Pickup Number',
            render: (invoice) => invoice?.pickupRequest?.pickupNumber,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Customer',
            render: (invoice) => invoice?.customer?.contactPersonFullName,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4 capitalize',
        },
        {
            label: 'Status',
            render: (invoice) => { return (<span className='p-1 px-3 bg-yellow-300 rounded-md'>{invoice.status}</span>) },
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4 capitalize',
        },
        {
            label: 'invoice file',
            render: (invoice) => <Link
                to={invoice.invoicePath}
                className='text-blue-600 text-sm leading-5 font-normal'
            >{invoice.invoicePath}</Link>,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Total Price',
            render: (invoice) => '$' + parseFloat(invoice.totalPrice).toFixed(2),
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
    ];



    const keyFun = (invoice) => {
        return invoice.id
    }

    const searchHandler = (e) => {
        e.preventDefault();
        // if (e.target.value.length > 2) {
        setSearch(e.target.value);
        // }
    }

    return (
        <div>
            <div className="content px-3 lg:px-8">
                <div className="content-bar flex justify-between mt-6">
                    <div className='flex flex-wrap justify-between'>
                        <div className="mr-3">
                            <PageHeading>INVOICES</PageHeading>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content px-3 lg:px-8">
                <div className="content-bar flex justify-between mt-6">
                    <div className="search-bar w-full sm:w-2/6">
                        <div className="search-field-container flex p-2 pl-4 bg-white border border-gray-300 shadow-sm rounded-md">
                            <img className="pr-2" src={searchIcon} alt="Search Icon" />
                            <input value={search} onInput={(e) => searchHandler(e)} type="text" className="pl-1 w-full" placeholder="Business Name or Pickup Number" />
                        </div>
                    </div>
                </div>
                {loading ? <div>
                    <Loader />
                </div> :
                    <div className="mt-8">
                        <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                            <div className="absolute inset-0"></div>
                            <div className="relative rounded-xl overflow-auto">
                                <div className="shadow-sm overflow-x-scroll mt-7">
                                    <SortableTable tableLayout="row" data={invoiceData} config={config} keyFun={keyFun} />
                                </div>
                            </div>
                            <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                        </div>
                        <div className="flex justify-between items-center self-stretch py-6">
                            {count > 0 ? <div>Showing {offset + 1} to {(page) * recordsPerPage} of {count} results</div> : null}
                            {count > recordsPerPage ?
                                <div className="flex">
                                    <Pagination
                                        to="/bols"
                                        currentPage={page}
                                        totalPages={Math.ceil(count / recordsPerPage)}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                                : null}
                        </div>
                    </div>}
            </div>



        </div >
    );
}

export default PickupInvoicePage;