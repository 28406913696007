import React, { useState, useEffect, useContext } from 'react';

import SortableTable from "../../components/SortableTable";
import PageHeading from "../../components/PageHeading";

import { Link } from 'react-router-dom'
import Pagination from "../../components/Pagination";

import searchIcon from '../../assets/search-icon.svg';
import { gql, useMutation, useQuery } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
// import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import Loader from '../../components/Loader';
import GraphQuery from '../../components/graphql/queries/Query';
import { useLocation } from 'react-router-dom';
import BatchInvoice from '../../components/batch_invoice/BatchInvoice';

function InvoicePage() {

    const urlParams = new URLSearchParams(window.location.search);
    const [showReportIssueModal, setShowReportIssueModal] = useState(false);
    const { token } = useContext(AuthContext);

    const location = useLocation();

    const [search, setSearch] = useState("");
    const [checkinTruck, setCheckinTruck] = useState(null);
    const [selectedTruckTab, setSelectedTruckTab] = useState(0);
    const [statusOptions, setStatusOptions] = useState([
        { id: 1, title: 'driver-assigned' },
        { id: 2, title: 'unloaded' },
        { id: 3, title: 'loaded' },
    ]);

    const pageValue = urlParams.get('page');
    const [page, setPage] = useState(pageValue ? parseInt(pageValue) : 1);

    const recordsPerPage = 10;
    const [offset, setOffset] = useState((page - 1) * recordsPerPage);
    const [count, setCount] = useState(0);
    const [selectFreightTab, setSelectedFreightTab] = useState('batch-invoice');
    const [checkinTrucks, setCheckinTrucks] = useState([]);

    const { responseData: trucksData, loading, error, refetchData } = GraphQuery({ name: 'Trucks', single: 'Truck' }, {
        pickupStatus: "driver-assigned",
        skip: 0,
        search: '',
        sortBy: 'number_plate',
        order: 'asc',
    }, `
        id,
        numberPlate,
        make,
        model,
        color
        zone{
            id,
            title,
            zoneLocations {
                id,
                title
            }
        }
        assignedPickupRequests(pickupStatus: $pickupStatus){
            id,
            createdAt,
            apiMode,
            shipmentDate,
            pickupDate,
            pickupTime,
            pickupNumber,
            shipmentTerms,
            pickupStatus,
            customer {
                contactPersonFullName,
                businessName
            }
            account {
                id,
                companyName,
            },
            pickupRequestAddresses {
                id,
                companyName,
                addressType,
                address1,
                address2,
                city,
                state,
                zipCode,
            },
            pickupRequestItems {
                id,
                description,
                quantity,
                weight,
                pallets,
                shipmentClass,
                charges
            },
        }
    `);

    useEffect(() => {
        if (location.pathname === '/admin/invoices') {
            refetchData();
        }
    }, [location, refetchData]);

    useEffect(() => {
        setPage(pageValue ? parseInt(pageValue) : 1);
    }, [pageValue]);


    useEffect(() => {
        console.log('shipmentClased fleet page', trucksData)
        if (trucksData) {
            setCheckinTrucks(trucksData);
            setCheckinTruck(trucksData[0]);
        }
    }, [trucksData, setCheckinTrucks]);

    const handlePageChange = (page) => {
        setPage(page);
        setOffset((page - 1) * recordsPerPage);
    }
    const searchHandler = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
    }


    const paginationLastLimitHandler = () => {
        let leftRecords = count - (offset + 1);
        if (leftRecords > recordsPerPage) {
            return (page) * recordsPerPage;
        }
        else {
            return count;
        }
    }

    const changeFreightTab = (tab) => {
        setSelectedFreightTab(tab);
        if (tab == 'batch-invoice') {
            refetchData();
            if (selectedTruckTab > 0) {
                let newTruckTab = selectedTruckTab - 1;
                setSelectedTruckTab(newTruckTab);
                console.log('selected Truck tab', selectedTruckTab, 'new Truck Tab', newTruckTab);
            }
        }
    }

    const getCheckinTruckHandle = (truck) => {
        setCheckinTruck(truck);
    }
    const changeSelectedTruckTabHandle = (index) => {
        setSelectedTruckTab(index);
    }

    const changeCheckinTruck = (truck, index) => {
        changeSelectedTruckTabHandle(index);
        setCheckinTruck(truck);
    }

    const renderCheckInFreightTruckTabs = () => {
        if (checkinTrucks.length > 0) {
            return checkinTrucks.map((truck, index) => {
                return <li key={index} onClick={() => changeSelectedTruckTabHandle(index)} className={`${selectedTruckTab === index ? 'bg-[#3B82F6] text-white' : 'text-black'} cursor-pointer font-inter text-[13px] py-[8px] font-medium text-start pl-[20px] rounded-md  mt-3`} >{truck.make} {truck.model} {truck.numberPlate ? '( ' + truck.numberPlate + ')' : ''}</li>
            })
        }
        return '';
    }

    const showReportIssueModalHandle = () => {
        setShowReportIssueModal(true);
    }

    return (
        <div className="content min-h-[86vh]">
            {/* <div className="content-bar flex justify-between mt-6">
                <PageHeading>Fleet Control</PageHeading>

                <div className="search-bar">
                    <div className="search-field-container flex p-2 pl-4 bg-white border border-gray-300 shadow-sm rounded-md">
                        <img className="pr-2" src={searchIcon} alt="Search Icon" />
                        <input value={search} onInput={(e) => searchHandler(e)} type="text" className="pl-1" placeholder="Name" />
                    </div>
                </div>

            </div> */}
            <div className="flex flex-wrap w-full min-h-[86vh]">
                <div className="w-full sm:w-[25%] lg:w-[20%] xl:w-[17%] 2xl:w-[20%] bg-white border-r px-3 xl:py-[50px] xl:px-[32px] h-100">
                    <ul className="relative text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                        <li onClick={() => changeFreightTab('batch-invoice')} className={`relative z-10 py-[10px] text-[#374151] hover:text-black hover:font-bold text-[14px] ${selectFreightTab === 'batch-invoice' ? 'font-bold' : ''}`} >
                            <a type="button" href="#" className={`flex items-center justify-start font-inter text-[14px] text-[#374151] ${selectFreightTab === 'batch-invoice' ? 'font-semibold ' : 'font-normal'} `}>
                                <span className="mr-5">
                                    <svg width="24" height="24" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className="hover:stroke-[#1F2937]" d="M17 5L9 1L1 5M17 5L9 9M17 5V15L9 19M9 9L1 5M9 9V19M1 5V15L9 19" stroke={selectFreightTab === 'batch-invoice' ? "#1F2937" : '#9CA3AF'} strokeWidth="2" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                Batch Invoice</a>
                            {selectFreightTab === 'batch-invoice' && <ul className="w-full">
                                {renderCheckInFreightTruckTabs()}
                            </ul>}
                        </li>
                    </ul>

                </div>
                <div className="w-full sm:w-[75%] lg:w-[80%] xl:w-[83%] 2xl:w-[80%] bg-[#F9FAFB] px-3 xl:py-[50px] xl:px-[50px] h-100">
                    <div className="pt-4 sm:pt-0 flex flex-wrap items-center justify-between mt-4">
                        <div className="w-full sm:w-3/6 md:w-3/6 2xl:w-1/6 search-bar">
                            <div className="search-field-container flex p-2 pl-4 bg-white border border-[#D1D5DB] shadow-sm rounded-md">
                                <img className="pr-2" src={searchIcon} alt="Search Icon" />
                                <input value={search} onInput={(e) => searchHandler(e)} type="text" className="w-full pl-1 font-inter text-[#6B7280]" placeholder="Code or Business Name" />
                            </div>
                        </div>
                        <div className="relative pr-0 block sm:inline-block mt-4 md:mt-0">
                            <select className="block sm:inline-block appearance-none bg-white border border-[#D1D5DB] text-grey-darker py-2 px-4 pr-8 rounded-lg" id="location_zone">
                                <option value="">Select Status</option>
                                {
                                    statusOptions.map((option) => {
                                        return (
                                            <option value={option.id} key={option.id}>{option.title}</option>
                                        );
                                    })
                                }
                            </select>
                            <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-[0rem] top-[0.85rem] border-[#D1D5DB]">
                                <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>
                    {selectFreightTab === "batch-invoice" && <div>
                        <div className="w-full">
                            <BatchInvoice selectedTruckTab={selectedTruckTab} trucksData={checkinTrucks} getCheckinTruck={(truck) => getCheckinTruckHandle(truck)} reportFreightCheckinIssue={() => showReportIssueModalHandle()} />
                        </div>
                    </div>}
                </div>
            </div>

        </div>
    );
}

export default InvoicePage;