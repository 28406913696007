import './index.css';
import React from "react";
import ReactDOM from "react-dom/client";
import App from './App';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import { GRAPHQL_API_URL } from './constants';

import store from './app/store'
import { Provider } from 'react-redux'

const client = new ApolloClient({
  uri: GRAPHQL_API_URL,
  cache: new InMemoryCache(),
});



const el = document.getElementById('root');

const root = ReactDOM.createRoot(el);
root.render(
  // <Provider store={store}>
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
  // {/* </Provider> */}
);