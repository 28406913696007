
import Header from '../layouts/PublicSite/Header';
import Footer from '../layouts/PublicSite/Footer';

function WelcomePage() {
    return (
        <>
            <div className="hero bg-cover bg-center bg-no-repeat bg-color-primary text-white">
                <Header theme="dark" />
            </div>

            <div className=" wrapper xl:max-w-[2000px] 2xl:max-w-[2500px] mx-auto">
                <section className="sm:px-8 md:px-16 xl:px-32 2xl:px-52">
                    <div>
                        <svg className="hidden lg:block absolute left-0  top-[20rem] z-20" xmlns="http://www.w3.org/2000/svg"
                            width="314" height="602" viewBox="0 0 314 602" fill="none">
                            <path opacity="0.2"
                                d="M-184.203 602L-184.203 0M-108.241 602L-108.241 0M-32.2787 602L-32.2787 0M43.6835 602L43.6834 0M119.646 602L119.646 0M195.608 602L195.608 0M271.57 602L271.57 0M-257 72.7971H345M-257 148.759H345M-257 224.721H345M-257 300.684H345M-257 376.646H345M-257 452.608H345M-257 528.57H345"
                                stroke="url(#paint0_radial_152_17241)" strokeWidth="2" />
                            <defs>
                                <radialGradient id="paint0_radial_152_17241" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(44 301) rotate(90) scale(301)">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className="my-14 sm:px-0 px-6 overflow-hidden">
                        <div className="flex-column items-center justify-center py-9 relative">
                            <div className="flex flex-col-reverse relative z-20">
                                <div className="sm:border-2 sm:border-[#56545484] sm:bg-[#56545484] sm:rounded-3xl sm:p-8 xl:p-32 block md:pb-[74px] overflow-hidden">
                                    <div>
                                        <h3 className="sm:text-center md:text-4xl text-2xl text-white font-bold">
                                            Welcome!
                                        </h3>
                                        <p className="sm:text-center text-white text-justify pt-4 pb-6 md:pr-12">
                                            Your account is under review. We will be in touch with you shortly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
}

export default WelcomePage;