import React from 'react';


const Pagination = ({ to, currentPage, totalPages, onPageChange }) => {
  const getPageNumbers = () => {
    const pageNumbers = [];
    
    // Calculate the range of page numbers to display
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);
    
    // Add ellipsis if there are more pages before the startPage
    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push('...');
      }
    }
    
    // Add page numbers between startPage and endPage
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    
    // Add ellipsis if there are more pages after the endPage
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }
      pageNumbers.push(totalPages);
    }
    
    return pageNumbers;
  };

  return (
    <ul className="flex">
      {getPageNumbers().map((page, index) => (
        <li
          key={index}
          className="border py-2.5 p-4 inline-flex flex-col justify-center items-center"
          onClick={() => onPageChange(page)}
        >

            <span className="cursor-pointer text-sm text-gray-500 leading-5 font-medium">{page}</span>
        </li>
      ))}
    </ul>
  );
};

export default Pagination;
