import React, { useState, useEffect, useContext } from 'react';
import PageHeading from "../../components/PageHeading";

import SortableTable from "../../components/SortableTable";
import { AuthContext } from '../../context/authContext';

import { Link, useParams } from 'react-router-dom'
import Pagination from "../../components/Pagination";
import searchIcon from '../../assets/search-icon.svg';
import Loader from '../../components/Loader';
import GraphQuery from '../../components/graphql/queries/Query';

function PickupInvoiceDetailPage() {
    const { id } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    const [invoiceData, setInvoiceData] = useState({});
    const [invoiceItems, setInvoiceItems] = useState([]);

    const { token } = useContext(AuthContext);
    const [search, setSearch] = useState("");

    const pageValue = urlParams.get('page');
    const [page, setPage] = useState(pageValue ? parseInt(pageValue) : 1);

    const recordsPerPage = 10;
    const [offset, setOffset] = useState((page - 1) * recordsPerPage);


    const { responseData: pickupInvoiceData, invoiceloading, error } = GraphQuery({ name: 'PickupInvoice', single: 'PickupInvoice' }, {
        id: parseInt(id),
    }, `
        id,
        invoiceDate,
        invoicePath,
        status,
        totalPrice,
        pickupRequest {
            pickupNumber
        }
        customer {
            contactPersonFullName
        }
        pickupInvoiceItems {
            id,
            description,
            quantity,
            itemPrice,
            serviceCharges,
            handlingCharges,
            subtotal
        }
    `);


    useEffect(() => {
        console.log('pickupInvoices', pickupInvoiceData)
        console.log('pickupInvoiceData?.pickupInvoiceItems', pickupInvoiceData?.pickupInvoiceItems)
        if (pickupInvoiceData && pickupInvoiceData.pickupInvoiceItems) {
            setInvoiceData(pickupInvoiceData);
            setInvoiceItems(pickupInvoiceData?.pickupInvoiceItems)
        }
    }, [pickupInvoiceData, setInvoiceData]);

    const [count, setCount] = useState(0);

    // const fetchPickupRequests = async () => {
    //     if (pickupData && pickupData.pickupRequests) {
    //         const pickupRequests = await pickupData.pickupRequests;
    //         setData(pickupRequests);
    //         setCount(pickupData.pickupCount);
    //     }
    // }

    // useEffect(() => {
    //     fetchPickupRequests();
    // }, [pickupData, setData]);

    useEffect(() => {
        setPage(pageValue ? parseInt(pageValue) : 1);
    }, [pageValue]);





    // if (error) {
    //     return <p>Error :(</p>;
    // }

    const handlePageChange = (page) => {
        setPage(page);
        setOffset((page - 1) * recordsPerPage);
    }

    const config = [
        {
            label: 'ID',
            render: (invoice) => <Link
                to={"/admin/invoices/" + invoice.id}
                className='text-blue-600 text-sm leading-5 font-normal'
            >{invoice.id}</Link>,
            headerClassName: 'pr-8 w-12',
            rowCellClassName: '',
        },
        {
            label: 'Invoice Date',
            render: (invoice) => invoice.invoiceDate,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Pickup Number',
            render: (invoice) => invoice?.pickupRequest?.pickupNumber,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Customer',
            render: (invoice) => invoice?.customer?.contactPersonFullName,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4 capitalize',
        },
        {
            label: 'Status',
            render: (invoice) => { return (<span className='p-1 px-3 bg-yellow-300 rounded-md'>{invoice.status}</span>) },
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4 capitalize',
        },
        {
            label: 'invoice file',
            render: (invoice) => <Link
                to={invoice.invoicePath}
                className='text-blue-600 text-sm leading-5 font-normal'
            >{invoice.invoicePath}</Link>,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Total Price',
            render: (invoice) => '$' + parseFloat(invoice.totalPrice).toFixed(2),
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
    ];



    const keyFun = (invoice) => {
        return invoice.id
    }

    const invoiceItemsConfig = [
        {
            label: 'ID',
            render: (invoiceItem) => invoiceItem.id,
            headerClassName: 'pr-8 w-12',
            rowCellClassName: '',
        },
        {
            label: 'Description',
            render: (invoiceItem) => invoiceItem.description,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Quantity',
            render: (invoiceItem) => invoiceItem.quantity,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Item Price',
            render: (invoiceItem) => '$' + invoiceItem.itemPrice,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4 capitalize',
        },
        {
            label: 'Handling Charges',
            render: (invoiceItem) => '$' + invoiceItem.handlingCharges,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4 capitalize',
        },
        {
            label: 'Service Charges',
            render: (invoiceItem) => '$' + invoiceItem.serviceCharges,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4 capitalize',
        },
        {
            label: 'Total Price',
            render: (invoiceItem) => '$' + parseFloat(invoiceItem.subtotal).toFixed(2),
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
    ];



    const invoiceItemkeyFun = (invoiceItem) => {
        return invoiceItem.id
    }

    const searchHandler = (e) => {
        e.preventDefault();
        // if (e.target.value.length > 2) {
        setSearch(e.target.value);
        // }
    }

    return (
        <div>
            <div className="content px-3 lg:px-8">
                <div className="content-bar flex justify-between mt-6">
                    <div className='flex flex-wrap justify-between'>
                        <div className="mr-3">
                            <PageHeading>PICK UP INVOICE DETAIL PAGE</PageHeading>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content px-3 lg:px-8">
                {invoiceloading ? <div>
                    <Loader />
                </div> :
                    <div>
                        <div className="mt-8">
                            <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                                <div className="absolute inset-0"></div>
                                <div className="relative rounded-xl overflow-auto">
                                    <div className="shadow-sm overflow-x-scroll mt-7">
                                        <SortableTable tableLayout="row" data={[invoiceData]} config={config} keyFun={keyFun} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {invoiceItems.length > 0 && <div>
                            <h3 className='text-3xl font-semibold mt-6'>Pickup Invoice Items</h3>
                            <div className="mt-8">
                                <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                                    <div className="absolute inset-0"></div>
                                    <div className="relative rounded-xl overflow-auto">
                                        <div className="shadow-sm overflow-x-scroll mt-7">
                                            <SortableTable tableLayout="row" data={invoiceItems} config={invoiceItemsConfig} keyFun={invoiceItemkeyFun} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>

                }
            </div>



        </div >
    );
}

export default PickupInvoiceDetailPage;