import React, { useState, useEffect, useContext } from 'react';

import PageHeading from "../../components/PageHeading";
import { gql, useMutation, useQuery } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import validate from '../../helpers/Validations';
import GraphMutation from '../../components/graphql/mutations/Mutation';
import GraphQuery from '../../components/graphql/queries/Query';

function LocationEditPage() {
    const [locationError, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const { id } = useParams();
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    const [zoneOptions, setZoneOptions] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        zoneId: '',
    });

    const { responseData: locationData, locationloading, error } = GraphQuery({ name: 'Location', single: 'Location' }, {
        id: parseInt(id),
    }, 'id, title, zone { id,title}');

    const { executeMutation: updateLocation, responseData: updatedLocationData, loading: updatedLocationLoading, errors: updatedLocationError } = GraphMutation(
        { name: 'Location', expectedResponseField: 'location', method: 'update' },
        { name: 'location', type: 'UpdateLocationInput' },
        `
            id,
            title,
            zone {
                id,
                title
            },
        `
    );

    const { responseData: zoneData, dataCount: zoneCount, zoneloading, zoneError } = GraphQuery({ name: 'Zones', single: 'Zone' }, {
        skip: 0,
        search: '',
        sortBy: 'title',
        order: 'asc',
    }, 'id,title', 'zoneCount');

    useEffect(() => {
        if (zoneData) {
            setZoneOptions(zoneData);
        }
    }, [zoneData]);

    useEffect(() => {
        if (locationData && locationData.zone) {
            setFormData({
                title: locationData.title,
                zoneId: locationData.zone.id,
            })
        }
    }, [locationData]);

    useEffect(() => {
        if (updatedLocationData && updatedLocationData.zone) {
            window.location.href = '/admin/locations';
        }
    }, [updatedLocationData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let input = {
            title: formData.title,
            zoneId: formData.zoneId
        }
        await updateLocation(input, parseInt(id));
    }

    const handleCancel = () => {
        navigate("/admin/locations");
    }

    const handleChange = (property, e) => {
        setFormData({
            ...formData,
            [property]: e.target.value
        })
    }

    const zoneHandle = (e, property) => {
        setFormData({
            ...formData,
            [property]: e.target.value
        })
    }

    return (
        <div className="content px-3 lg:px-8">
            <div className="content-bar flex justify-between mt-6">
                <PageHeading>Edit Location</PageHeading>
            </div>
            {locationloading ?
                <Loader />
                : <div className="mt-8">
                    <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                        <div className="absolute inset-0"></div>
                        <div className="relative rounded-xl overflow-auto">
                            <div className="shadow-sm overflow-hidden mt-7 px-4">
                                {
                                    updatedLocationData && updatedLocationData.zone && <div className="text-green-500">Location updated successfully.</div>
                                }

                                {/* Create Driver Form  */}
                                <div className="row">
                                    <div className="md:w-2/4">
                                        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-2/4 px-3 flex items-center">
                                                    <div className='md:w-full'>
                                                        <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="zone-title">
                                                            Title
                                                        </label>
                                                        <input value={formData.title} onChange={(value) => handleChange('title', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="zone-title" type="text" autoComplete="on" placeholder="Zone Title" required />
                                                        {locationError && locationError.title && <p className="text-red-500 text-xs italic">{locationError.title}</p>}
                                                    </div>
                                                </div>
                                                <div className="md:w-2/4 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="location_zone">
                                                        Zone
                                                    </label>
                                                    <div className="relative">
                                                        <select value={formData.zoneId} onChange={(e) => zoneHandle(e, 'zoneId')} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="location_zone">
                                                            <option value="null">Select Zone</option>
                                                            {
                                                                zoneOptions.map((zone) => {
                                                                    return (
                                                                        <option value={zone.id} key={zone.id}>{zone.title}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div>

                                                    </div>
                                                    {locationError && locationError.zoneId && <p className="text-red-500 text-xs italic">{locationError.zoneId}</p>}
                                                </div>

                                            </div>
                                            <div className="flex justify-end mt-6">
                                                <button onClick={handleCancel} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500">Cancel</button>
                                                <button onClick={handleSubmit} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                        </div>
                    </div>
                </div>
            }
        </div >
    );
}

export default LocationEditPage;