
// import { useEffect, useState } from "react";
import { gql, useMutation } from '@apollo/client';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup';

import Select from '../../components/Select';
import Input from '../../components/Input';
import TextArea from '../../components/TextArea';

import Header from '../layouts/PublicSite/Header';
import Footer from '../layouts/PublicSite/Footer';

const GRAPHQL_CREATE_ACCOUNT = gql`
  mutation createAccount(
        $username: String!, 
        $password: String!, 
        $email: String!, 
        $firstName: String!, 
        $lastName: String!, 
        $companyName: String!,
        $countryCode: String!,
        $phone: String!
        $zipCode: String!
        $jobTitle: String!
        $fleetSize: String!
        $additionalComments: String!
    ) {
    createAccount(
            username: $username, 
            password: $password, 
            email: $email, 
            firstName : $firstName, 
            lastName: $lastName, 
            companyName: $companyName,
            countryCode: $countryCode,
            phone: $phone,
            zipCode: $zipCode,
            jobTitle: $jobTitle,
            fleetSize: $fleetSize,
            additionalComments: $additionalComments,
        ) {
        user {
            id,
            firstName,
            lastName,
            email,
            username,
            account {
                id,
                companyName,
            }
        }
    }
  }
`;

function RegisterPageStep2() {
    const registrationFormData = JSON.parse(localStorage.getItem('registrationFormData'));
    const defaultValues = {
        companyName: registrationFormData?.companyName,
    };

    const formSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string().email().required('Email is required'),
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required').min(6, 'Password must be at 6 char long'),
        confirmPassword: Yup.string().required('Password is required').oneOf([Yup.ref('password')], 'Passwords does not match'),
        phone: Yup.string().required('Phone is required'),
        countryCode: Yup.string().required('Country is required'),
        zipCode: Yup.string().required('Zip Code is required'),
        companyName: Yup.string().required('Company name is required'),
        jobTitle: Yup.string().required('Job title is required'),
        fleetSize: Yup.string().required('Fleet size is required'),
        additionalComments: Yup.string(),
    })
    const formOptions = {
        resolver: yupResolver(formSchema),
        defaultValues: defaultValues,
    }

    const [createAccount] = useMutation(GRAPHQL_CREATE_ACCOUNT);

    const { register, handleSubmit, setError, formState: { errors } } = useForm(formOptions);
    const onSubmit = async (stepTwoData) => {
        try {
            const { data } = await createAccount({
                variables: {
                    firstName: stepTwoData['firstName'],
                    lastName: stepTwoData['lastName'],
                    email: stepTwoData['email'],
                    username: stepTwoData['username'],
                    password: stepTwoData['password'],
                    companyName: registrationFormData['companyName'],
                    countryCode: stepTwoData['countryCode'],
                    phone: stepTwoData['phone'],
                    zipCode: stepTwoData['zipCode'],
                    jobTitle: stepTwoData['jobTitle'],
                    fleetSize: stepTwoData['fleetSize'],
                    additionalComments: stepTwoData['additionalComments'],
                },
            });

            if (data.createAccount.user.account) {
                const d = new Date();
                let time = d.getTime();

                window.location.href = `/users/welcome?t=${time}`;
            }
        } catch (error) {
            if (error.message) {
                let errorMessage = JSON.parse(error.message.replace(/'/g, '"'));
                Object.entries(errorMessage).forEach(([key, value]) => {
                    setError(key, { message: value.message });
                });
            }
        }
    };

    // const GRAPHQL_QUERY = gql`
    //     query GetData {
    //         industryClassifications {
    //             id
    //             industryName,
    //         },
    //         industries {
    //             id,
    //             industryName,
    //         }
    //     }
    // `;

    // const { data: graphData } = useQuery(GRAPHQL_QUERY, {});

    // const [industryClassificationOptions, setIndustryClassificationOptions] = useState([]);
    // const [industryOptions, setIndustryOptions] = useState([]);

    // useEffect(() => {
    //     if (graphData && graphData.industryClassifications) {
    //         let industryClassificationOptions = graphData.industryClassifications.map((industryClassification) => {
    //             return {
    //                 value: industryClassification.id,
    //                 label: industryClassification.industryName,
    //             }
    //         });

    //         setIndustryClassificationOptions(industryClassificationOptions);
    //     }

    //     if (graphData && graphData.industries) {
    //         let industryOptions = graphData.industries.map((industryClassification) => {
    //             return {
    //                 value: industryClassification.id,
    //                 label: industryClassification.industryName,
    //             }
    //         });

    //         setIndustryOptions(industryOptions);
    //     }

    // }, [graphData]);

    return (
        <>
            <div className="hero bg-cover bg-center bg-no-repeat bg-color-primary text-white">
                <Header theme="dark" />
            </div>

            <div className=" wrapper xl:max-w-[2000px] 2xl:max-w-[2500px] mx-auto">
                <section className="sm:px-8 md:px-16 xl:px-32 2xl:px-52">
                    <div>
                        <svg className="hidden lg:block absolute left-0  top-[20rem] z-20" xmlns="http://www.w3.org/2000/svg"
                            width="314" height="602" viewBox="0 0 314 602" fill="none">
                            <path opacity="0.2"
                                d="M-184.203 602L-184.203 0M-108.241 602L-108.241 0M-32.2787 602L-32.2787 0M43.6835 602L43.6834 0M119.646 602L119.646 0M195.608 602L195.608 0M271.57 602L271.57 0M-257 72.7971H345M-257 148.759H345M-257 224.721H345M-257 300.684H345M-257 376.646H345M-257 452.608H345M-257 528.57H345"
                                stroke="url(#paint0_radial_152_17241)" strokeWidth="2" />
                            <defs>
                                <radialGradient id="paint0_radial_152_17241" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(44 301) rotate(90) scale(301)">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className="my-14 sm:px-0 px-6 overflow-hidden">
                        <div className="flex-column items-center justify-center py-9 relative">
                            <div className="flex flex-col-reverse relative z-20">
                                <div className="sm:border-2 sm:border-[#56545484] sm:bg-[#56545484] sm:rounded-3xl sm:p-8 xl:p-32 block md:pb-[74px] overflow-hidden">
                                    <div>
                                        <h3 className="sm:text-center md:text-4xl text-2xl text-white font-bold">
                                            Tell us about yourself
                                        </h3>
                                        <p className="sm:text-center text-white text-justify pt-4 pb-6 md:pr-12">
                                            Enter the field below to continue
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="flex flex-row space-x-4 font-mono text-white text-sm font-bold leading-6">
                                                <div className="w-6/12 p-8">
                                                    <div className="w-full px-3 mb-6 mt-4 md:mb-0">
                                                        <Input
                                                            type={`text`}
                                                            label="First name"
                                                            id={`firstName`}
                                                            register={register}
                                                        />
                                                        <p className="text-rose-700 text-sm mb-4">{errors.firstName?.message}</p>
                                                    </div>
                                                    <div className="w-full px-3 mb-6 mt-4 md:mb-0">
                                                        <Input
                                                            type={`text`}
                                                            label="Last Name"
                                                            id={`lastName`}
                                                            register={register}
                                                        />
                                                        <p className="text-rose-700 text-sm mb-4">{errors.lastName?.message}</p>
                                                    </div>
                                                    <div className="w-full px-3 mb-6 mt-4 md:mb-0">
                                                        <Input
                                                            label="Email"
                                                            type="email"
                                                            register={register}
                                                            id={`email`}
                                                        />
                                                        <p className="text-rose-700 text-sm mb-4">{errors.email?.message}</p>
                                                    </div>
                                                    <div className="w-full px-3 mb-6 mt-4 md:mb-0">
                                                        <Input
                                                            label="Username"
                                                            type="username"
                                                            register={register}
                                                            id={`username`}
                                                        />
                                                        <p className="text-rose-700 text-sm mb-4">{errors.username?.message}</p>
                                                    </div>
                                                    <div className="w-full px-3 mb-6 mt-4 md:mb-0">
                                                        <Input
                                                            label="Password"
                                                            type="password"
                                                            register={register}
                                                            id={`password`}
                                                        />
                                                        <p className="text-rose-700 text-sm mb-4">{errors.password?.message}</p>
                                                    </div>
                                                    <div className="w-full px-3 mb-6 mt-4 md:mb-0">
                                                        <Input
                                                            label="Confirm Password"
                                                            type="password"
                                                            register={register}
                                                            id={`confirmPassword`}
                                                        />
                                                        <p className="text-rose-700 text-sm mb-4">{errors.confirmPassword?.message}</p>
                                                    </div>
                                                </div>
                                                <div className="w-6/12 p-8">
                                                    <div className="w-full px-3 mb-6 mt-4 md:mb-0">
                                                        <Input
                                                            label="Phone"
                                                            register={register}
                                                            id={`phone`}
                                                        />
                                                        <p className="text-rose-700 text-sm mb-4">{errors.phone?.message}</p>
                                                    </div>
                                                    <div className="w-full px-3 mb-6 mt-4 md:mb-0">
                                                        <Select
                                                            options={[
                                                                {
                                                                    value: 'USA', label: 'USA'
                                                                },
                                                            ]}
                                                            id={`countryCode`}
                                                            firstOption="Select Country"
                                                            label="Country"
                                                            register={register}
                                                        />
                                                        <p className="text-rose-700 text-sm mb-4">{errors.countryCode?.message}</p>
                                                    </div>
                                                    <div className="w-full px-3 mb-6 mt-4 md:mb-0">
                                                        <Input
                                                            label="Zip Code"
                                                            register={register}
                                                            id={`zipCode`}
                                                        />
                                                        <p className="text-rose-700 text-sm mb-4">{errors.zipCode?.message}</p>
                                                    </div>
                                                    <div className="w-full px-3 mb-6 mt-4 md:mb-0">
                                                        <Input
                                                            label="Company name"
                                                            register={register}
                                                            id={`companyName`}
                                                        />
                                                        <p className="text-rose-700 text-sm mb-4">{errors.companyName?.message}</p>
                                                    </div>
                                                    <div className="w-full px-3 mb-6 mt-4 md:mb-0">
                                                        <Input
                                                            label="Job title"
                                                            register={register}
                                                            id={`jobTitle`}
                                                        />
                                                        <p className="text-rose-700 text-sm mb-4">{errors.jobTitle?.message}</p>
                                                    </div>
                                                    <div className="w-full px-3 mb-6 mt-4 md:mb-0">
                                                        <Input
                                                            label="Fleet size"
                                                            type={`number`}
                                                            register={register}
                                                            id={`fleetSize`}
                                                        />
                                                        <p className="text-rose-700 text-sm mb-4">{errors.fleetSize?.message}</p>
                                                    </div>
                                                    <div className="w-full px-3 mb-6 mt-4 md:mb-0">
                                                        <TextArea
                                                            label="Additional comments"
                                                            register={register}
                                                            id={`additionalComments`}
                                                        />
                                                        <p className="text-rose-700 text-sm mb-4">{errors.additionalComments?.message}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap justify-center -mx-3 mb-2">
                                                <div className="p-[2px] rounded-full bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block">
                                                    <button className="px-[21px] xl:px-[21px] rounded-full py-2 text-white bg-black text-sm font-[600]  hover:bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block duration-500">
                                                        Continue
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
}

export default RegisterPageStep2;