import { createSlice } from '@reduxjs/toolkit'

export const demoDataSlice = createSlice({
    name: 'demoData',
    initialState: {
        demoDate: {
            pickupDate: '',
            pickupTime: '',
            shipmentTerms: '',
            customer: {},
            boxes: [],
        },
    },
    reducers: {
        setDemoData: (state, action) => {
            state.demoData = action.payload
        },
        resetDemoData: (state) => {
            state.demoData = {
                pickupDate: '',
                pickupTime: '',
                shipmentTerms: '',
                customer: {},
                boxes: [],
            };
        }
    },
})

// Action creators are generated for each case reducer function
export const { setDemoData, resetDemoData } = demoDataSlice.actions

export default demoDataSlice.reducer