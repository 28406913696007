import logo from '../assets/logo.png';
import bellIcon from '../assets/bell-icon.svg';
import settingsIcon from '../assets/settings-icon.svg';
import { Link } from 'react-router-dom';

import ProfileMenu from './ProfileMenu';

function TopBar() {
    return (
        <div className="top-bar border-b pb-2 flex justify-between">
            <div className="logo-container">
                <Link to="/">
                    <img src={logo} alt="logo" />
                </Link>
            </div>
            <div className="notification-container">
                <div className="flex justify-center">
                    <div className="w-12 mt-2">
                        <img className="ml-auto" src={bellIcon} alt="Notifications" />
                    </div>
                    <div className="w-12 mt-2">
                        <img className="ml-auto" src={settingsIcon} alt="Settings" />
                    </div>
                    <div className="w-12 relative">
                        <ProfileMenu />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBar;