// import { useContext, useEffect, useState } from 'react';
// import { AuthContext } from '../../../context/authContext';
// import { gql, useQuery, useLazyQuery } from '@apollo/client';

// const GraphQuery = (modelName, variables = {}, output = 'id', count = null, useLazy = false) => {
//     const { token } = useContext(AuthContext);
//     const [responseData, setResponseData] = useState([]);
//     const [dataCount, setDataCount] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);

//     const filterVariables = Object.keys(variables).filter(key => !['id', 'first', 'skip', 'search', 'sortBy', 'order'].includes(key));
//     const filterArgs = filterVariables.map(key => `$${key}: ${typeof variables[key] === 'string' ? 'String' : 'Int'}`).join(', ');
//     const filterParams = filterVariables.map(key => `${key}: $${key}`).join(', ');

//     const GET_DYNAMIC_QUERY = gql`
//         query Get${modelName.name}(${variables.id ? '$id: Int' : ''}${variables.first ? '$first: Int!,' : ''}${variables.skip ? '$skip: Int,' : ''}${variables.search ? '$search: String,' : ''}${variables.sortBy ? `$sortBy: ${modelName.single}SortInput` : ''}${filterArgs ? `, ${filterArgs}` : ''}) {
//             ${modelName.name[0].toLowerCase() + modelName.name.slice(1)}(${variables.id ? 'id: $id' : ''}${variables.first ? 'first: $first,' : ''}${variables.skip ? 'skip: $skip,' : ''}${variables.search ? 'search: $search,' : ''}${variables.sortBy ? 'sortBy: $sortBy,' : ''}${filterParams ? `${filterParams},` : ''}) {
//                 ${output}
//             }
//             ${count ? count + (variables.search ? '(search: $search)' : '') : ''}
//         }
//     `;

//     const queryVariables = {
//         id: variables.id || null,
//         first: variables.first || null,
//         skip: variables.skip || 0,
//         search: variables.search || '',
//         sortBy: variables.sortBy ? { field: variables.sortBy, direction: variables.order || 'asc' } : null,
//     };

//     filterVariables.forEach(key => {
//         queryVariables[key] = variables[key];
//     });

//     const { data: queryData, refetch, ...rest } = useQuery(GET_DYNAMIC_QUERY, {
//         context: {
//             headers: {
//                 Authorization: `JWT ${token}`,
//             },
//         },
//         variables: queryVariables,
//         skip: useLazy // Skip the query if useLazy is true
//     });

//     const [executeQuery] = useLazyQuery(GET_DYNAMIC_QUERY, {
//         context: {
//             headers: {
//                 Authorization: `JWT ${token}`,
//             },
//         },
//     });

//     useEffect(() => {
//         if (useLazy) {
//             executeQuery({ variables: queryVariables });
//         }
//     }, [executeQuery, useLazy]);

//     useEffect(() => {
//         let dataAttributeName = modelName.name[0].toLowerCase() + modelName.name.slice(1);
//         if (queryData && queryData[dataAttributeName]) {
//             setResponseData(queryData[dataAttributeName]);
//             if (count != null) setDataCount(queryData[count]);
//         }
//     }, [queryData]);

//     useEffect(() => {
//         setLoading(rest.loading);
//         setError(rest.error);
//     }, [rest.loading, rest.error]);

//     return { responseData, dataCount, loading, error, refetchData: refetch, executeQuery };
// };

// export default GraphQuery;




// import { useContext, useEffect, useState } from 'react';
// import { AuthContext } from '../../../context/authContext';
// import { gql, useQuery, useLazyQuery } from '@apollo/client';

// const GraphQuery = (modelName, variables = {}, output = 'id', count = null, useLazy = false) => {
//     const { token } = useContext(AuthContext);
//     const [responseData, setResponseData] = useState([]);
//     const [dataCount, setDataCount] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);

//     const filterVariables = Object.keys(variables).filter(key => !['id', 'first', 'skip', 'search', 'sortBy', 'order'].includes(key));
//     const filterArgs = filterVariables.map(key => {
//         if (Array.isArray(variables[key])) {
//             return `$${key}: [${typeof variables[key][0] === 'string' ? 'String' : 'Int'}]`;
//         }
//         return `$${key}: [${typeof variables[key] === 'string' ? 'String' : 'Int'}]`;
//     }).join(', ');
//     const filterParams = filterVariables.map(key => `${key}: $${key}`).join(', ');

//     const GET_DYNAMIC_QUERY = gql`
//         query Get${modelName.name}(${variables.id ? '$id: Int' : ''}${variables.first ? '$first: Int!,' : ''}${variables.skip ? '$skip: Int,' : ''}${variables.search ? '$search: String,' : ''}${variables.sortBy ? `$sortBy: ${modelName.single}SortInput` : ''}${filterArgs ? `, ${filterArgs}` : ''}) {
//             ${modelName.name[0].toLowerCase() + modelName.name.slice(1)}(${variables.id ? 'id: $id' : ''}${variables.first ? 'first: $first,' : ''}${variables.skip ? 'skip: $skip,' : ''}${variables.search ? 'search: $search,' : ''}${variables.sortBy ? 'sortBy: $sortBy,' : ''}${filterParams ? `${filterParams},` : ''}) {
//                 ${output}
//             }
//             ${count ? count + (variables.search ? '(search: $search)' : '') : ''}
//         }
//     `;

//     const queryVariables = {
//         id: variables.id || null,
//         first: variables.first || null,
//         skip: variables.skip || 0,
//         search: variables.search || '',
//         sortBy: variables.sortBy ? { field: variables.sortBy, direction: variables.order || 'asc' } : null,
//     };

//     filterVariables.forEach(key => {
//         // Handle pickupStatus specifically to convert single string to array if needed
//         queryVariables[key] = Array.isArray(variables[key]) ? variables[key] : [variables[key]];
//     });

//     const { data: queryData, refetch, ...rest } = useQuery(GET_DYNAMIC_QUERY, {
//         context: {
//             headers: {
//                 Authorization: `JWT ${token}`,
//             },
//         },
//         variables: queryVariables,
//         skip: useLazy // Skip the query if useLazy is true
//     });

//     const [executeQuery] = useLazyQuery(GET_DYNAMIC_QUERY, {
//         context: {
//             headers: {
//                 Authorization: `JWT ${token}`,
//             },
//         },
//     });

//     useEffect(() => {
//         if (useLazy) {
//             executeQuery({ variables: queryVariables });
//         }
//     }, [executeQuery, useLazy]);

//     useEffect(() => {
//         let dataAttributeName = modelName.name[0].toLowerCase() + modelName.name.slice(1);
//         if (queryData && queryData[dataAttributeName]) {
//             setResponseData(queryData[dataAttributeName]);
//             if (count != null) setDataCount(queryData[count]);
//         }
//     }, [queryData]);

//     useEffect(() => {
//         setLoading(rest.loading);
//         setError(rest.error);
//     }, [rest.loading, rest.error]);

//     return { responseData, dataCount, loading, error, refetchData: refetch, executeQuery };
// };

// export default GraphQuery;










import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../context/authContext';
import { gql, useQuery, useLazyQuery } from '@apollo/client';

const GraphQuery = (modelName, variables = {}, output = 'id', count = null, useLazy = false) => {
    const { token } = useContext(AuthContext);
    const [responseData, setResponseData] = useState([]);
    const [dataCount, setDataCount] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const filterVariables = Object.keys(variables).filter(key => !['id', 'first', 'skip', 'search', 'sortBy', 'order'].includes(key));
    const filterArgs = filterVariables.map(key => {
        if (Array.isArray(variables[key])) {
            return `$${key}: [${typeof variables[key][0] === 'string' ? 'String' : 'Int'}]`;
        }
        return `$${key}: ${typeof variables[key] === 'string' ? 'String' : 'Int'}`;
    }).join(', ');
    const filterParams = filterVariables.map(key => `${key}: $${key}`).join(', ');

    const queryArgs = [
        variables.id !== undefined ? '$id: Int!' : '',
        variables.first !== undefined ? '$first: Int!' : '',
        variables.skip !== undefined ? '$skip: Int' : '',
        variables.search !== undefined ? '$search: String' : '',
        variables.sortBy !== undefined ? `$sortBy: ${modelName.single}SortInput` : '',
        filterArgs
    ].filter(arg => arg !== '').join(', ');

    const queryFields = [
        variables.id !== undefined ? 'id: $id' : '',
        variables.first !== undefined ? 'first: $first' : '',
        variables.skip !== undefined ? 'skip: $skip' : '',
        variables.search !== undefined ? 'search: $search' : '',
        variables.sortBy !== undefined ? 'sortBy: $sortBy' : '',
        filterParams
    ].filter(field => field !== '').join(', ');

    const GET_DYNAMIC_QUERY = gql`
        query Get${modelName.name}(${queryArgs}) {
            ${modelName.name[0].toLowerCase() + modelName.name.slice(1)}(${queryFields}) {
                ${output}
            }
            ${count ? count + (variables.search ? '(search: $search)' : '') : ''}
        }
    `;

    const queryVariables = {
        id: variables.id || null,
        first: variables.first || null,
        skip: variables.skip || 0,
        search: variables.search || '',
        sortBy: variables.sortBy ? { field: variables.sortBy, direction: variables.order || 'asc' } : null,
    };

    filterVariables.forEach(key => {
        queryVariables[key] = variables[key];
    });

    const { data: queryData, refetch, ...rest } = useQuery(GET_DYNAMIC_QUERY, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: queryVariables,
        skip: useLazy // Skip the query if useLazy is true
    });

    const [executeQuery] = useLazyQuery(GET_DYNAMIC_QUERY, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
    });

    useEffect(() => {
        if (useLazy) {
            executeQuery({ variables: queryVariables });
        }
    }, [executeQuery, useLazy]);

    useEffect(() => {
        let dataAttributeName = modelName.name[0].toLowerCase() + modelName.name.slice(1);
        if (queryData && queryData[dataAttributeName]) {
            setResponseData(queryData[dataAttributeName]);
            if (count != null) setDataCount(queryData[count]);
        }
    }, [queryData]);

    useEffect(() => {
        setLoading(rest.loading);
        setError(rest.error);
    }, [rest.loading, rest.error]);

    return { responseData, dataCount, loading, error, refetchData: refetch, executeQuery };
};

export default GraphQuery;
