import classNames from "classnames";
import React, { useState } from 'react';

function Input({ label, register, type, validationOptions, onChange, defaultValue, id, cssInputClasses, cssLabelClasses, hideLabel, placeholder }) {
    const [value, setValue] = useState(defaultValue);
    if (defaultValue !== value) {
        setValue(defaultValue);
    }

    const inputClasses = classNames(
        'appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white',
        cssInputClasses
    );

    const labelClasses = classNames(
        'text-sm leading-5 font-medium',
        cssLabelClasses
    );
    return (
        <>
            {hideLabel === true ? null : <label htmlFor={id} className={labelClasses}>{label}</label>}
            <input onChange={onChange} id={id} type={type} placeholder={placeholder} {...register(id, validationOptions)} value={value} className={inputClasses} />
        </>
    );
}

export default Input;