import classNames from "classnames";

function Li({ children, className, activeClassName }) {
    const classes = classNames(
        className,
        activeClassName
    );

    return (
        <li className={classes}>{children}</li>
    );
}

export default Li;