import { Fragment } from "react";
import classNames from "classnames";
import moment from 'moment';

function VerticalTable({ data, config, keyFun }) {
    const renderedRows = data.map((rowData) => {
        const unMutatedRow = { ...rowData };
        unMutatedRow.createdAt = moment(unMutatedRow.createdAt).format('DD-MM-Y');
        const renderedCells = config.map((column) => {
            const classes = classNames(
                'border-b p-4 text-gray-500 text-left',
                column.rowCellClassName,
            );
            return (
                <Fragment key={column.label}>
                    <tr>
                        <th className={classes + ' w-1/2'}>{column.label}</th>
                        <td className={classes}>{column.render(unMutatedRow, unMutatedRow.createdAt)}</td>
                    </tr>
                </Fragment>
            );
        });
        return (
            <Fragment key={keyFun(unMutatedRow)}>
                {renderedCells}
            </Fragment>
        );
    });

    return (
        <table className="border-collapse table-auto w-full text-sm shadow-base">
            <tbody className="bg-white">
                {renderedRows}
            </tbody>
        </table>
    );
}

export default VerticalTable;