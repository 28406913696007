import React, { useState, useContext } from 'react';

import PageHeading from "../../components/PageHeading";
import { gql, useMutation } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import validate from '../../helpers/Validations';

function TruckFeatureCreatePage() {
    const [errors, setErrors] = useState([]);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        id: null,
        name: ''
    });

    const CREATE_TRUCK_FEATURE = gql`
        mutation($truckFeatureNew: CreateTruckFeatureInput!) {
            createTruckFeature(truckFeatureData: $truckFeatureNew) {
                truckFeature {
                    name
                }
            }
        }
    `;

    const [createTruckFeature, { loading, data }] = useMutation(CREATE_TRUCK_FEATURE, {
        onError: (error) => {
            try {
                const parsedError = JSON.parse(error.message);
                setErrors(parsedError);
            } catch (parseError) {
                setErrors('An error occurred while processing your request.');
            }

        },
        onCompleted: () => {
            window.location.href = '/admin/truck-features';
            // navigate("/admin/trucks", { fetch: true });
        }
    })


    const handleChange = (property, e) => {
        setFormData({
            ...formData,
            [property]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors(null);

        const validations = [
            { field_name: 'name', title: 'Name', rules: ['required'] },
        ];

        const validationErrors = validate(formData, validations);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        let TruckFeatureInput = formData;
        createTruckFeature({
            context: {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            },
            variables: {
                truckFeatureNew: {
                    name: TruckFeatureInput.name,
                }
            }
        });

        // window.location.href = 'admin/truck-features';
    }

    const handleCancel = () => {
        navigate("/admin/truck-features");
    }

    return (
        <div className="content px-3 lg:px-8">
            <div className="content-bar flex justify-between mt-6">
                <PageHeading>Add New Truck Feature</PageHeading>
            </div>
            <div className="mt-8">
                <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                    <div className="absolute inset-0"></div>
                    {loading ?
                        <Loader />
                        : <div className="relative rounded-xl overflow-auto">
                            <div className="shadow-sm overflow-hidden mt-7 px-4">
                                {
                                    data && data.createTruck?.truck && < div className='text-green-500'>Truck Feature added successfully.</div>
                                }

                                {/* Create Driver Form  */}
                                <div className='row'>
                                    <div className='md:w-3/6'>
                                        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">

                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                        Name
                                                    </label>
                                                    <input value={formData.name} onChange={(value) => handleChange('name', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="grid-first-name" type="text" autoComplete="on" placeholder="Name" required />
                                                    {errors && errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
                                                </div>
                                            </div>
                                            <div className="flex justify-end mt-6">
                                                <button onClick={handleCancel} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500">Cancel</button>
                                                <button onClick={handleSubmit} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                </div>
            </div>
        </div >
    );
}

export default TruckFeatureCreatePage;