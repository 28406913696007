import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const _token = localStorage.getItem('token');  
  
  const [token, setToken] = useState(_token);
  const [registrationFormData, setRegistrationFormArray] = useState([]);

  const isLoggedIn = !!token;

  const setAuthData = (token) => {
    setToken(token);
  };

  const setRegistrationFormData = (data) => {
    setRegistrationFormArray(data);
  }

  return (
    <AuthContext.Provider value={{ 
        token, 
        setAuthData, 
        isLoggedIn, 
        registrationFormData, 
        setRegistrationFormData 
      }}>
      {children}
    </AuthContext.Provider>
  );
};