import React, { useEffect, useContext } from 'react';

import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/authContext';

import { gql, useQuery } from '@apollo/client';


function Protected({ children }) {
	const { isLoggedIn, token } = useContext(AuthContext);
	const GET_PROFILE = gql`
        query {
			me {
				id,
				isActive
			}
		}
    `;

    const { data: me } = useQuery(GET_PROFILE, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
		variables: {
			first: 1,
		}
    });
	useEffect(() => {
		
    }, [me]);

	if (!isLoggedIn) {
		return <Navigate to="/users/login" replace />
	}

	return children;
}
export default Protected;