import classNames from "classnames";

function TextArea({ label, register, validationOptions, id, cssTextAreaClasses, cssLabelClasses, hideLabel, placeholder, rows, cols }) {
    
    const inputClasses = classNames(
        'appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white',
        cssTextAreaClasses
    );

    const labelClasses = classNames(
        'text-sm leading-5 font-medium',
        cssLabelClasses
    );
    
    return (
        <>
            {hideLabel === true ? null : <label htmlFor={id} className={labelClasses}>{label}</label>}
            <textarea id={id} rows={rows} cols={cols} placeholder={placeholder} {...register(id, validationOptions)} className={inputClasses}></textarea>
        </>
    );
}

export default TextArea;