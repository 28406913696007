import React, { useContext } from 'react';

import darkLogo from '../../../assets/site/dark-logo.png';
import whiteLogo from '../../../assets/site/white-logo.png';

import { AuthContext } from '../../../context/authContext';

import { Link } from 'react-router-dom'
function Header({ theme }) {
    const { isLoggedIn } = useContext(AuthContext);

    return (
        <header className="bg-transparent text-color-primary-dark">
            <nav className="md:px-16 px-6 mx-auto flex justify-between items-center">
                <div className="w-30 text-3xl py-3">
                    <a href="/">
                        {theme === 'dark' ? <img className="" src={whiteLogo} alt="" /> : <img className="" src={darkLogo} alt="" />}
                    </a>
                </div>
                <div>
                    <ul className="hidden md:flex items-center gap-10">
                        <li>
                            <a href="/" className="hover:text-color-primary-secondary font-medium font-roboto ease-in duration-200 text-sm text-color-white">Home</a>
                        </li>
                        <li>
                            <a href="/" className="hover:text-color-primary-secondary font-medium font-roboto ease-in duration-200 text-sm text-color-white">Contact</a>
                        </li>
                        <li>
                            <a href="/" className="hover:text-color-primary-secondary font-medium font-roboto ease-in duration-200 text-sm text-color-white">Support</a>
                        </li>
                        <li>
                            {!isLoggedIn ?
                                <div className='hidden md:flex items-center gap-10'>
                                    <button className="bg-gradient-to-l from-5% from-gray-300 to-gray-300 to-100% px-5 py-1 rounded-sm capitalize font-medium font-roboto hover:opacity-80 ease-in duration-200 text-sm text-color-dark">
                                        <Link to={"/users/login"}>Sign in</Link>
                                    </button>

                                    <button className="bg-gradient-to-l from-5% from-gray-300 to-gray-300 to-100% px-5 py-1 rounded-sm capitalize font-medium font-roboto hover:opacity-80 ease-in duration-200 text-sm text-color-dark">
                                        <Link to={"/users/register"}>Register</Link>
                                    </button>
                                </div>
                                :
                                <button className="bg-gradient-to-l from-5% from-gray-300 to-gray-300 to-100% px-5 py-1 rounded-sm capitalize font-medium font-roboto hover:opacity-80 ease-in duration-200 text-sm text-color-dark">
                                    <Link to={"/admin/dashboard"}>Dashboard</Link>
                                </button>
                            }
                        </li>
                    </ul>
                </div>
                <div id="hamburger" className="md:hidden cursor-pointer z-50">
                    <i className="fa-solid fa-bars"></i>
                </div>

                <div id="menu" className="hidden bg-color-primary-dark h-[100vh] absolute inset-0">
                    <ul className="h-full grid place-items-center py-20">
                        <li><a id="nav-link" href="/"
                            className="hover:text-color-primary-secondary text-white ease-in duration-200">Home</a></li>
                        <li><a id="nav-link" href="/"
                            className="hover:text-color-primary-secondary text-white ease-in duration-200">Contact</a>
                        </li>
                        <li><a id="nav-link" href="/"
                            className="hover:text-color-primary-secondary text-white ease-in duration-200">Support</a>
                        </li>
                        <li>
                            <button
                                className="bg-color-primary-secondary px-9 py-3 rounded-md capitalize hover:opacity-80 ease-in duration-200">Signin</button>
                        </li>
                        <li>
                            <button
                                className="bg-color-primary-secondary px-9 py-3 rounded-md capitalize hover:opacity-80 ease-in duration-200">Signup</button>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default Header;