import React, { useState, useEffect, useContext } from 'react';

import SortableTable from "../components/SortableTable";
import PageHeading from "../components/PageHeading";

import { useNavigate, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client';
import { BACKEND_API_URL } from '../constants.js';
import { AuthContext } from '../context/authContext';
import Loader from '../components/Loader.js';

function BolDetailPage() {
    const { id } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();

    const [bolShipperAddress, setBolShipperAddress] = useState([]);
    const [bolBillingAddress, setBolBillingAddress] = useState([]);
    const [bolConsigneeAddress, setBolConsigneeAddress] = useState([]);
    const [bolItems, setBolItems] = useState([]);
    const [bolShipmentReferences, setBolShipmentReferences] = useState([]);
    const [errors, setErrors] = useState(null);
    const [apiResponse, setApiResponse] = useState(null);
    // const [isloading, setIsLoading] = useState(false);

    const pageValue = urlParams.get('page');



    // get the data from the server
    const GET_BOL = gql`
        query GetBol($id: Int!) {
            bol(id: $id) {
                id,
                createdAt,
                apiMode,
                shipmentDate,
                shipmentTerms,
                serviceType,
                driverName,
                truckNumber,
                shipmentId,
                customer {
                    contactPersonFullName,
                    businessName
                },
                account {
                    id,
                    companyName,
                },
                bolAddresses{
                    id,
                    companyName,
                    addressType,
                    address1,
                    address2,
                    city,
                    state,
                    zipCode,
                },
                bolItems {
                    id,
                    description,
                    quantity,
                    weight,
                    length,
                    width,
                    height,
                    pallets,
                    shipmentClass,
                    charges,
                    serviceType,
                    packageType
                },
                bolShipmentReferences {
                    id,
                    description,
                    referenceType,
                    referenceNumber,
                    poNumber,
                    createdAt
                },
            }
            
            
        }
    `;

    const { loading, error, data: bolData } = useQuery(GET_BOL, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: {
            id: parseInt(id),
        },
    });

    const [data, setBol] = useState([]);

    useEffect(() => {
        if (bolData) {
            if (bolData.bol) {
                setBol([bolData.bol]);
            }

            if (bolData.bol.bolAddresses) {
                const bolAddresses = bolData.bol?.bolAddresses;
                const bolShipperAddress = bolAddresses.filter((bolAddress) => bolAddress.addressType === 'SHIPPER');
                const bolBillingAddress = bolAddresses.filter((bolAddress) => bolAddress.addressType === 'BILLTO');
                const bolConsigneeAddress = bolAddresses.filter((bolAddress) => bolAddress.addressType === 'CONSIGNEE');

                setBolShipperAddress(bolShipperAddress);
                setBolBillingAddress(bolBillingAddress);
                setBolConsigneeAddress(bolConsigneeAddress);
            }

            if (bolData.bol.bolItems) {
                const bolItems = bolData.bol.bolItems;
                setBolItems(bolItems);
            }

            if (bolData.bol.bolShipmentReferences) {
                const bolShipmentReferences = bolData.bol.bolShipmentReferences;
                setBolShipmentReferences(bolShipmentReferences);
            }

        }
    }, [bolData, setBol]);

    useEffect(() => {
    }, [pageValue]);

    if (loading) return <Loader />;
    if (error) {
        return <p>Error :(</p>;
    }

    const bolConfig = [
        {
            label: 'ID',
            render: (bol) => bol.id,
            headerClassName: 'pr-8 w-12',
            rowCellClassName: '',
        },
        {
            label: 'Customer',
            render: (bol) => bol.customer[0]['businessName'],
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Created At',
            render: (bol) => bol.createdAt,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Api Mode',
            render: (bol) => bol.apiMode,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'Shipment Terms',
            render: (bol) => bol.shipmentTerms,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'Service Type',
            render: (bol) => bol.serviceType,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        }
    ];

    const addressConfig = [
        {
            label: 'Company Name',
            render: (bolAddress) => bolAddress.companyName,
            headerClassName: 'pr-8',
            rowCellClassName: '',
            width: '30%',
        },
        {
            label: 'Address',
            render: (bolAddress) => bolAddress.address1 + ' ' + bolAddress.address2,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'City',
            render: (bolAddress) => bolAddress.city,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'State',
            render: (bolAddress) => bolAddress.state,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'Zip Code',
            render: (bolAddress) => bolAddress.zipCode,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        }
    ];

    const bolItemConfig = [
        {
            label: 'Description',
            render: (bolItem) => bolItem.description,
            headerClassName: 'pr-8',
            rowCellClassName: '',
            width: '30%',
        },
        {
            label: 'Shipping Class',
            render: (bolItem) => bolItem.shipmentClass,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'Weight',
            render: (bolItem) => bolItem.weight,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'Length',
            render: (bolItem) => bolItem.length,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'Width',
            render: (bolItem) => bolItem.width,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'Height',
            render: (bolItem) => bolItem.height,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'Quantity',
            render: (bolItem) => bolItem.quantity,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        }
        // {
        //     label: 'Charges',
        //     render: (bolItem) => bolItem.charges,
        //     headerClassName: 'pl-4',
        //     rowCellClassName: '',
        // }
    ];

    const bolShipmentReferenceConfig = [
        {
            label: 'Description',
            render: (bolShipmentReference) => bolShipmentReference.description,
            headerClassName: 'pr-8',
            rowCellClassName: '',
            width: '30%',
        },
        {
            label: 'Reference Number',
            render: (bolShipmentReference) => bolShipmentReference.referenceNumber,
            headerClassName: 'pr-8',
            rowCellClassName: '',
            width: '30%',
        },
        {
            label: 'PO Number',
            render: (bolShipmentReference) => bolShipmentReference.poNumber,
            headerClassName: 'pr-8',
            rowCellClassName: '',
            width: '30%',
        },
        {
            label: 'Reference Type',
            render: (bolShipmentReference) => bolShipmentReference.referenceType,
            headerClassName: 'pr-8',
            rowCellClassName: '',
            width: '30%',
        },
    ];

    const keyFun = (bol) => {
        return bol.id
    };

    // const generateTestBol = async () => {
    //     console.log('bol data', bolData)
    //     setApiResponse('');
    //     // setIsLoading(true);
    //     console.log('token ', token)
    //     if (token === '' || token === null) {
    //         return setErrors({
    //             message: 'Token Credentials are invalid'
    //         });
    //     }

    //     let addresses = bolData.bolAddresses.map((address) => {
    //         return {
    //             "address_type": address.addressType.toLowerCase(),
    //             "company_name": address.companyName,
    //             "address_1": address.address1,
    //             "address_2": address.address2,
    //             "city": address.city,
    //             "state": address.state,
    //             "zip_code": address.zipCode,
    //             "contact_number": ""
    //         };
    //     });

    //     let bol_items = bolData.bolItems.map((item) => {
    //         return {
    //             "description": item.description,
    //             "quantity": item.quantity,
    //             "weight": item.weight,
    //             "pallets": item.pallets,
    //             "shipment_class": item.shipmentClass,
    //             "service_type": item.serviceType,
    //             "package_type": item.packageType,
    //             "charges": item.charges
    //         };
    //     });

    //     let bol_shipment_references = bolData.bolShipmentReferences.map((reference) => {
    //         return {
    //             "reference_type": reference.referenceType,
    //             "reference_number": reference.referenceNumber,
    //             "po_number": reference.poNumber
    //         };
    //     });

    //     let params = {
    //         "shipment_id": bolData.bol.shipmentId,
    //         "shipment_date": bolData.bol.shipmentDate,
    //         "driver_name": bolData.bol.driverName,
    //         "truck_number": bolData.bol.truckNumber,
    //         "shipment_terms": bolData.bol.shipmentTerms,
    //         "api_mode": bolData.bol.apiMode,
    //         "addresses": addresses,
    //         "bol_items": bol_items,
    //         "shipment_references": bol_shipment_references,
    //         "shipment_accessorials": {
    //             "pallet_jack_lift_gat": "12",
    //             "attempt": "22",
    //             "detention": "12",
    //             "over_size_charge": "21",
    //             "storage": "23",
    //             "high_value_shipment": "11",
    //             "fuel_surcharge": "15",
    //             "e_california_compliance_surcharge": "12"
    //         }
    //     };

    //     console.log('p', params, 'token', `JWT ${token}`);
    //     console.log('base_api_url', BACKEND_API_URL);
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `JWT ${token}`
    //         },
    //         body: JSON.stringify(params)
    //     };
    //     const response = await fetch(`${BACKEND_API_URL}rest/test/bol/`, requestOptions);
    //     const api_response = await response.json();
    //     setApiResponse(JSON.stringify(api_response));
    //     console.log('test res', api_response);
    //     console.log('setApiResponse', apiResponse);
    //     // setIsLoading(false);

    // }

    // const getAccessToken = async () => {
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             'username': 'wasifraja',
    //             'password': 'wasif123'
    //         })
    //     };
    //     const response = await fetch(`${BACKEND_API_URL}auth/jwt/create`, requestOptions);
    //     const data = await response.json();
    //     if (!data.access) {
    //         setApiResponse(JSON.stringify(data));
    //         setIsLoading(false);
    //     }
    //     return data.access;
    // }


    const handleCancel = () => {
        navigate("/admin/bols");
    }

    return (
        <div className="content px-3 lg:px-8">
            <div className="content-bar flex justify-between mt-6">
                <PageHeading>BOL DETAIL FOR "{id}"</PageHeading>
                {/* <div className="search-bar">
                    <div className="search-field-container flex p-2 pl-4 bg-white border border-gray-300 shadow-sm rounded-md">
                        <img className="pr-2" src={searchIcon} alt="Search Icon" />
                        <input type="text" className="pl-1" placeholder="Customer code or Business name" />
                    </div>
                </div> */}
            </div>
            <div className="mt-8 w-full">
                <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                    <div className="absolute inset-0"></div>
                    <div className="relative rounded-xl overflow-auto">
                        <div className="shadow-sm overflow-hidden mt-7">
                            <div className="w-full">
                                <SortableTable tableLayout="row" data={data} config={bolConfig} keyFun={keyFun} />
                            </div>
                        </div>
                    </div>
                    <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                </div>
            </div>
            <div className='flex gap-5'>

                <div className="mt-8 w-full mb-8">
                    <div className="mt-8 w-1/2 mb-3">
                        <PageHeading>Bol Items</PageHeading>
                    </div>
                    <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                        <div className="absolute inset-0"></div>
                        <div className="relative rounded-xl overflow-auto">
                            <div className="shadow-sm overflow-hidden mt-7">
                                <div className="w-full">
                                    <SortableTable tableLayout="row" data={bolItems} config={bolItemConfig} keyFun={keyFun} />
                                </div>
                            </div>
                        </div>
                        <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                    </div>
                </div>


            </div>
            <div className='flex column-4 gap-5'>
                <div className="mt-8 w-full">
                    <div className="mt-8 mb-3">
                        <PageHeading>Shipper Address</PageHeading>
                    </div>
                    <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                        <div className="absolute inset-0"></div>
                        <div className="relative rounded-xl overflow-auto">
                            <div className="shadow-sm overflow-hidden mt-7">
                                <div className="w-full">
                                    <SortableTable tableLayout="column" data={bolShipperAddress} config={addressConfig} keyFun={keyFun} />
                                </div>
                            </div>
                        </div>
                        <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                    </div>
                </div>

                <div className="mt-8 w-full">
                    <div className="mt-8 mb-3">
                        <PageHeading>Consignee Address</PageHeading>
                    </div>
                    <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                        <div className="absolute inset-0"></div>
                        <div className="relative rounded-xl overflow-auto">
                            <div className="shadow-sm overflow-hidden mt-7">
                                <div className="w-full">
                                    <SortableTable tableLayout="column" data={bolConsigneeAddress} config={addressConfig} keyFun={keyFun} />
                                </div>
                            </div>
                        </div>
                        <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                    </div>
                </div>

                <div className="mt-8 w-full">
                    <div className="mt-8 mb-3">
                        <PageHeading>Billing Address</PageHeading>
                    </div>
                    <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                        <div className="absolute inset-0"></div>
                        <div className="relative rounded-xl overflow-auto">
                            <div className="shadow-sm overflow-hidden mt-7">
                                <div className="w-full">
                                    <SortableTable tableLayout="column" data={bolBillingAddress} config={addressConfig} keyFun={keyFun} />
                                </div>
                            </div>
                        </div>
                        <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                    </div>
                </div>

                <div className="mt-8 w-full mb-8">
                    <div className="mt-8 w-full mb-3">
                        <PageHeading>Bol Shipment Reference</PageHeading>
                    </div>
                    <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                        <div className="absolute inset-0"></div>
                        <div className="relative rounded-xl overflow-auto">
                            <div className="shadow-sm overflow-hidden mt-7">
                                <div className="w-full">
                                    <SortableTable tableLayout="column" data={bolShipmentReferences} config={bolShipmentReferenceConfig} keyFun={keyFun} />
                                </div>
                            </div>
                        </div>
                        <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl">
                        </div>
                    </div>
                </div>

            </div>

            <div className='w-full mt-5 flex items-center justify-end'>
                <button onClick={handleCancel} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500">Cancel</button>
            </div>

            {apiResponse &&
                <div className="mt-8 w-full mb-8 py-5">
                    <h5 className='text-xl font-medium'>Output:</h5>
                    <div className="bg-slate-300 p-5 rounded-xl">
                        <pre>{apiResponse ? apiResponse : ''}</pre>
                    </div>
                </div>
            }
            <div className="mt-8 w-full mb-8 flex items-center justify-start gap-3">
                {/* {!isloading &&
                    <button onClick={generateTestBol} type="button"
                        className="inline-flex items-center px-4 py-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out bg-blue-500 rounded-md shadow hover:bg-blue-600">
                        Generate Bol
                    </button>
                } */}
                {/* {isloading &&
                    <button type="button"
                        className="inline-flex items-center px-4 py-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out bg-blue-500 rounded-md shadow cursor-not-allowed hover:bg-blue-400"
                        disabled>
                        <svg className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                            </path>
                        </svg>
                        Loading...
                    </button>
                } */}
            </div>

        </div>
    );
}

export default BolDetailPage;