function Footer() {
    return (
        <footer className="w-full lg:h-[21rem] lg:overflow-hidden">
            <div className="flex flex-col lg:flex-row items-start justify-between ">
                <div className="hidden lg:block">
                    <svg className="hidden lg:block" xmlns="http://www.w3.org/2000/svg" width="281" height="602"
                        viewBox="0 0 281 602" fill="none">
                        <path opacity="0.2"
                            d="M208.203 -2.69873e-05L208.203 602M132.241 -3.36281e-05L132.241 602M56.2788 -4.0269e-05L56.2787 602M-19.6834 -4.69098e-05L-19.6834 602M-95.6456 -5.35506e-05L-95.6456 602M-171.608 -6.01914e-05L-171.608 602M-247.57 -6.68323e-05L-247.57 602M281 529.203L-321 529.203M281 453.241L-321 453.241M281 377.279L-321 377.279M281 301.316L-321 301.316M281 225.354L-321 225.354M281 149.392L-321 149.392M281 73.4299L-321 73.4298"
                            stroke="url(#paint0_radial_23_28)" strokeWidth="2" />
                        <defs>
                            <radialGradient id="paint0_radial_23_28" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(-20 301) rotate(-90) scale(301)">
                                <stop stopColor="white" />
                                <stop offset="1" stopColor="white" stopOpacity="0" />
                            </radialGradient>
                        </defs>
                    </svg>
                </div>
                <div>
                    <div className="sm:px-8 md:px-16 my-7  px-6">
                        <div className="flex-column items-center justify-center py-9 relative">
                            <div className="sm:px-8 md:px-16 flex flex-row items-center justify-center  relative z-20">
                                <div className="md:pb-[52px]">
                                    <p className="text-white text-center pt-4 pb-6 md:pr-12">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    </p>
                                    <p className="text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                        leo
                                        nisl,
                                        tristique mollis
                                        venenatis ut, tristique sed
                                        mi. Ut lorem felis, auctor eget congue sed, sagittis semper neque.o</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col lg:flex-row items-center justify-center my-6">
                        <ul className="flex flex-col md:flex-row justify-center lg:justify-start items-center ">
                            <li className="ml-7"><a className="text-sm" href="/">BOLERM@2023</a></li>
                            <li className="ml-7"><a className="text-sm" href="/">Privacy & Legal</a></li>
                            <li className="ml-7"><a className="text-sm" href="/">Vehicle Recalls</a></li>
                            <li className="ml-7"><a className="text-sm" href="/">Contract</a></li>
                            <li className="ml-7"><a className="text-sm" href="/">News</a></li>
                            <li className="ml-7"><a className="text-sm" href="/">Get Updates</a></li>
                            <li className="ml-7"><a className="text-sm" href="/">Location</a></li>
                        </ul>
                    </div>
                </div>
                <div className="hidden lg:block">
                    <svg className="hidden lg:block" xmlns="http://www.w3.org/2000/svg" width="359" height="602"
                        viewBox="0 0 359 602" fill="none">
                        <path opacity="0.2"
                            d="M529.203 -2.69873e-05L529.203 602M453.241 -3.36281e-05L453.241 602M377.279 -4.0269e-05L377.279 602M301.317 -4.69098e-05L301.317 602M225.354 -5.35506e-05L225.354 602M149.392 -6.01914e-05L149.392 602M73.43 -6.68323e-05L73.43 602M602 529.203L3.78859e-06 529.203M602 453.241L1.04294e-05 453.241M602 377.279L1.70702e-05 377.279M602 301.316L2.37111e-05 301.316M602 225.354L3.03519e-05 225.354M602 149.392L7.56291e-05 149.392M602 73.4299L8.22699e-05 73.4298"
                            stroke="url(#paint0_radial_42_22269)" strokeWidth="2" />
                        <defs>
                            <radialGradient id="paint0_radial_42_22269" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(301 301) rotate(-90) scale(301)">
                                <stop stopColor="white" />
                                <stop offset="1" stopColor="white" stopOpacity="0" />
                            </radialGradient>
                        </defs>
                    </svg>
                </div>
            </div>
        </footer>
    );
}

export default Footer;