import React, { useState, useContext, useEffect } from 'react';
// import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import GraphQuery from '../graphql/queries/Query';
// import PhoneInput from 'react-phone-input-2'

const OtherLocationModel = ({ pickupId, selectedLocation = {}, showModal, closeModal, updateSelectedLocation }) => {
    const { token } = useContext(AuthContext);
    const [locationOptions, setLocationOptions] = useState([]);
    const [formData, setFormData] = useState({
        locationId: '',
    });

    const [errors, setErrors] = useState([]);

    const { responseData: locationData, dataCount: locationCount, locationLoading, locationError } = GraphQuery({ name: 'Locations', single: 'Location' }, {
        skip: 0,
        search: '',
        sortBy: 'title',
        order: 'asc',
    }, 'id,title', 'locationCount');

    useEffect(() => {
        console.log('locationData', locationData)
        if (locationData) {
            setLocationOptions(locationData);
        }
    }, [locationData]);

    useEffect(() => {
        if (selectedLocation) {
            setFormData({
                ...formData,
                locationId: selectedLocation[pickupId],
            })
        }
    }, [selectedLocation])
    // if (loading) return <p>Loading ... please wait</p>
    const locationHandle = (e, property) => {
        setFormData({
            ...formData,
            [property]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        console.log(formData);
        if (formData.locationId === '' || formData.locationId === null) {
            return setErrors({ locationId: 'Please select a location to proceed' });
        }
        updateSelectedLocation(formData.locationId);
        console.log(formData.locationId);
        // if (window.confirm("Are you sure you want to send report?")) {
        //     alert("Report sent successfully.");
        // }
    }


    if (showModal) {
        return (
            <div >
                <div id="crud-modal" tabIndex="-1" aria-hidden="true" className="bg-black/40 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full d-flex">
                    <div className="relative p-4 w-full max-w-xl top-[3%] left-[35%] mb-20 d-flex justify-center">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 w-max-3xl">
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    Choose Other Location
                                </h3>
                                <button onClick={() => closeModal()} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <form className="">
                                <div className='row'>
                                    <div className='md:w-full'>
                                        <div className="bg-white rounded px-8 pt-6 pb-8 flex flex-col my-2">
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-[14px] font-bold mb-2" htmlFor="pickupId">
                                                        Pickup Request ID : {pickupId}
                                                    </label>
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="location_zone">
                                                        Location
                                                    </label>
                                                    <div className="relative">
                                                        <select value={formData.locationId} onChange={(e) => locationHandle(e, 'locationId')} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="location_zone">
                                                            <option value="">Select Location</option>
                                                            {
                                                                locationOptions.map((location) => {
                                                                    return (
                                                                        <option value={location.id} key={location.id}>{location.title}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div>
                                                    </div>
                                                    {errors && errors.locationId && <p className="text-red-500 text-xs italic">{errors.locationId}</p>}
                                                </div>
                                            </div>
                                            <div className="flex justify-end">
                                                <button type="button" onClick={() => closeModal()} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500">Cancel</button>
                                                <button type="button" onClick={() => handleSubmit()} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ div >
        );
    }
    else
        return false;

}

export default OtherLocationModel;