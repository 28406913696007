import React, { useState, useEffect } from 'react';

import SortableTable from "../../components/SortableTable";
import MyGoogleMap from "../../components/MyGoogleMap";
import { useParams } from 'react-router-dom'

import { gql, useQuery } from '@apollo/client';

import moment from 'moment';
import Header from '../layouts/PublicSite/Header';
import Footer from '../layouts/PublicSite/Footer';


function TrackingPublicPage() {

    const { id } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    // const { token } = useContext(AuthContext);

    const pageValue = urlParams.get('page');
    const GET_TRACKING_INFO = gql`
    query GetTrackings($id: String) {
        trackings(id: $id) {
            id,
            createdAt,
                trackingNumber,
                latitude,
                longitude,
                trackingDateTime,
                status,
                account {
                    id,
                    companyName,
                },
            }
        }
    `;

    const { loading, error, data: trackingData } = useQuery(GET_TRACKING_INFO, {
        variables: {
            id: id,
        },
    });

    const [data, setData] = useState([]);

    useEffect(() => {
        if (trackingData) {
            if (trackingData.trackings) {
                setData(trackingData.trackings);
            }

        }
    }, [trackingData, setData]);

    useEffect(() => {
    }, [pageValue]);

    if (loading) return <p>Loading...</p>;
    if (error) {
        return <p>Error :(</p>;
    }

    const trackingConfig = [
        {
            label: 'ID',
            render: (tracking) => tracking.id,
            headerClassName: 'pr-8 w-12',
            rowCellClassName: '',
        },
        {
            label: 'Tracking Number',
            render: (tracking) => tracking.trackingNumber,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Latitude',
            render: (tracking) => tracking.latitude,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Longitude',
            render: (tracking) => tracking.longitude,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Status',
            render: (tracking) => tracking.status,
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
        {
            label: 'Tracking Date Time',
            render: (tracking) => moment(tracking.trackingDateTime).format('DD-MM-Y hh:m:s'),
            headerClassName: 'pl-4',
            rowCellClassName: '',
        }
    ];

    const keyFun = (tracking) => {
        return tracking.id
    }

    return (
        <>
            <div className="hero bg-cover bg-center bg-no-repeat bg-color-primary text-white">
                <Header theme="dark" />
            </div>

            <div className=" wrapper xl:max-w-[2000px] 2xl:max-w-[2500px] mx-auto">
                <div className="content px-4 mt-[70px]">
                    <div className="content-bar flex justify-between text-white text-center">
                        <h4 className="text-2xl text-white leading-tight font-bold text-center">Public Tracking Page</h4>
                    </div>
                    <div className="mt-8 w-full">
                        <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                            <div className="absolute inset-0"></div>
                            <div className="relative rounded-xl overflow-auto">
                                <div className="shadow-sm overflow-hidden mt-7">
                                    <div className="w-full">
                                        <SortableTable tableLayout="row" data={data} config={trackingConfig} keyFun={keyFun} />
                                    </div>
                                </div>
                            </div>
                            <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                        </div>
                    </div>
                    <div>
                        <div className='mt-[70px]'>
                            <MyGoogleMap trackingData={data} />
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    );
}

export default TrackingPublicPage;
