import React, { useState, useContext, useEffect } from 'react';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';

import { AuthContext } from '../context/authContext';
import PhoneInput from 'react-phone-input-2'

const CreateCustomerModal = ({ showModal, closeModal, selectCreatedCustomer }) => {
    const { token } = useContext(AuthContext);
    const [stateOptions, setStateOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [billingCityOptions, setBillingCityOptions] = useState([]);
    const [formData, setFormData] = useState({
        businessName: '',
        firstName: '',
        lastName: '',
        username: '',
        contactPersonEmail: '',
        password: '',
        contactPersonPhoneNumber: '',
        contactPersonMobileNumber: '',
        websiteUrl: '',
        businessAddress1: '',
        businessAddress2: '',
        businessAddress3: '',
        state: '',
        city: '',
        zipCode: '',
        billingAddress1: '',
        billingAddress2: '',
        billingAddress3: '',
        billingState: '',
        billingCity: '',
        billingZipCode: '',
    });
    // const data = useState([]);
    const [errors, setErrors] = useState([]);
    const [disableCities, setDisableCities] = useState(true);

    const CREATE_CUSTOMER = gql`
        mutation($customerNew: CreateCustomerInput!) {
            createCustomer(customerData: $customerNew) {
                customer {
                    id,
                    businessName,
                    contactPersonFullName
                    contactPersonEmail
                    websiteUrl
                    businessAddress1
                    businessAddress2
                    businessAddress3
                    state
                    city
                    zipCode
                    billingAddress1
                    billingAddress2
                    billingAddress3
                    billingState
                    billingCity
                    billingZipCode
                }
            }
        }
    `;

    const GET_US_STATES = gql`
        query GetUSStates($first: Int, $skip: Int, $search: String, $sortBy: USStateSortInput) {
            usStates(first: $first, skip: $skip, search: $search, sortBy: $sortBy) {
                id
                name,
                code,
            },
        }
    `;

    const GET_CITIES = gql`
        query GetCities($stateId: ID,$first: Int, $skip: Int, $search: String, $sortBy: CitySortInput) {
            cities(stateId: $stateId, first: $first, skip: $skip, search: $search, sortBy: $sortBy) {
                id
                name,
                state {
                    name
                }
            },
        }
    `;

    const { data: usStateData } = useQuery(GET_US_STATES, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: {
            search: '',
            sortBy: {
                field: 'name',
                direction: 'asc',
            },
        },
    });

    const [fetchCities, { data: cityData }] = useLazyQuery(GET_CITIES, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
    });

    const [fetchBillingCities, { data: billingCityData }] = useLazyQuery(GET_CITIES, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
    });

    const [createCustomer, { data: createdCustomerData, loading }] = useMutation(CREATE_CUSTOMER, {
        onError: (error) => {
            try {
                const parsedError = JSON.parse(error.message);
                setErrors(parsedError);
            } catch (parseError) {
                setErrors('An error occurred while processing your request.');
            }

        },
        // onCompleted: (data) => {
        //     console.log(data, createdCustomerData.createCustomer)
        //     selectCreatedCustomer(createdCustomerData.createCustomer.customer);
        //     closeModal();
        // }
    })

    // useEffect(() => {
    //     if (customerData && customerData.customer && customerData.customer.state) {
    //         const stateId = customerData.customer.state;
    //         const billingStateId = customerData.customer.billingState;
    //         console.log('Fetching cities with stateId:', stateId, billingStateId);
    //         fetchCities({
    //             variables: { stateId },
    //         });
    //         fetchBillingCities({
    //             variables: { billingStateId },
    //         });
    //     }
    // }, [formData, fetchCities, fetchBillingCities]);

    useEffect(() => {
        if (billingCityData && billingCityData.cities) {
            setBillingCityOptions(billingCityData.cities);
        }
    }, [billingCityData]);

    useEffect(() => {
        fetchCities({
            variables: {
                stateId: formData.state,
                search: '',
                sortBy: {
                    field: 'name',
                    direction: 'asc',
                },
            },
        });
    }, [formData?.state]);

    useEffect(() => {
        fetchBillingCities({
            variables: {
                stateId: formData.billingState,
                search: '',
                sortBy: {
                    field: 'name',
                    direction: 'asc',
                },
            },
        });
    }, [formData?.billingState]);

    useEffect(() => {
        if (createdCustomerData && createdCustomerData.createCustomer) {
            selectCreatedCustomer(createdCustomerData.createCustomer.customer);
            closeModal();
        }
    }, [createdCustomerData])


    // if (loading) return <p>Loading ... please wait</p>
    const handleChange = (property, e) => {
        setFormData({
            ...formData,
            [property]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        console.log("FormData => ", formData);
        e.preventDefault();
        createCustomer({
            context: {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            },
            variables: {
                customerNew: {
                    businessName: formData.businessName,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    username: formData.username,
                    contactPersonEmail: formData.contactPersonEmail,
                    password: formData.password,
                    contactPersonPhoneNumber: formData.contactPersonPhoneNumber,
                    contactPersonMobileNumber: formData.contactPersonMobileNumber,
                    websiteUrl: formData.websiteUrl,
                    businessAddress1: formData.businessAddress1,
                    businessAddress2: formData.businessAddress2,
                    businessAddress3: formData.businessAddress3,
                    state: formData.state,
                    city: formData.city,
                    zipCode: formData.zipCode,
                    billingAddress1: formData.billingAddress1,
                    billingAddress2: formData.billingAddress2,
                    billingAddress3: formData.billingAddress3,
                    billingState: formData.billingState,
                    billingCity: formData.billingCity,
                    billingZipCode: formData.billingZipCode ? formData.billingZipCode : '',
                }
            }
        });
    }

    useEffect(() => {
        if (usStateData && usStateData.usStates) {
            const states = usStateData.usStates;
            setStateOptions(states);
        }
    }, [usStateData, setStateOptions]);

    useEffect(() => {
        if (cityData && cityData.cities) {
            const cities = cityData.cities;
            setCityOptions(cities);
        }
    }, [cityData, setCityOptions]);

    if (loading) return <p>Loading...</p>;

    const stateHandle = (e, property) => {
        setFormData({
            ...formData,
            [property]: e.target.value,
        });
        setDisableCities(false);
        // fetchCities({
        //     variables: {
        //         stateId: e.target.value,
        //         search: '',
        //         sortBy: {
        //             field: 'name',
        //             direction: 'asc',
        //         },
        //     },
        // });
    };

    const cityHandle = (e, property) => {
        setFormData({
            ...formData,
            [property]: e.target.value,
        });

    };

    const mobileNumberHandle = (mobileNumber) => {
        setFormData({
            ...formData,
            contactPersonMobileNumber: mobileNumber,
        })
    }

    if (showModal) {
        return (
            <div >
                <div id="crud-modal" tabIndex="-1" aria-hidden="true" className="bg-black/40 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full d-flex">
                    <div className="relative p-4 w-full max-w-3xl top-[3%] left-[35%] mb-20 d-flex justify-center">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 w-max-3xl">
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    Create New Customer
                                </h3>
                                <button onClick={() => closeModal()} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <form className="">
                                <div className='row'>
                                    <div className='md:w-full'>
                                        <div className="bg-white rounded px-8 pt-6 pb-8 flex flex-col my-2">
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-1/2 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="contact_person_first_name">
                                                        Contact Person First Name
                                                    </label>
                                                    <input value={formData.firstName} onChange={(value) => handleChange('firstName', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="contact_person_first_name" type="text" autoComplete="on" placeholder="First Name" required />
                                                    {errors && errors.firstName && <p className="text-red-500 text-xs italic">{errors.firstName}</p>}
                                                </div>
                                                <div className="md:w-1/2 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="contact_person_last_name">
                                                        Contact Person Last Name
                                                    </label>
                                                    <input value={formData.lastName} onChange={(value) => handleChange('lastName', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4" id="contact_person_last_name" type="text" autoComplete="on" placeholder="Last Name" required />
                                                    {errors && errors.firstName && <p className="text-red-500 text-xs italic">{errors.firstName}</p>}
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="business_name">
                                                        Business Name
                                                    </label>
                                                    <input value={formData.businessName} onChange={(value) => handleChange('businessName', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="business_name" type="text" autoComplete="on" placeholder="Business Name" required />
                                                    {errors && errors.businessName && <p className="text-red-500 text-xs italic">{errors.businessName}</p>}
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-1/2 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_username">
                                                        Username
                                                    </label>
                                                    <input value={formData.username} onChange={(value) => handleChange('username', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="customer_username" type="text" autoComplete="on" placeholder="Username" required />
                                                    {errors && errors.username && <p className="text-red-500 text-xs italic">{errors.username}</p>}
                                                </div>
                                                <div className="md:w-1/2 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_email">
                                                        Email
                                                    </label>
                                                    <input value={formData.contactPersonEmail} onChange={(value) => handleChange('contactPersonEmail', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4" id="customer_email" type="email" autoComplete="on" placeholder="Email" required />
                                                    {errors && errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-1/2 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_password">
                                                        Password
                                                    </label>
                                                    <input value={formData.password} onChange={(value) => handleChange('password', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="customer_password" type="password" autoComplete="on" placeholder="Password" required />
                                                    {errors && errors.password && <p className="text-red-500 text-xs italic">{errors.password}</p>}
                                                </div>
                                                <div className="md:w-1/2 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_phone_number">
                                                        Phone Number
                                                    </label>
                                                    <input value={formData.contactPersonPhoneNumber} onChange={(value) => handleChange('contactPersonPhoneNumber', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4" id="customer_phone_number" type="text" autoComplete="on" placeholder="Phone Number" required />
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-3">
                                                <div className="md:w-1/2 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="mobile_number">
                                                        Mobile Number
                                                    </label>
                                                    <PhoneInput
                                                        className="border-0"
                                                        country={'us'}
                                                        value={formData.contactPersonMobileNumber}
                                                        onChange={mobileNumberHandle}
                                                    />
                                                    {errors && errors.contactPersonMobileNumber && <p className="text-red-500 text-xs italic">{errors.contactPersonMobileNumber}</p>}
                                                </div>
                                                <div className="md:w-1/2 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_webiste_url">
                                                        Website URL
                                                    </label>
                                                    <input value={formData.websiteUrl} onChange={(value) => handleChange('websiteUrl', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4" id="customer_webiste_url" type="text" autoComplete="on" placeholder="https://www.example.com" required />
                                                </div>
                                            </div>
                                            <h4 className="text-xl mb-3 font-bold">Consignee Address</h4>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_address_1">
                                                        Address Line 1
                                                    </label>
                                                    <input value={formData.businessAddress1} onChange={(value) => handleChange('businessAddress1', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="customer_address_1" type="text" autoComplete="on" placeholder="Street Address 1" required />
                                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                                    {errors && errors.businessAddress1 && <p className="text-red-500 text-xs italic">{errors.businessAddress1}</p>}
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_address_2">
                                                        Address Line 2
                                                    </label>
                                                    <input value={formData.businessAddress2} onChange={(value) => handleChange('businessAddress2', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="customer_address_2" type="text" autoComplete="on" placeholder="Street Address 2" required />
                                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_address_3">
                                                        Address Line 3
                                                    </label>
                                                    <input value={formData.businessAddress3} onChange={(value) => handleChange('businessAddress3', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="customer_address_3" type="text" autoComplete="on" placeholder="Street Address 3" required />
                                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-1/3 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_state">
                                                        State
                                                    </label>
                                                    <div className="relative">
                                                        <select value={formData.state} onChange={(e) => stateHandle(e, 'state')} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="customer_state">
                                                            <option value="null">Select State</option>
                                                            {
                                                                stateOptions.map((state, index) => {
                                                                    return (
                                                                        <option key={state.code} value={state.code}>{state.name}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div>

                                                    </div>
                                                    {errors && errors.state && <p className="text-red-500 text-xs italic">{errors.state}</p>}
                                                </div>
                                                <div className="md:w-1/3 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_city">
                                                        City
                                                    </label>
                                                    <div className="relative">
                                                        <select value={formData.city} disabled={disableCities} onChange={(e) => cityHandle(e, 'city')} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="customer_city">
                                                            <option value="null">Select City</option>
                                                            {
                                                                cityOptions.map((city) => {
                                                                    return (
                                                                        <option value={city.name} key={city.id}>{city.name}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div>

                                                    </div>
                                                    {errors && errors.city && <p className="text-red-500 text-xs italic">{errors.city}</p>}
                                                </div>
                                                <div className="md:w-1/3 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_zipCode">
                                                        Zip Code
                                                    </label>
                                                    <input value={formData.zipCode} onChange={(value) => handleChange('zipCode', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="customer_zipCode" type="text" autoComplete="on" placeholder="Zip Code" required />
                                                    {errors && errors.zipCode && <p className="text-red-500 text-xs italic">{errors.zipCode}</p>}
                                                </div>
                                            </div>
                                            <h4 className="text-xl mb-3 font-bold">Billing Address</h4>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_billing_address_1">
                                                        Address Line 1
                                                    </label>
                                                    <input value={formData.billingAddress1} onChange={(value) => handleChange('billingAddress1', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="customer_billing_address_1" type="text" autoComplete="on" placeholder="Street Address 1" required />
                                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_billing_address_2">
                                                        Address Line 2
                                                    </label>
                                                    <input value={formData.billingAddress2} onChange={(value) => handleChange('billingAddress2', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="customer_billing_address_2" type="text" autoComplete="on" placeholder="Street Address 2" required />
                                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_billing_address_3">
                                                        Address Line 3
                                                    </label>
                                                    <input value={formData.billingAddress3} onChange={(value) => handleChange('billingAddress3', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="customer_billing_address_3" type="text" autoComplete="on" placeholder="Street Address 3" required />
                                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-1/3 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_billing_state">
                                                        State
                                                    </label>
                                                    <div className="relative mb-3">
                                                        <select value={formData.billingState} onChange={(e) => stateHandle(e, 'billingState')} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="customer_billing_state">
                                                            <option value="null">Select State</option>
                                                            {
                                                                stateOptions.map((state, index) => {
                                                                    return (
                                                                        <option key={state.code} value={state.code}>{state.name}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div>

                                                    </div>
                                                    {errors && errors.billingState && <p className="text-red-500 text-xs italic">{errors.billingState}</p>}
                                                </div>
                                                <div className="md:w-1/3 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_billing_city">
                                                        City
                                                    </label>
                                                    <div className="relative mb-3">
                                                        <select value={formData.billingCity} disabled={disableCities} onChange={(e) => cityHandle(e, 'billingCity')} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="customer_billing_city">
                                                            <option value="null">Select City</option>
                                                            {
                                                                billingCityOptions.map((city) => {
                                                                    return (
                                                                        <option value={city.name} key={city.id}>{city.name}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div>

                                                    </div>
                                                    {errors && errors.billingCity && <p className="text-red-500 text-xs italic">{errors.billingCity}</p>}
                                                </div>
                                                <div className="md:w-1/3 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_billing_zipCode">
                                                        Zip Code
                                                    </label>
                                                    <input value={formData.billingZipCode} onChange={(value) => handleChange('billingZipCode', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="customer_billing_zipCode" type="text" autoComplete="on" placeholder="Zip Code" required />
                                                    {errors && errors.billingZipCode && <p className="text-red-500 text-xs italic">{errors.billingZipCode}</p>}
                                                </div>
                                            </div>
                                            <div className="flex justify-end">
                                                <button type="button" onClick={() => closeModal()} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500">Cancel</button>
                                                <button type="button" onClick={handleSubmit} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ div >
        );
    }
    else
        return false;

}

export default CreateCustomerModal;