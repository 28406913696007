import React, { useState, useContext, useEffect } from 'react';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { AuthContext } from '../../context/authContext';

const UPDATE_COLUMN_SETTINGS = gql`
  mutation UpdateUserTableColumnSetting($userTableColumnSettingData: UpdateUserTableColumnSettingInput!) {
    updateUserTableColumnSetting(userTableColumnSettingData: $userTableColumnSettingData) {
      userTableColumnSetting {
        tableName,
        columnSettings
      }
    }
  }
`;
const EditTableColumnSettingModal = ({ columns, tableName, updateColumns }) => {
    const { token } = useContext(AuthContext);
    const [tableColumns, setTableColumns] = useState([]);
    const [showTableSettingModal, setShowTableSettingModal] = useState(false)
    // const TableColumnSettings = ({ tableName, userId }) => {
    // const { data, loading, error } = useQuery(GET_COLUMN_SETTINGS, {
    //     variables: { tableName, userId }
    // });


    useEffect(() => {
        if (columns) {
            setTableColumns(columns);
        }
    }, [columns]);

    // if (loading) return <p>Loading...</p>;
    // if (error) return <p>Error: {error.message}</p>;

    // Handle visibility toggle

    const [updateSettings, { data: updateSettingsData, loading: updateSettingLoading, error: updateSettingError }] = useMutation(UPDATE_COLUMN_SETTINGS);


    useEffect(() => {
        if (updateSettingsData && updateSettingsData.updateUserTableColumnSetting) {
            // selectCreatedCustomer(createdCustomerData.createCustomer.customer);
            let columnSettings = JSON.parse(updateSettingsData?.updateUserTableColumnSetting?.userTableColumnSetting?.columnSettings)
            updateColumns(columnSettings.columns)
            setShowTableSettingModal(false);
        }
    }, [updateSettingsData])

    const toggleVisibility = (index) => {
        const updatedColumns = [...columns];
        updatedColumns[index].visible = !updatedColumns[index].visible;
        setTableColumns(updatedColumns);
        updateColumns(updatedColumns);
    };

    // Handle drag-and-drop reordering
    const onDragEnd = (result) => {
        if (!result.destination) return;

        const updatedColumns = [...columns];
        const [reorderedItem] = updatedColumns.splice(result.source.index, 1);
        updatedColumns.splice(result.destination.index, 0, reorderedItem);
        setTableColumns(updatedColumns);
    };

    // Save settings
    const saveSettings = () => {
        const columnSettings = JSON.stringify({ columns });
        // const columnSettings = { columns };
        let userTableColumnSettingData = {
            tableName: tableName,
            columnSettings: columnSettings
        }
        updateSettings({
            context: {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            },
            variables: { userTableColumnSettingData }
        });
    };

    const editTableColumnSettingModalHandle = (status) => {
        setShowTableSettingModal(status);
    }

    // if (showModal) {
    return (
        <div>

            <div>
                <div>
                    <button onClick={() => editTableColumnSettingModalHandle(true)} type="button" className={`mt-3 sm:mt-0 rounded-md bg-white-600 text-dark border border-black'}bg-indigo-600 px-3 py-2 text-sm font-semibold shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center`}>
                        <span className='w-[20px] h-[20px] rounded-[50px]  flex items-center justify-center'>
                            <svg width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" /></svg>
                        </span>
                        <span className="ml-2">Edit Columns</span>
                    </button>
                </div>
                {showTableSettingModal === true ? <div>
                    <div id="crud-modal" tabIndex="-1" aria-hidden="true" className="bg-black/40 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full d-flex">
                        <div className="relative p-4 w-full max-w-3xl top-[3%] left-[35%] mb-20 d-flex justify-center">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 w-max-3xl">
                                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                        Edit Columns
                                    </h3>
                                    <button onClick={() => editTableColumnSettingModalHandle(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                </div>
                                <div>
                                    <div className='w-full px-5'>
                                        <h2>Table Column Settings</h2>
                                        <ul>
                                            {/* <div className='w-full inline-flex items-center' draggable={true} onDragEnd={(e) => onDragEnd(e)}>
                                                <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor={'check_select_all'}>
                                                    <input
                                                        // name={pickup.id}
                                                        type="checkbox"
                                                        onChange={() => toggleVisibility(index)}
                                                        checked={column.visible}
                                                        // value={pickup.id}
                                                        className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-bg-primary checked:before:bg-bg-primary hover:before:opacity-10"
                                                        id={'check_' + column.id}
                                                    />
                                                    <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" strokeWidth="1">
                                                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                                        </svg>
                                                    </span>
                                                </label>
                                                <span className='ml-0'>{column.name}</span>
                                            </div> */}
                                            {columns.map((column, index) => (
                                                <div className='w-full inline-flex items-center' key={column.name} draggable={true} onDragEnd={(e) => onDragEnd(e)}>
                                                    {/* <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={column.visible}
                                                            onChange={() => toggleVisibility(index)}
                                                        />
                                                        {column.name}
                                                    </label> */}
                                                    <label className='flex items-center' htmlFor={'check_' + column.slug}>
                                                        <div className="relative flex items-center p-3 rounded-full cursor-pointer">
                                                            <input
                                                                // name={pickup.id}
                                                                type="checkbox"
                                                                onChange={() => toggleVisibility(index)}
                                                                checked={column.visible}
                                                                // value={pickup.id}
                                                                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-bg-primary checked:before:bg-bg-primary hover:before:opacity-10"
                                                                id={'check_' + column.slug}
                                                            />
                                                            <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" strokeWidth="1">
                                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <span className='ml-0'>{column.name}</span>
                                                    </label>
                                                </div>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="flex justify-end mt-6 m-3 py-4">
                                        {/* <button onClick={cancelHandle} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500">Cancel</button> */}
                                        {updateSettingLoading ?
                                            <button disabled={true} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">
                                                Please wait...
                                            </button>
                                            :
                                            <button onClick={saveSettings} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    :
                    <div></div>
                }
            </div>
        </div>
    );
}
export default EditTableColumnSettingModal;