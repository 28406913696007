import React, { useState, useEffect, useContext } from 'react';
import PageHeading from "../../components/PageHeading";
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import { useNavigate, useParams } from 'react-router-dom';

function CustomerEditPage() {
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
    const { id } = useParams();
    const [stateOptions, setStateOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [billingCityOptions, setBillingCityOptions] = useState([]);
    const [disableCities, setDisableCities] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        businessName: '',
        firstName: '',
        lastName: '',
        username: '',
        contactPersonEmail: '',
        password: '',
        contactPersonPhoneNumber: '',
        contactPersonMobileNumber: '',
        websiteUrl: '',
        businessAddress1: '',
        businessAddress2: '',
        businessAddress3: '',
        state: '',
        city: '',
        zipCode: '',
        billingAddress1: '',
        billingAddress2: '',
        billingAddress3: '',
        billingState: '',
        billingCity: '',
        billingZipCode: '',
        sameBillingAddress: false,
    });
    const [data] = useState([]);

    const GET_CUSTOMER = gql`
        query GetCustomer($id: Int!) {
            customer (id: $id) {
                businessName,
                user {
                    id
                    firstName
                    lastName
                    username
                    email
                }
                contactPersonPhoneNumber,
                contactPersonMobileNumber,
                websiteUrl,
                businessAddress1,
                businessAddress2,
                businessAddress3,
                state,
                city,
                zipCode,
                billingAddress1,
                billingAddress2,
                billingAddress3,
                billingState,
                billingCity,
                billingZipCode,
                
            },
        }
    `;

    const GET_US_STATES = gql`
        query GetUSStates($first: Int, $skip: Int, $search: String, $sortBy: USStateSortInput) {
            usStates(first: $first, skip: $skip, search: $search, sortBy: $sortBy) {
                id
                name,
                code,
            },
        }
    `;

    const GET_CITIES = gql`
        query GetCities($stateId: ID,$first: Int, $skip: Int, $search: String, $sortBy: CitySortInput) {
            cities(stateId: $stateId, first: $first, skip: $skip, search: $search, sortBy: $sortBy) {
                id
                name,
                state {
                    name
                }
            },
        }
    `;

    const { data: usStateData, refetch } = useQuery(GET_US_STATES, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: {
            search: '',
            sortBy: {
                field: 'name',
                direction: 'asc',
            },
        },
    });



    const { loading: customerLoading, data: customerData } = useQuery(GET_CUSTOMER, {
        context: {
            headers: {
                Authorization: `JWT ${token}`
            }
        },
        variables: {
            id: parseInt(id),
        }
    });

    const [fetchCities, { data: cityData, loading, error }] = useLazyQuery(GET_CITIES, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
    });

    const [fetchBillingCities, { data: billingCityData }] = useLazyQuery(GET_CITIES, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
    });



    const UPDATE_CUSTOMER = gql`
        mutation($customerNew: UpdateCustomerInput) {
            updateCustomer(customerData:$customerNew) {
                customer {
                    id,
                    businessName,
                    businessAddress1,
                    contactPersonFullName,
                    contactPersonEmail,
                    websiteUrl,
                    createdBy,
                }
            }
        }
    `;

    const [updateCustomer, { data: UpdatedCustomer }] = useMutation(UPDATE_CUSTOMER, {
        onError: (error) => {
            try {
                const parsedError = JSON.parse(error.message);
                setErrors(parsedError);
            } catch (parseError) {
                setErrors('An error occurred while processing your request.');
            }
            setButtonLoading(false);

        },
        onCompleted: () => {
            setButtonLoading(false);
            window.location.href = '/admin/customers';
        }
    })


    const handleChange = (property, e) => {
        setFormData({
            ...formData,
            [property]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setButtonLoading(true);
        updateCustomer({
            context: {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            },
            variables: {
                customerNew: {
                    id: parseInt(id),
                    businessName: formData.businessName,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    username: formData.username,
                    contactPersonEmail: formData.contactPersonEmail,
                    password: formData.password ? formData.password : '',
                    contactPersonPhoneNumber: formData.contactPersonPhoneNumber,
                    contactPersonMobileNumber: formData.contactPersonMobileNumber,
                    websiteUrl: formData.websiteUrl,
                    businessAddress1: formData.businessAddress1,
                    businessAddress2: formData.businessAddress2,
                    businessAddress3: formData.businessAddress3,
                    state: formData.state,
                    city: formData.city,
                    zipCode: formData.zipCode ? formData.zipCode : '',
                    billingAddress1: formData.billingAddress1,
                    billingAddress2: formData.billingAddress2,
                    billingAddress3: formData.billingAddress3,
                    billingState: formData.billingState,
                    billingCity: formData.billingCity,
                    billingZipCode: formData.billingZipCode ? formData.billingZipCode : '',
                }
            }
        });
    }

    const handleCancel = () => {
        navigate("/admin/customers");
    }

    useEffect(() => {
        if (customerData && customerData.customer && customerData.customer.state) {
            const stateId = customerData.customer.state;
            const billingStateId = customerData.customer.billingState;
            fetchCities({
                variables: { stateId },
            });
            fetchBillingCities({
                variables: { billingStateId },
            });
        }
    }, [customerData, fetchCities, fetchBillingCities]);

    useEffect(() => {

        if (customerData && customerData.customer) {
            let customer = customerData.customer;

            let billingCheckboxValue = false;
            if (customer.billingAddress1 && customer.billingAddress1 == customer.businessAddress1) {
                billingCheckboxValue = true;
            }

            if (customer.billingAddress2 && customer.billingAddress2 != customer.businessAddress2) {
                billingCheckboxValue = false;
            }

            if (customer.billingAddress3 && customer.billingAddress3 != customer.businessAddress3) {
                billingCheckboxValue = false;
            }

            if (customer.billingState && customer.billingState != customer.state) {
                billingCheckboxValue = false;
            }

            if (customer.billingCity && customer.billingCity != customer.city) {
                billingCheckboxValue = false;
            }

            setFormData({
                ...formData,
                username: customer.user.username || '',
                firstName: customer.user.firstName || '',
                lastName: customer.user.lastName || '',
                contactPersonEmail: customer.user.email || '',
                contactPersonMobilerNumber: customer.contactPersonMobilerNumber || '',
                businessAddress1: customer.businessAddress1 || '',
                businessAddress2: customer.businessAddress2 || '',
                businessAddress3: customer.businessAddress3 || '',
                businessName: customer.businessName || '',
                contactPersonPhoneNumber: customer.contactPersonPhoneNumber || '',
                contactPersonMobileNumber: customer.contactPersonMobileNumber || '',
                websiteUrl: customer.websiteUrl || '',
                state: customer.state || '',
                city: customer.city || '',
                zipCode: customer.zipCode || '',
                billingAddress1: customer.billingAddress1 || '',
                billingAddress2: customer.billingAddress2 || '',
                billingAddress3: customer.billingAddress3 || '',
                billingState: customer.billingState || '',
                billingCity: customer.billingCity || '',
                billingZipCode: customer.billingZipCode || '',
                sameBillingAddress: billingCheckboxValue,
            });



        }
    }, [customerData]);

    useEffect(() => {
        if (usStateData && usStateData.usStates) {
            setStateOptions(usStateData.usStates);
        }
    }, [usStateData]);

    useEffect(() => {
        if (cityData && cityData.cities) {
            setCityOptions(cityData.cities);
        }
    }, [cityData]);

    useEffect(() => {
        if (billingCityData && billingCityData.cities) {
            setBillingCityOptions(billingCityData.cities);
        }
    }, [billingCityData]);

    useEffect(() => {
        fetchBillingCities({
            variables: {
                stateId: formData.billingState,
                search: '',
                sortBy: {
                    field: 'name',
                    direction: 'asc',
                },
            },
        });
    }, [formData?.billingState]);

    useEffect(() => {
        fetchCities({
            variables: {
                stateId: formData.state,
                search: '',
                sortBy: {
                    field: 'name',
                    direction: 'asc',
                },
            },
        });
    }, [formData?.state]);


    const getCustomers = (stateId) => {
        fetchCities({
            variables: {
                stateId: stateId,
                search: '',
                sortBy: {
                    field: 'name',
                    direction: 'asc',
                },
            },
        });
    }

    const stateHandle = (e, property) => {
        setFormData({
            ...formData,
            [property]: e.target.value || '',
        });
        setDisableCities(false);
    };

    const cityHandle = (e) => {
        setFormData({
            ...formData,
            city: e.target.value || '',
        });
    };

    const handleCheckboxChange = () => {
        let isChecked = formData.sameBillingAddress;
        if (isChecked == false) {
            setFormData({
                ...formData,
                sameBillingAddress: !isChecked,
                billingAddress1: formData.businessAddress1,
                billingAddress2: formData.businessAddress2,
                billingAddress3: formData.businessAddress3,
                billingState: formData.state,
                billingCity: formData.city,
                billingZipCode: formData.zipCode,

            })

        }
        else {
            setFormData({
                ...formData,
                sameBillingAddress: !isChecked,
                billingAddress1: '',
                billingAddress2: '',
                billingAddress3: '',
                billingState: '',
                billingCity: '',
                billingZipCode: '',

            })
        }
    };

    if (customerLoading) return <p>Loading...</p>;
    return (
        <div className="content px-3 lg:px-8">
            <div className="content-bar flex justify-between mt-6">
                <PageHeading>Update Customer</PageHeading>
            </div>
            <div className="mt-8">
                <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                    <div className="absolute inset-0"></div>
                    <div className="relative rounded-xl overflow-auto">
                        <div className="shadow-sm overflow-hidden mt-7 px-4">

                            {
                                data && data.createCustomer?.customer && < div className='text-green-500'>Customer added successfully.</div>
                            }


                            {/* Create Driver Form  */}
                            <div className='row'>
                                <div className='md:w-3/6'>
                                    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">

                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="contact_person_first_name">
                                                    Contact Person First Name
                                                </label>
                                                <input value={formData.firstName} onChange={(value) => handleChange('firstName', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="contact_person_first_name" type="text" autoComplete="on" placeholder="First Name" required />
                                                {errors && errors.firstName && <p className="text-red-500 text-xs italic">{errors.firstName}</p>}
                                            </div>
                                            <div className="md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="contact_person_last_name">
                                                    Contact Person Last Name
                                                </label>
                                                <input value={formData.lastName} onChange={(value) => handleChange('lastName', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4" id="contact_person_last_name" type="text" autoComplete="on" placeholder="Last Name" required />
                                                {errors && errors.lastName && <p className="text-red-500 text-xs italic">{errors.lastName}</p>}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-full px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="business_name">
                                                    Business Name
                                                </label>
                                                <input value={formData.businessName} onChange={(value) => handleChange('businessName', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="business_name" type="text" autoComplete="on" placeholder="Business Name" required />
                                                {errors && errors.businessName && <p className="text-red-500 text-xs italic">{errors.businessName}</p>}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_username">
                                                    Username
                                                </label>
                                                <input value={formData.username} onChange={(value) => handleChange('username', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="customer_username" type="text" autoComplete="on" placeholder="Username" required />
                                                {errors && errors.username && <p className="text-red-500 text-xs italic">{errors.username}</p>}
                                            </div>
                                            <div className="md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_email">
                                                    Email
                                                </label>
                                                <input value={formData.contactPersonEmail} onChange={(value) => handleChange('contactPersonEmail', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4" id="customer_email" type="email" autoComplete="on" placeholder="Email" required />
                                                {errors && errors.contactPersonEmail && <p className="text-red-500 text-xs italic">{errors.contactPersonEmail}</p>}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_password">
                                                    Password
                                                </label>
                                                <input value={formData.password || ''} onChange={(value) => handleChange('password', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="customer_password" type="password" autoComplete="on" placeholder="Password" required />
                                                {errors && errors.password && <p className="text-red-500 text-xs italic">{errors.password}</p>}
                                            </div>
                                            <div className="md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_phone_number">
                                                    Phone Number
                                                </label>
                                                <input value={formData.contactPersonPhoneNumber} onChange={(value) => handleChange('contactPersonPhoneNumber', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4" id="customer_phone_number" type="text" autoComplete="on" placeholder="Phone Number" required />
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex">
                                            <div className="md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="mobile_number">
                                                    Mobile Number
                                                </label>
                                                <input value={formData.contactPersonMobileNumber} onChange={(value) => handleChange('contactPersonMobileNumber', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="mobile_number" type="text" autoComplete="on" placeholder="Mobile Number" required />
                                                {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                            </div>
                                            <div className="md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_webiste_url">
                                                    Website URL
                                                </label>
                                                <input value={formData.websiteUrl} onChange={(value) => handleChange('websiteUrl', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4" id="customer_webiste_url" type="text" autoComplete="on" placeholder="https://www.example.com" required />
                                            </div>
                                        </div>
                                        <h4 className='text-xl font-bold my-3'>Consignee Address</h4>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-full px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_address_1">
                                                    Address Line 1
                                                </label>
                                                <input value={formData.businessAddress1} onChange={(value) => handleChange('businessAddress1', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="customer_address_1" type="text" autoComplete="on" placeholder="Address Line 1" required />
                                                {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-full px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_address_2">
                                                    Address Line 2
                                                </label>
                                                <input value={formData.businessAddress2} onChange={(value) => handleChange('businessAddress2', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="customer_address_2" type="text" autoComplete="on" placeholder="Address Line 2" required />
                                                {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-full px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_address_3">
                                                    Address Line 3
                                                </label>
                                                <input value={formData.businessAddress3} onChange={(value) => handleChange('businessAddress3', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="customer_address_3" type="text" autoComplete="on" placeholder="Address Line 3" required />
                                                {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-1/3 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_state">
                                                    State
                                                </label>
                                                <div className="relative">
                                                    <select value={formData.state} onChange={(e) => stateHandle(e, 'state')} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="grid-state">
                                                        <option value="">Select State</option>
                                                        {
                                                            stateOptions.map((state, index) => {
                                                                return (
                                                                    <option key={state.id} value={state.code}>{state.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                    <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                        <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                    </div>

                                                </div>
                                                {errors && errors.state && <p className="text-red-500 text-xs italic">{errors.state}</p>}
                                            </div>
                                            <div className="md:w-1/3 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_city">
                                                    City
                                                </label>
                                                <div className="relative">
                                                    <select value={formData.city} disabled={disableCities} onChange={(e) => cityHandle(e, 'city')} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="grid-state">
                                                        <option value="">Select City</option>
                                                        {
                                                            cityOptions.map((city) => {
                                                                return (
                                                                    <option value={city.name} key={city.id}>{city.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                    <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                        <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                    </div>
                                                </div>
                                                {errors && errors.city && <p className="text-red-500 text-xs italic">{errors.city}</p>}
                                            </div>
                                            <div className="md:w-1/3 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_zipCode">
                                                    Zip Code
                                                </label>
                                                <input value={formData.zipCode} onChange={(value) => handleChange('zipCode', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="customer_zipCode" type="text" autoComplete="on" placeholder="Zip Code" required />
                                                {errors && errors.zipCode && <p className="text-red-500 text-xs italic">{errors.zipCode}</p>}
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className='inline-flex items-center'>
                                                <label className="relative flex items-center py-3 rounded-full cursor-pointer" htmlFor="check">
                                                    <input checked={formData?.sameBillingAddress} onChange={handleCheckboxChange} type="checkbox"
                                                        className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                                                        id="check" />
                                                    <span
                                                        className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                                            stroke="currentColor" strokeWidth="1">
                                                            <path fillRule="evenodd"
                                                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                clipRule="evenodd"></path>
                                                        </svg>
                                                    </span>
                                                </label>
                                                <label className="ml-3 mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor="check">
                                                    Billing Address same as Consignee Address
                                                </label>
                                            </div>
                                        </div>
                                        <h4 className='text-xl font-bold my-3'>Billing Address</h4>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-full px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_billing_address_1">
                                                    Address Line 1
                                                </label>
                                                <input value={formData.billingAddress1} onChange={(value) => handleChange('billingAddress1', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="customer_billing_address_1" type="text" autoComplete="on" placeholder="Billing Address Line 1" required />
                                                {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-full px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_billing_address_2">
                                                    Address Line 2
                                                </label>
                                                <input value={formData.billingAddress2} onChange={(value) => handleChange('billingAddress2', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="customer_billing_address_2" type="text" autoComplete="on" placeholder="Billing Address Line 2" required />
                                                {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-full px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_billing_address_3">
                                                    Address Line 3
                                                </label>
                                                <input value={formData.billingAddress3} onChange={(value) => handleChange('billingAddress3', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="customer_billing_address_3" type="text" autoComplete="on" placeholder="Billing Address Line 3" required />
                                                {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex">
                                            <div className="md:w-1/3 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_billing_state">
                                                    State
                                                </label>
                                                <div className="relative">
                                                    <select value={formData.billingState ? formData.billingState : ''} onChange={(e) => stateHandle(e, 'billingState')} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="customer_billing_state">
                                                        <option value="null">Select State</option>
                                                        {
                                                            stateOptions.map((state, index) => {
                                                                return (
                                                                    <option key={state.code} value={state.code}>{state.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                    <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                        <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                    </div>

                                                </div>
                                                {errors && errors.billingState && <p className="text-red-500 text-xs italic">{errors.billingState}</p>}
                                            </div>
                                            <div className="md:w-1/3 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_billing_city">
                                                    City
                                                </label>
                                                <div className="relative">
                                                    <select value={formData.billingCity ? formData.billingCity : ''} disabled={disableCities} onChange={(e) => stateHandle(e, 'billingCity')} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="customer_billing_city">
                                                        <option value="null">Select City</option>
                                                        {
                                                            billingCityOptions.map((city) => {
                                                                return (
                                                                    <option value={city.name} key={city.id}>{city.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                    <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                        <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                    </div>

                                                </div>
                                                {errors && errors.billingCity && <p className="text-red-500 text-xs italic">{errors.billingCity}</p>}
                                            </div>
                                            <div className="md:w-1/3 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="customer_billing_zipCode">
                                                    Zip Code
                                                </label>
                                                <input value={formData.billingZipCode} onChange={(value) => handleChange('billingZipCode', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="customer_billing_zipCode" type="text" autoComplete="on" placeholder="Zip Code" required />
                                                {errors && errors.billingZipCode && <p className="text-red-500 text-xs italic">{errors.billingZipCode}</p>}
                                            </div>
                                        </div>
                                        <div className="flex justify-end mt-6">
                                            <button onClick={handleCancel} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500">Cancel</button>
                                            {buttonLoading === true ? <button disabled type="button" class="text-white bg-gray-600 hover:bg-gray-600 focus:ring-4 focus:ring-blue-300 font-normal rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center">
                                                <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                </svg>
                                                Loading...
                                            </button> :
                                                <button type='submit' onClick={handleSubmit} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Create Driver Form  */}


                        </div>
                    </div>
                    <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                </div>
            </div>
        </div >
    );
}

export default CustomerEditPage;