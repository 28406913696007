import React, { useState, useContext, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { AuthContext } from '../../context/authContext';
import GraphQuery from '../graphql/queries/Query';
import Loader from '../Loader';

const FreightLocationModal = ({ pickupId, showModal, closeModal, updateSelectedPickups }) => {
    const { token } = useContext(AuthContext);
    const [locationOptions, setLocationOptions] = useState([]);
    const [showDistanceBar, setShowDistanceBar] = useState(false);
    const [showAllFreight, setShowAllFreight] = useState(false);
    const [formData, setFormData] = useState({
        locationId: [],
        zipCode: '',
        distance: 0,
        sourceAddress: '',
    });
    const [sourceAddress, setSourceAddress] = useState('');
    const [pickupRequestOptions, setPickupRequestOptions] = useState([]);
    const [errors, setErrors] = useState([]);
    const [selectedPickupRequests, setSelectedPickupRequests] = useState([]);

    const GET_PICKUP_REQUESTS_BY_LOCATION = gql`
        query GetPickupRequestsByLocation($locationId: [String], $pickupStatus: String, $zipCode: String, $distance: String, $sourceAddress: String, $sortBy: PickupRequestsByLocationSortInput) {
            pickupRequestsByLocation(locationId: $locationId, pickupStatus: $pickupStatus, zipCode: $zipCode, distance: $distance, sourceAddress: $sourceAddress, sortBy: $sortBy) {
                id,
                createdAt,
                apiMode,
                shipmentDate,
                pickupDate,
                pickupTime,
                pickupNumber,
                shipmentTerms,
                serviceType,
                pickupStatus,
                customer {
                    contactPersonFullName,
                    businessName
                }
                account {
                    id,
                    companyName,
                },
                pickupRequestAddresses {
                    id,
                    companyName,
                    addressType,
                    address1,
                    address2,
                    city,
                    state,
                    zipCode,
                },
                pickupRequestItems {
                    id,
                    description,
                    quantity,
                    weight,
                    length,
                    width,
                    height,
                    pallets,
                    shipmentClass,
                    charges
                },
            }
        }
    `;

    const [fetchPickupRequestsByLocation, { data: pickupRequestsByLocationData, loading: pickupRequestsLoading }] = useLazyQuery(GET_PICKUP_REQUESTS_BY_LOCATION, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
    });

    const { responseData: locationData, dataCount: locationCount, locationLoading, locationError } = GraphQuery({ name: 'Locations', single: 'Location' }, {
        skip: 0,
        search: '',
        sortBy: 'title',
        order: 'asc',
    }, 'id,title', 'locationCount');

    useEffect(() => {
        if (locationData) {
            setLocationOptions(locationData);
        }
    }, [locationData]);

    useEffect(() => {
        if (formData.locationId) {
            setSelectedPickupRequests([]);
            setPickupRequestOptions([]);
            setShowAllFreight(false);
            let result = fetchPickupRequestsByLocation({
                variables: {
                    locationId: formData.locationId,
                    pickupStatus: 'unloaded',
                    zipCode: formData.zipCode != '' ? formData.zipCode : '',
                    distance: formData.distance != '' ? formData.distance : '',
                    sourceAddress: sourceAddress != '' ? sourceAddress : '',
                    // sortBy: {
                    //     field: 'pickup_number',
                    //     direction: 'asc',
                    // },
                },
                fetchPolicy: 'network-only',
            });
            if (result?.data?.pickupRequestsByLocation[0] != null) {
                console.log('pickup requests fetched:', result.data.pickupRequestsByLocation);
            }
        }
    }, [formData]);

    useEffect(() => {
        if (selectedPickupRequests.length > 0) {
            let checkAllSelected = pickupRequestOptions.every((pickup) => selectedPickupRequests.includes(pickup.id.toString()))
            setShowAllFreight(checkAllSelected);
            let pickupId = selectedPickupRequests[0];
            console.log(pickupId, 'kojfsf');
            let toAddress = null;
            pickupRequestOptions.map((pickup, index) => {
                if (pickup.id == pickupId && pickup?.pickupRequestAddresses?.length > 0) {
                    pickup?.pickupRequestAddresses.forEach((address) => {
                        if (address.addressType == 'CONSIGNEE' || address.addressType == 'DESTINATION') {
                            toAddress = address;
                        }
                    })
                }
            });

            console.log('soutrce addresss is ', toAddress);
            let source_address = toAddress ? toAddress.address1 + ', ' + toAddress.city + ', ' + toAddress.state + ', ' + toAddress.zipCode : ''
            setSourceAddress(source_address);

        }
    }, [selectedPickupRequests]);

    useEffect(() => {
        if (pickupRequestsByLocationData?.pickupRequestsByLocation.length > 0) {
            setPickupRequestOptions(pickupRequestsByLocationData.pickupRequestsByLocation);
        }
    }, [pickupRequestsByLocationData]);

    const locationHandle = async (e, property) => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        setFormData({
            ...formData,
            [property]: selectedOptions
        });

    };

    const handleSubmit = (e) => {
        console.log('assigned PRs', selectedPickupRequests);
        let filteredPickupRequests = [];
        pickupRequestOptions.map((pickup) => {
            if (selectedPickupRequests.includes(pickup.id.toString())) {
                filteredPickupRequests.push(pickup);
            }
        });

        console.log('==filteredPickupRequests===');
        console.log(filteredPickupRequests);
        updateSelectedPickups(filteredPickupRequests);
    };

    const selectPickupRequestHandle = (event) => {
        const { value, checked } = event.target;
        setSelectedPickupRequests((prevSelected) => {
            if (checked) {
                return [...prevSelected, value];
            } else {
                return prevSelected.filter((id) => id !== value);
            }
        });
        console.log('checkbox output', selectedPickupRequests);
    };

    const removeSelectedLocation = (id) => {
        let updatedLocationIds = formData.locationId.filter((location) => location != `${id}`)
        setFormData({
            ...formData,
            locationId: updatedLocationIds
        });
    }

    const handleRangeChange = (e) => {
        setFormData({
            ...formData,
            distance: e.target.value,
        });
    };

    const handleZipCodeChange = (e) => {
        setFormData({
            ...formData,
            zipCode: e.target.value,
        });
    };

    const showDistanceBarHandle = (event) => {
        const { value, checked } = event.target;
        setShowDistanceBar(checked);
        setFormData({
            ...formData,
            distance: 0,
        });
    };

    const selectAllFreightHandle = (e) => {
        console.log('========Before=========')
        console.log('pickups', selectedPickupRequests);
        console.log('========Before=========')
        const { value, checked } = e.target;
        setShowAllFreight(checked);
        // setSelectedPickupRequests((prevSelected) => {
        if (checked) {
            let updatedPickupIds = [];
            updatedPickupIds = pickupRequestOptions.map((pickup) => pickup.id)
            console.log('UP', updatedPickupIds);
            setSelectedPickupRequests(updatedPickupIds);
        } else {
            setSelectedPickupRequests([]);
        }
        // });
        console.log('========After=========')
        console.log('pickups', selectedPickupRequests);
        console.log('========After=========')
    }

    const renderPickupRequestOptions = () => {
        if (pickupRequestOptions.length > 0) {
            return pickupRequestOptions.map((pickup, index) => {
                let fromAddress = null;
                let toAddress = null;
                if (pickup?.pickupRequestAddresses?.length > 0) {
                    pickup?.pickupRequestAddresses.forEach((address) => {
                        if (address.addressType == 'CONSIGNEE' || address.addressType == 'DESTINATION') {
                            toAddress = address;
                        }
                        if (address.addressType == 'BILLTO') {
                            fromAddress = address;
                        }
                        if (address.addressType == 'SHIPPER' || address.addressType == 'SOURCE') {
                            fromAddress = address;
                        }
                    })
                }

                return (
                    <div key={index} className={[`w-3/6 py-3 bg-white ${index % 2 == 0 ? 'pr-3' : 'pl-3 pr-0'}`]}>
                        <div className='border rounded-md px-3 py-5 hover:shadow-md'>
                            <div className="w-full inline-flex items-start">
                                <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor={'check_' + pickup.id}>
                                    <input
                                        name={pickup.id}
                                        onChange={selectPickupRequestHandle}
                                        value={pickup.id}
                                        type="checkbox"
                                        checked={selectedPickupRequests.includes(pickup.id.toString())}
                                        className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-bg-primary checked:before:bg-bg-primary hover:before:opacity-10"
                                        id={'check_' + pickup.id}
                                    />
                                    <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" strokeWidth="1">
                                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                        </svg>
                                    </span>
                                </label>
                                <label className="w-full mt-px font-normal text-gray-700 cursor-pointer select-none" htmlFor={'check_' + pickup.id}>
                                    <div className='flex items-start justify-start mb-0'>
                                        <span className="w-[45%]">Pickup ID: </span>
                                        <span className='w-[55%] font-semibold'>{pickup.id}</span>
                                    </div>
                                    <div className='flex items-start justify-start mb-0'>
                                        <span className="w-[45%]">Pickup Number: </span>
                                        <span className='w-[55%] font-semibold'>{pickup.pickupNumber}</span>
                                    </div>
                                    <div className='flex items-start justify-start mb-0'>
                                        <span className="w-[45%]">Service Type: </span>
                                        <span className='w-[55%] font-semibold'>{pickup.serviceType}</span>
                                    </div>
                                    <div className='flex items-start justify-start mb-0'>
                                        <span className="w-[45%]">From Bol: </span>
                                        <span className='w-[55%] font-semibold'>XXXXXXXXXX</span>
                                    </div>
                                    <div className='flex items-start justify-start mb-0'>
                                        <span className="w-[45%]">Source Company Name: </span>
                                        <span className='w-[55%] font-semibold'>{fromAddress ? fromAddress.companyName : ''}</span>
                                    </div>
                                    <div className='flex items-start justify-start mb-0'>
                                        <span className="w-[45%]">Source Address: </span>
                                        <span className='w-[55%] font-semibold'>{fromAddress ? fromAddress.address1 + ',' + fromAddress.city + ', ' + fromAddress.state + ' ' + fromAddress.zipCode + ', United States' : ''}</span>
                                    </div>
                                    <div className='flex items-start justify-start mb-0'>
                                        <span className="w-[45%]">Destination Company Name: </span>
                                        <span className='w-[55%] font-semibold'>{toAddress ? toAddress.companyName : ''}</span>
                                    </div>
                                    <div className='flex items-start justify-start mb-0'>
                                        <span className="w-[45%]">Destination Address: </span>
                                        <span className='w-[55%] font-semibold'>{toAddress ? toAddress.address1 + ',' + toAddress.city + ', ' + toAddress.state + ' ' + toAddress.zipCode + ', United States' : ''}</span>
                                    </div>

                                </label>
                            </div>
                        </div>
                    </div>
                )
            });
        }
    };

    if (showModal) {
        return (
            <div>
                <div id="crud-modal" tabIndex="-1" aria-hidden="true" className="bg-black/40 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full d-flex">
                    <div className="relative p-4 w-full max-w-7xl top-[3%] left-[22%] mb-20 d-flex justify-center">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 w-max-7xl">
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    Choose Freight Location
                                </h3>
                                <button onClick={() => closeModal()} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <form className="">
                                <div className='row'>
                                    <div className='md:w-full'>
                                        <div className="bg-white rounded px-8 pt-6 pb-8 flex flex-col my-2">
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-2/6 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="location_zone">
                                                        Location
                                                    </label>
                                                    <div className="relative">
                                                        <select multiple={true} value={formData.locationId} onChange={(e) => locationHandle(e, 'locationId')} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="location_zone">
                                                            <option value="">Select Location</option>
                                                            <option value="all locations">Select All Location</option>
                                                            {
                                                                locationOptions.map((location) => {
                                                                    return (
                                                                        <option value={location.id} key={location.id}>{location.title}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>

                                                        {/* <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div> */}
                                                    </div>
                                                    {errors && errors.locationId && <p className="text-red-500 text-xs italic">{errors.locationId}</p>}
                                                </div>
                                                <div className="md:w-4/6 px-3">
                                                    Selected Locations:
                                                    <div className='flex flex-wrap items-center justify-start mt-3'>
                                                        {locationOptions.map((location, index) => {
                                                            if (formData.locationId[index] == 'all locations') {
                                                                return <span className='flex items-center py-1 px-2 bg-purple-300 rounded-md mr-2 text-md' key={index}>
                                                                    All Locations
                                                                    <svg onClick={() => removeSelectedLocation('all locations')} className='ml-2' fill='#fff' width={18} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                                                    </svg>
                                                                </span>;
                                                            }

                                                            if (formData.locationId.includes(`${location.id}`)) {
                                                                return <span className='flex items-center py-1 px-2 bg-purple-300 rounded-md mr-2 text-md' key={index}>
                                                                    {location.title}
                                                                    <svg onClick={() => removeSelectedLocation(location.id)} className='ml-2' fill='#fff' width={18} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                                                    </svg>
                                                                </span>;
                                                            }
                                                        })}</div>
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-2/6 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="destination-zip-code">
                                                        Destination Zip Code
                                                    </label>
                                                    <input value={formData.zipCode} onChange={(e) => handleZipCodeChange(e, 'zipCode')} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="destination-zip-code" type="text" autoComplete="on" placeholder="Zip Code" />
                                                    {errors && errors.zipCode && <p className="text-red-500 text-xs italic">{errors.zipCode}</p>}
                                                </div>


                                                <div className="md:w-1/6 px-3">
                                                    <div>
                                                        <div className="inline-flex items-center">
                                                            <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor={'check_distance_bar'}>
                                                                <input onChange={showDistanceBarHandle} value={showDistanceBar} checked={showDistanceBar} type="checkbox"
                                                                    className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                                                                    id={'check_distance_bar'} />
                                                                <span
                                                                    className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                                                        stroke="currentColor" strokeWidth="1">
                                                                        <path fillRule="evenodd"
                                                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                            clipRule="evenodd"></path>
                                                                    </svg>
                                                                </span>
                                                            </label>
                                                            <label className="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor={'check_distance_bar'}>
                                                                Show Distance Bar
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                                {showDistanceBar && <div className="md:w-3/6 px-3">
                                                    <label htmlFor="labels-range-input" className="">Select Distance</label>
                                                    <div className="relative mb-6">
                                                        <div className="text-center mt-2 text-gray-500 dark:text-gray-400">
                                                            Selected Value: <input className='w-16' value={formData.distance} onChange={(e) => handleRangeChange(e)} /> km
                                                        </div>
                                                        <input
                                                            id="labels-range-input"
                                                            type="range"
                                                            value={formData.distance}
                                                            min="0"
                                                            max="50"
                                                            onChange={handleRangeChange}
                                                            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                                                        />
                                                        <span className="absolute left-0 -bottom-4 text-sm text-gray-500 dark:text-gray-400">
                                                            0 km
                                                        </span>
                                                        <span className="absolute left-[47%] -bottom-4 text-sm text-gray-500 dark:text-gray-400">
                                                            25 km
                                                        </span>
                                                        {/* <span className="absolute left-2/3 -bottom-4 text-sm text-gray-500 dark:text-gray-400">
                                                            10000 km
                                                        </span> */}
                                                        <span className="absolute right-0 -bottom-4 text-sm text-gray-500 dark:text-gray-400">
                                                            50 km
                                                        </span>

                                                    </div>
                                                </div>}


                                            </div>
                                            <div className='w-full flex items-center justify-start'>
                                                <div className="md:w-2/6 pr-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="destination-zip-code">
                                                        Source Address
                                                    </label>
                                                    <input value={sourceAddress} onChange={(e) => setSourceAddress(e.target.value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="destination-zip-code" type="text" autoComplete="on" placeholder="Source Address" />
                                                    {errors && errors.sourceAddress && <p className="text-red-500 text-xs italic">{errors.sourceAddress}</p>}
                                                </div>
                                                <div className="md:w-2/6 pl-3">
                                                    <div className="inline-flex items-center">
                                                        <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor={'check_select_all_box'}>
                                                            <input onChange={(e) => selectAllFreightHandle(e)} value={showAllFreight} checked={showAllFreight} type="checkbox"
                                                                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                                                                id={'check_select_all_box'} />
                                                            <span
                                                                className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                                                    stroke="currentColor" strokeWidth="1">
                                                                    <path fillRule="evenodd"
                                                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                        clipRule="evenodd"></path>
                                                                </svg>
                                                            </span>
                                                        </label>
                                                        <label className="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor={'check_select_all_box'}>
                                                            Select All
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                pickupRequestsLoading ?
                                                    <div>
                                                        <Loader />
                                                        <p className='text-center'>Please wait....</p>
                                                    </div>
                                                    :
                                                    <div className="md:w-full flex flex-wrap items-start justify-start  mb-4 px-0">
                                                        {renderPickupRequestOptions()}
                                                    </div>
                                            }
                                            <div className="flex justify-end">
                                                <button type="button" onClick={() => closeModal()} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:bg-gray-500">Cancel</button>
                                                <button type="button" onClick={() => handleSubmit()} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-bg-primary rounded-md hover:bg-[#0f2350] focus:outline-none focus:bg-gray-600">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default FreightLocationModal;
