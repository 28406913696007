import classNames from "classnames";

function Select({id, name, options, onChange, firstOption, register, label, cssSelectClasses, cssLabelClasses, hideLabel, validationOptions}) {
    
    const renderedOptions = options.map((option) => {
        return <option value={option.value} key={option.value}>
            {option.label}
        </option>
    });

    const renderEmptyOption = () => {
        if (firstOption) {
            return <option value="" key="">{firstOption}</option>
        }
    };

    const selectClasses = classNames(
        'w-full appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white',
        cssSelectClasses
    );

    const labelClasses = classNames(
        'text-sm leading-5 font-medium',
        cssLabelClasses
    );

    let _name = name ? name : id;
    
    return (
        <>
            {hideLabel === true ? null : <label className={labelClasses}>{label}</label>}
            <select {...register(id, validationOptions)} id={id} name={_name} className={selectClasses} onChange={onChange} >
                {renderEmptyOption()}
                {renderedOptions}
            </select>
        </>
    );
}

export default Select;