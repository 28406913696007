import React, { useState, useEffect, useContext } from 'react';
import { gql, useMutation, useQuery, } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import GraphQuery from '../graphql/queries/Query';
import ReportIsssueModal from './ReportIssueModal';
import CompletedFreight from './CompletedFreight';

const FreightCheckIn = ({ filterValue, selectedTruckTab, trucksData, getCheckinTruck, reportFreightCheckinIssue }) => {
    const [trucks, setTrucks] = useState([]);
    const [showReportIssueModal, setShowReportIssueModal] = useState(false);
    const [showReportIssueModalId, setShowReportIssueModalId] = useState(null);
    const [checkinTruck, setCheckinTruck] = useState(null);
    const [upcomingTrucks, setUpcomingTrucks] = useState([]);

    useEffect(() => {
        console.log('shipmentClased checin', trucksData)
        if (trucksData) {
            let filteredTrucks = trucksData.filter((truck) => { return truck.assignedPickupRequests.length > 0 ? truck : false; })
            setTrucks(filteredTrucks)
        }
    }, [trucksData, setTrucks]);

    useEffect(() => {
        console.log('selected Truck tab', selectedTruckTab, 'trucks', trucks);
        let filteredUpcomingTrucks = trucks.filter((truck, index) => {
            if (index == selectedTruckTab) {
                setCheckinTruck(truck);
            }
            return index != selectedTruckTab;
        });
        setUpcomingTrucks(filteredUpcomingTrucks);
    }, [selectedTruckTab, setUpcomingTrucks, trucks]);

    const renderPickupRequestItems = (pickupItems) => {
        if (pickupItems.length > 0) {
            return pickupItems.map((pickupItem, index) => {
                return (

                    <div key={pickupItem.id} className="w-full justify-start">
                        <div className='w-full my-[8px]'>
                            <span className="font-inter font-medium text-[16px] text-[#374151]">Pickup Item# {index + 1}</span>
                        </div>
                        <div className='flex flex-wrap w-full'>
                            <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Weight:</span>
                            <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[70%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupItem.weight} lbs</span>
                            <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Dimensions:</span>
                            <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[70%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupItem.length + ' X ' + pickupItem.width + ' X ' + pickupItem.height}</span>
                        </div>

                        {/* <div className="w-full flex items-center mb-1">
                            <p className="text-md font-semibold">Pickup Item# {index + 1}</p>
                        </div>
                        <div className="w-full">
                            <div className="w-full flex items-center justify-between md:justify-start mb-1">
                                <div className="md:w-[80%] lg:w-[40%] xl:w-2/6 mr-2 md:mr-0">
                                    <div className="flex items-center justify-start">
                                        <p className="text-[#6B7280]">Weight:</p>
                                    </div>
                                </div>
                                <div className="md:w-[25%] x:w-4/6 text-end md:text-start">
                                    <p className="font-5edium">{pickupItem.weight}</p>
                                </div>
                            </div>
                            <div className="w-full flex items-center justify-between md:justify-start mb-1">
                                <div className="md:w-[80%] lg:w-[40%] xl:w-2/6 mr-2 md:mr-0">
                                    <div className="flex items-center justify-start">
                                        <p className="text-[#6B7280]">Dimensions:</p>
                                    </div>
                                </div>
                                <div className="md:w-[25%] x:w-4/6 text-end md:text-start">
                                    <p className="font-5edium">{pickupItem.weight}</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                )
            })
        }

    }

    const renderTruckPickupRequests = (truck, truckIndex, type = "in_progress") => {
        if (truck.assignedPickupRequests.length > 0) {
            return truck.assignedPickupRequests.map((pickupRequest, index) => {
                let sourceAddressData = {};
                let destinationAddressData = {};
                let addresses = pickupRequest?.pickupRequestAddresses;
                addresses.map((address) => {
                    if (address.addressType === 'SHIPPER' || address.addressType === 'SOURCE') {
                        sourceAddressData = address;
                    }
                    else if (address.addressType === 'CONSIGNEE' || address.addressType == 'DESTINATION') {
                        destinationAddressData = address;
                    }
                    return true;
                });

                return (
                    <div key={index} className="md:w-full px-0 lg:px-3 flex flex-wrap mb-[20px]">
                        <div className="relative w-full sm:w-full md:w-[100%] lg:w-[45%] xl:w-[60%] 2xl:w-[50%]">
                            <div className='flex flex-wrap w-full  mt-3 md:mt-0'>
                                <span className="w-full font-inter font-semibold text-[16px] text-[#374151] mb-[20px]">Pickup #{index + 1}</span>
                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Pickup Number:</span>
                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[70%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupRequest.pickupNumber}</span>
                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Source Zip:</span>
                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[70%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{sourceAddressData.city ? sourceAddressData.city + ',' : ''} {sourceAddressData.state ? sourceAddressData.state + ',' : ''} {sourceAddressData.zipCode}</span>
                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Destination Zip:</span>
                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[70%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{destinationAddressData.city ? destinationAddressData.city + ',' : ''} {destinationAddressData.state ? destinationAddressData.state + ',' : ''} {destinationAddressData.zipCode}</span>
                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Service Type:</span>
                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[70%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupRequest.serviceType ? pickupRequest.serviceType : ''} </span>
                            </div>
                            {renderPickupRequestItems(pickupRequest?.pickupRequestItems)}
                        </div>
                        <div className="w-[35%] md:w-3/6 lg:w-[25%] xl:w-[15%] 2xl:w-[25%] mt-4 md:mt-0 flex items-center">
                            {type == 'in_progress' && <button onClick={() => checkinHandle(truck)} className="px-[1.5rem] py-[0.75rem] leading-4 text-[16px] text-white transition-colors duration-200 transform bg-[#EA580C] rounded-[6px] hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Check-in</button>}
                        </div>
                        <div className="w-[65%] md:w-3/6 lg:w-[25%] xl:w-[25%] 2xl:w-[25%] relative md:absolute top-[-7%] right-0 lg:relative mt-4 md:mt-0 flex items-center justify-center">
                            <div className="flex justify-end md:justify-end items-center">
                                <button onClick={() => showReportIssueModalHandle(pickupRequest.id)} className="flex items-center px-0 md:p-2 leading-5 text-[#EA580C] text-[17px] font-semibold transition-colors duration-200 transform  hover:bg-[#F9FAFB] focus:outline-none focus:bg-[#F9FAFB]">
                                    <span className="mr-3">

                                        <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.1083 2.5H6.89167L2.5 6.89167V13.1083L6.89167 17.5H13.1083L17.5 13.1083V6.89167L13.1083 2.5ZM15.8333 12.4167L12.4167 15.8333H7.58333L4.16667 12.4167V7.58333L7.58333 4.16667H12.4167L15.8333 7.58333V12.4167Z" fill="#EA580C" />
                                            <path d="M10.0001 14.1667C10.4603 14.1667 10.8334 13.7936 10.8334 13.3333C10.8334 12.8731 10.4603 12.5 10.0001 12.5C9.53984 12.5 9.16675 12.8731 9.16675 13.3333C9.16675 13.7936 9.53984 14.1667 10.0001 14.1667Z" fill="#EA580C" />
                                            <path d="M9.16675 5.83333H10.8334V11.6667H9.16675V5.83333Z" fill="#EA580C" />
                                        </svg>

                                    </span>
                                    Report an issue
                                </button>
                            </div>
                        </div>
                        <div key={pickupRequest.id}>
                            {showReportIssueModalId === pickupRequest.id && <ReportIsssueModal pickupId={showReportIssueModalId} showModal={showReportIssueModal} closeModal={() => closeReportIssueModal()} />}
                        </div>
                    </div>
                )

            });
        }
    }


    const checkinHandle = (truck) => {
        setCheckinTruck(truck);
        return getCheckinTruck(truck);
    }

    const reportHandle = () => {
        console.log('report')
    }

    const closeReportIssueModal = () => {
        setShowReportIssueModal(false);
    }

    const showReportIssueModalHandle = (id) => {
        // e.preventDefault();
        setShowReportIssueModal(true);
        setShowReportIssueModalId(id);
    }

    const renderTrucksDetails = () => {
        if (filterValue == 'driver-assigned' && trucks.length > 0) {
            return trucks.map((truck, index) => {
                if (selectedTruckTab === index) {
                    return (
                        <div key={index} className="md:w-full mb-[20px]">
                            <div className="relative md:w-full flex flex-wrap p-5 border bg-white border-[#D1D5DB] shadow-md rounded-lg h-[100%]">
                                <div className="w-full sm:w-full md:w-full lg:w-[100%] xl:w-[25%] 2xl:w-[20%]">
                                    <h5 className="text-[18px] text-[#374151] font-semibold font-inter capitalize mb-[8px]">{truck.make} {truck.model} {truck.numberPlate ? '( ' + truck.numberPlate + ')' : ''}</h5>
                                    {/* <p className="font-inter font-normal text-[16px] mb-[16px]">459873445023</p> */}
                                    {/* <button onClick={() => checkinHandle(truck)} className="px-[13px] py-[9px] leading-4 text-sm text-white transition-colors duration-200 transform bg-[#EA580C] rounded-[6px] hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Check-in</button> */}
                                </div>
                                <div className="relative w-full sm:w-full md:w-full lg:w-[100%] xl:w-[75%] 2xl:w-[80%]">
                                    {renderTruckPickupRequests(truck, index)}
                                </div>
                                {/* <div className="w-full md:w-2/6 lg:w-[25%] xl:w-2/6 2xl:w-2/6 relative md:absolute top-2 right-0 lg:relative">
                                    <div className="flex justify-start md:justify-end">
                                        <button onClick={() => showReportIssueModalHandle(truck.id)} className="flex items-center px-0 md:p-2 leading-5 text-[#EA580C] text-md font-semibold transition-colors duration-200 transform  hover:bg-[#F9FAFB] focus:outline-none focus:bg-[#F9FAFB]">
                                            <span className="mr-3">

                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.1083 2.5H6.89167L2.5 6.89167V13.1083L6.89167 17.5H13.1083L17.5 13.1083V6.89167L13.1083 2.5ZM15.8333 12.4167L12.4167 15.8333H7.58333L4.16667 12.4167V7.58333L7.58333 4.16667H12.4167L15.8333 7.58333V12.4167Z" fill="#EA580C" />
                                                    <path d="M10.0001 14.1667C10.4603 14.1667 10.8334 13.7936 10.8334 13.3333C10.8334 12.8731 10.4603 12.5 10.0001 12.5C9.53984 12.5 9.16675 12.8731 9.16675 13.3333C9.16675 13.7936 9.53984 14.1667 10.0001 14.1667Z" fill="#EA580C" />
                                                    <path d="M9.16675 5.83333H10.8334V11.6667H9.16675V5.83333Z" fill="#EA580C" />
                                                </svg>
                                            </span>
                                            Report an issue
                                        </button>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div key={truck.id + '_' + index}>
                                {showReportIssueModalId === truck.id && <ReportIsssueModal showModal={showReportIssueModal} closeModal={() => closeReportIssueModal()} />}
                            </div> */}
                        </div>
                    )
                }
            })
        }
        return (<p>No truck Data Available...</p>)
    }

    const renderUpComingTrucksDetails = () => {
        if (upcomingTrucks.length > 0) {

            return upcomingTrucks.map((truck, index) => {
                return (
                    <div key={index} className="md:w-full mb-[20px]">
                        <div className="relative md:w-full flex flex-wrap p-5 border bg-white border-[#D1D5DB] shadow-md rounded-lg h-[100%]">
                            <div className="w-full sm:w-full md:w-full lg:w-[100%] xl:w-[25%] 2xl:w-[20%]">
                                <h5 className="text-[18px] text-[#374151] font-semibold font-inter capitalize mb-[8px]">{truck.make} {truck.model} {truck.numberPlate ? '( ' + truck.numberPlate + ')' : ''}</h5>
                                {/* <p className="font-inter font-normal text-[16px] mb-[16px]">459873445023</p> */}
                                {/* <button onClick={() => checkinHandle(truck)} className="px-[13px] py-[9px] leading-4 text-sm text-white transition-colors duration-200 transform bg-[#EA580C] rounded-[6px] hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Check-in</button> */}
                            </div>
                            <div className="relative w-full sm:w-full md:w-full lg:w-[100%] xl:w-[75%] 2xl:w-[80%]">
                                {renderTruckPickupRequests(truck, index, 'upcoming')}
                            </div>
                            {/* <div className="w-full md:w-2/6 lg:w-[25%] xl:w-2/6 2xl:w-2/6 relative md:absolute top-0 right-0 lg:relative">
                                <div className="flex justify-start md:justify-end">
                                    <button onClick={() => showReportIssueModalHandle(truck.id)} className="flex items-center px-0 md:p-2 leading-5 text-[#EA580C] text-md font-semibold transition-colors duration-200 transform  hover:bg-[#F9FAFB] focus:outline-none focus:bg-[#F9FAFB]">
                                        <span className="mr-3">

                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.1083 2.5H6.89167L2.5 6.89167V13.1083L6.89167 17.5H13.1083L17.5 13.1083V6.89167L13.1083 2.5ZM15.8333 12.4167L12.4167 15.8333H7.58333L4.16667 12.4167V7.58333L7.58333 4.16667H12.4167L15.8333 7.58333V12.4167Z" fill="#EA580C" />
                                                <path d="M10.0001 14.1667C10.4603 14.1667 10.8334 13.7936 10.8334 13.3333C10.8334 12.8731 10.4603 12.5 10.0001 12.5C9.53984 12.5 9.16675 12.8731 9.16675 13.3333C9.16675 13.7936 9.53984 14.1667 10.0001 14.1667Z" fill="#EA580C" />
                                                <path d="M9.16675 5.83333H10.8334V11.6667H9.16675V5.83333Z" fill="#EA580C" />
                                            </svg>

                                        </span>
                                        Report an issue</button>
                                </div>
                            </div> */}
                        </div>
                        {/* <div key={truck.id + '_' + index}>
                            {showReportIssueModalId === truck.id && <ReportIsssueModal showModal={showReportIssueModal} closeModal={() => closeReportIssueModal()} />}
                        </div> */}
                    </div>
                )
            })
        }
        return (<p>No truck Data Available...</p>)
    }


    return (
        <div>
            {/* <h4 className="text-[30px] my-6 font-bold">Freight Check-In</h4> */}
            <div className="tab-container">
                <div className="relative z-1">
                    {filterValue == 'driver-assigned' ? <div>
                        {trucks.length > 0 ? <div className="row pt-3 mb-4 my-2">
                            <h3 className="uppercase text-[#6B7280] mb-3 font-medium font-inter text-[12px]">In Progress</h3>
                            {renderTrucksDetails()}
                        </div>
                            :
                            <p className=' mt-7'>No Pickup Request Found...</p>
                        }
                        {upcomingTrucks.length > 0 && <div className="row pt-3 mb-4 my-2">
                            <h3 className="uppercase text-[#6B7280] mb-3 font-medium font-inter text-[12px]">Up Coming</h3>
                            {renderUpComingTrucksDetails()}
                        </div>}
                    </div>
                        :
                        <CompletedFreight selectedTruckTab={selectedTruckTab} pickupStatus='unloaded' />}
                </div>
            </div>
        </div >
    )
}
export default FreightCheckIn;