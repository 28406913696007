import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import { useForm } from "react-hook-form"

import { gql, useMutation } from '@apollo/client';
import { AuthContext } from "../../context/authContext";
import Li from "../../components/Li";

import Header from '../layouts/PublicSite/Header';
import Footer from '../layouts/PublicSite/Footer';

import Input from '../../components/Input';

const TOKEN_AUTH = gql`
  mutation tokenAuth($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
        token
    }
  }
`;

function LoginPage() {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const { setAuthData } = useContext(AuthContext);

    // const [usernameError, setUsernameError] = useState('');
    // const [passwordError, setPasswordError] = useState('');
    const [loginErrors, setLoginErrors] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);

    const [tokenAuth] = useMutation(TOKEN_AUTH);

    const onSubmit = async (formData) => {
        setLoginErrors([]);

        try {
            setIsProcessing(true);
            const { data } = await tokenAuth({
                variables: { username: formData['username'], password: formData['password'] },
            });

            const token = data.tokenAuth.token;
            // save in local storage
            localStorage.setItem('token', token);
            setAuthData(token);
            const d = new Date();
            let time = d.getTime();

            window.location.href = `/admin/dashboard?t=${time}`;
        } catch (error) {
            setLoginErrors([error.message]);
            setIsProcessing(false);
        }
    }

    // useEffect(() => {
    //     setUsernameError(null);
    //     setPasswordError(null);
    // });

    const renderLoginErrors = loginErrors.map((error, index) => {
        return (
            <Li
                key={index}
                className={'text-red-500 text-xs italic'}
            >
                {error}
            </Li>
        );
    });

    return (
        <>
            <div className="hero bg-cover bg-center bg-no-repeat bg-color-primary text-white">
                <Header theme="dark" />
            </div>

            <div className=" wrapper xl:max-w-[2000px] 2xl:max-w-[2500px] mx-auto">
                <section className="sm:px-8 md:px-16 xl:px-32 2xl:px-52">
                    <div>
                        <svg className="hidden lg:block absolute left-0  top-[20rem] z-20" xmlns="http://www.w3.org/2000/svg"
                            width="314" height="602" viewBox="0 0 314 602" fill="none">
                            <path opacity="0.2"
                                d="M-184.203 602L-184.203 0M-108.241 602L-108.241 0M-32.2787 602L-32.2787 0M43.6835 602L43.6834 0M119.646 602L119.646 0M195.608 602L195.608 0M271.57 602L271.57 0M-257 72.7971H345M-257 148.759H345M-257 224.721H345M-257 300.684H345M-257 376.646H345M-257 452.608H345M-257 528.57H345"
                                stroke="url(#paint0_radial_152_17241)" strokeWidth="2" />
                            <defs>
                                <radialGradient id="paint0_radial_152_17241" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(44 301) rotate(90) scale(301)">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className="my-14 sm:px-0 px-6 overflow-hidden">
                        <div className="flex-column items-center justify-center py-9 relative">
                            <div className="flex flex-col-reverse relative z-20">
                                <div
                                    className="sm:border-2 sm:border-[#56545484] sm:bg-[#56545484] sm:rounded-3xl sm:p-8 xl:p-32 block md:pb-[74px] overflow-hidden">
                                    <div>
                                        <h3 className="sm:text-center md:text-4xl text-2xl text-white font-bold">
                                            Login to your Account
                                        </h3>
                                        <p className="sm:text-center text-white text-justify pt-4 pb-6 md:pr-12">
                                            Enter your details below to login to your account:
                                        </p>
                                    </div>
                                    <div className="flex flex-col items-start lg:items-center justify-center">
                                        <form className="space-y-4 md:space-y-12 w-4/12" onSubmit={handleSubmit(onSubmit)}>
                                            <div>
                                                <Input
                                                    type={`text`}
                                                    label="Username"
                                                    id={`username`}
                                                    placeholder={`Username`}
                                                    register={register}
                                                    validationOptions={{ required: 'Username is required' }}
                                                />
                                                <p className="text-rose-700 text-sm mb-4">{errors.username?.message}</p>
                                            </div>
                                            <div>
                                                <Input
                                                    type={`password`}
                                                    label="Password"
                                                    id={`password`}
                                                    placeholder={`********`}
                                                    register={register}
                                                    validationOptions={{ required: 'Password is required' }}
                                                />
                                                <p className="text-rose-700 text-sm mb-4">{errors.password?.message}</p>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-start">
                                                    <div className="flex items-center h-5">
                                                        <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                                    </div>
                                                    <div className="ml-3 text-sm">
                                                        <label htmlFor="remember" className="text-white-500 dark:text-white-300">Remember me</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {loginErrors.length > 0 ?
                                                <div>
                                                    <ul>
                                                        {renderLoginErrors}
                                                    </ul>
                                                </div>
                                                : null}

                                            <div className="p-[2px] rounded-full bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block">
                                                <button disabled={isProcessing} className="px-[21px] xl:px-[21px] rounded-full py-2 text-white bg-black text-sm font-[600]  hover:bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block duration-500">
                                                    Login
                                                </button>
                                            </div>
                                            <p className="text-sm font-light text-white-500 dark:text-white-400">
                                                Don't have an account yet? <Link to="/users/register" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</Link>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
}

export default LoginPage;
